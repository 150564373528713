import React from 'react'
import { IonItem, IonLabel, IonInput, IonButton } from '@ionic/react'
import { connect } from 'react-redux'
import Layout from '../../components/layout'
import PasswordInput from '../../components/passwordInput'
import { forwardTo, getDefaultRoute } from '../../lib/utils'
import { loginRequest } from '../../store/actions'
import { withTranslation } from '../../lib/translate'
import Loading from '../../components/spinner'
import { FieldError } from '../../components/common'
import { validateForm, isDesktop } from '../../lib/utils'

require('./index.css')
if (isDesktop()){
	require('./desktop.css')
}else{
	require('./mobile.css')
}

class Login extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			username: '',
			password: '',
			formErrors: {},
			caller: null
		}
		this.handleInput = this.handleInput.bind(this)
		this.handleLogin = this.handleLogin.bind(this)
		this.redirectLoggedIn = this.redirectLoggedIn.bind(this)
		this.formConfig = {
			username: { type: 'email', required: true },
			password: { type: 'password', required: true }
		}
	}

	handleInput (key, val) {
		this.setState({[key]: val })
	}

	handleKeyPress = e => {
		if (e.key === 'Enter' || e.which === 13) {
			this.handleLogin()
		}
	}

	handleLogin () {
		let formErrors = validateForm(this.formConfig, this.state, this.props.__)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			const { username, password } = this.state
			this.props.dispatch(loginRequest({ username, password }))
		}
	}

	redirectLoggedIn() {
		const { auth, history, language } = this.props
		const { loggedIn } = auth
		if (loggedIn){
			const defaultRoute = getDefaultRoute()
			if (this.state.caller !== null) {
				history.push({ pathname: this.state.caller })
				this.setState({ caller: null })
			} else {
				forwardTo(history, defaultRoute.path)
				localStorage.setItem("language", language);
				window.location.reload(false);
			}
		}
	}

	componentDidMount() {
		this.redirectLoggedIn()

		if (this.props.location && this.props.location.state && this.props.location.state.caller) {
			this.setState({ caller: this.props.location.state.caller })
		}
	}

	componentDidUpdate(prevProp) {
		if (prevProp.auth !== this.props.auth){
			this.redirectLoggedIn()
		}
	}

	render (){
		const { history, __ } = this.props
		const { username, password } = this.state
		return (
			<Loading transparent>
				<Layout headerTitle={ __('Login')} blank={ true } color="transparent" contentClassName="animated-gif-01" noPadding={ true }>
					<div className="login-holder">
						<h3 className="login-header">{ __('Click below to register or login')}</h3>
						<div className="ion-text-center login-holder-inner">
							<IonItem>
								<IonLabel className="item" position="floating" color="medium">
									{ __('Email')}
								</IonLabel>
								<IonInput onIonChange={(e) => this.handleInput('username', e.target.value)} onKeyPress={(e) => this.handleKeyPress(e)} clearInput required={ true } type="email" pattern="email" inputmode="email" value={ username } />
								<FieldError className="field-error" value={ __(this.state.formErrors.username)} />
							</IonItem>
							<IonItem>
								<IonLabel position="floating" color="medium">
									{ __('Password')}
								</IonLabel>
								<PasswordInput onIonChange={(e) => this.handleInput('password', e.target.value)} required={ true } value={ password } handleLogin={this.handleLogin}/>
								<FieldError className="field-error" value={ __(this.state.formErrors.password)} />
							</IonItem>
							<div className='login-register-buttons'>
								<IonButton expand="full" fill="outline" color="primary" className="default-button login-button" onClick={() => this.handleLogin()}>{ __('Login')}</IonButton>
								<IonButton expand="full" color="tertiary" className="default-button white-button top-medium" onClick={() => history.push('/register')}>{ __('Register')}</IonButton>
							</div>
							<IonButton className="text-link forgot-pass top-medium" color="tertiary" fill="clear" onClick={() => history.push('/reset-password')}>{ __('Forgot Password?')}</IonButton>
							<IonButton className="text-link forgot-pass top-small" color="tertiary" fill="clear" onClick={() => history.push('/dashboard')}>{ __('Continue as guest')}</IonButton>
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const stateToProps = (state) => {
	const { auth, profile } = state.auth
	return {
		auth,
		language: profile.locale
	}
}

export default connect(stateToProps)(withTranslation(Login))
