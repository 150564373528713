import { call, put, take, select } from 'redux-saga/effects'
import { LOADING, INIT, RESET_PASSWORD, SET_MODAL, SEND_POST_CODE, SET_COMMON_PROP, INIT_FIREBASE_DATABASE } from './constants'
import { GET_VOUCHERS, GET_PROFILE } from '../constants'
import { restoreAuth } from '../auth/actions'
import { firebaseFlow } from '../../lib/firebase'
import { isDefined } from '../../lib/utils'
import api from '../../lib/api'
import Stripe from '../../lib/stripe'
import appConfig from '../../appConfig'
import { translate } from '../../lib/translate'
import { eventChannel } from 'redux-saga'
import { setCatalog } from '../../translationCatalogWrapper'
import * as firebase from 'firebase'
import asyncStorage from '../../lib/asyncStorage'

export const loading = function* (fn = null) {
	try {
		yield put({ type: LOADING, loading: true })
		if (fn){
			yield call(fn)
		}
	} finally {
		yield put({ type: LOADING, loading: false })
	}
}

export const requestFlow = function* () {
}

export const initSaga = function* () {
	while (true) {
		yield take(INIT)

		//initialize stripe
		yield call(Stripe.setStripePublishableKey)

		yield put(restoreAuth())
		// const authStore = yield select(state => state.profile.auth)
		// if (authStore && authStore.loggedIn){
		// 	yield call(createAxiosInstance, authStore.token)
		// } else {
		// 	yield call(createAxiosInstance)
		// }
		// console.log('444')
		// SplashScreen.hide()
		//yield put(restoreAuth())
		//const authStore = yield select(state => state.profile.auth)
		let envs = {}
		try {
			envs = yield call(api.getEnvs)
			yield put({ type: SET_COMMON_PROP, key: 'envs', value: envs.data})
		} catch (error) {
			// xxx
			console.log('err')
		}
		let translationCatalog = {}
		try {
			translationCatalog = yield call(api.getTranslations, envs.data.APP_DOMAIN)
		} catch (error) {
			// xxx
			console.log('err', error )
		}

		yield call(setCatalog, translationCatalog)
		yield put({ type: SET_COMMON_PROP, key: 'translationCatalog', value: translationCatalog })
		//add firebase listeners
		yield call(firebaseFlow)

		//if (authStore && authStore.loggedIn){
		//	api.createAxiosInstance(authStore.token)
		//}
		//SplashScreen.hide()
	}
}

export const saveFcmToken = function* () {

	const store = yield select()
	const deviceFcmToken = yield select(store => store.common.deviceFcmToken)
	const profile = store.auth.profile
	if (isDefined(profile) && isDefined(deviceFcmToken) && profile.fcm_token !== deviceFcmToken) {
		//save new fcm token
		// yield put({ type: UPDATE_PROFILE, data: { fcm_token: deviceFcmToken }, skipAlert: true })
		yield call(api.fcmToken, deviceFcmToken)
	}
}

export const resetPasswordFlow = function* () {
	while (true) {
		const action = yield take(RESET_PASSWORD)
		yield call(loading, function *() {
			yield call(api.resetPassword, action.email)
			yield put({ type: SET_MODAL, modal: 'isResetPasswordModalOpen', value: true })
		})
	}
}

export const sendPostCode = function* () {
	while (true) {
		const action = yield take(SEND_POST_CODE)
		yield call(loading, function *() {
			const response = yield call(api.sendPostCode, action.data)
			yield put({ type: SET_COMMON_PROP, key: 'myLocation', value: {latitude: response.data.latitude, longitude: response.data.longitude} })
			const history = action.history
			if (history) {
				history.push({ pathname: 'locations' })
			}
		})
	}
}

// fetch user profile (if exists) and translate using profile.locale OR using default locale form config
export const translateSaga = function* (text) {
	const store = yield select()
	let locale = appConfig.localization.defaultLocale
	if (store.profile && store.profile.profile && store.profile.profile.locale) {
		locale = store.profile.profile.locale
	}
	return translate(text, locale)
}

const checkFibaseDBProperty = function* (propName, obj = {}) {
	let ret = false
	const storagePropName = propName + '_firebase'
	if (obj[propName]) {
		const storagePropValue = yield call(asyncStorage.getItem, storagePropName)
		if (!storagePropValue || storagePropValue !== obj[propName]) {
			yield call(asyncStorage.setItem, storagePropName, obj[propName])
			ret = true
		}
	}
	return ret
}

export const firebaseDatabaseFlow = function* () {
	while (true) {
		yield take(INIT_FIREBASE_DATABASE)
		const profileId = yield select(store => store.auth.profile.id)
		const firebaseChannel = eventChannel(emitter => {
			const connection = firebase.initializeApp(appConfig.firebaseConfig)
			const db = connection.database().ref(profileId)
			const restaurants = connection.database().ref('restaurants')
			db.on('value', snap => {
				let db_record = snap.val()
				if (db_record) {
					emitter({ type: 'db', value: db_record })
				}
			})

			restaurants.on('value', snap => {
				let db_record = snap.val()
				if (db_record) {
					emitter({ type: 'db', value: db_record })
				}
			})

			// unsubscribe function
			return () => {}
		})

		while (true) {
			const firebaseMessage = yield take(firebaseChannel)
			// eslint-disable-next-line no-console
			console.log('firebase database message', firebaseMessage)
			const { type, value } = firebaseMessage
			switch (type) {
			case 'db': {
				if (yield call(checkFibaseDBProperty, 'timestamp', value)) {
					yield put({ type: GET_PROFILE, skipLoading: true })
				}
				if (yield call(checkFibaseDBProperty, 'vouchers', value)) {
					// get vouhers here
					yield put({type: GET_VOUCHERS })
				}
				if (yield call(checkFibaseDBProperty, 'restaurant_snooze_data', value)) {
					// get restaurants snoozed data
					// yield put({ type: GET_RESTAURANTS_SNOOZED_DATA })
					// yield put({ type: SET_RESTAURANT_PROP, key: 'restaurantsUpdated', value: value.restaurant_snooze_data })
				}
				break
			}
			default:
			}
		}
	}
}
