import React from 'react'
import { IonButton, IonInput, IonItem, IonLabel, IonList, IonDatetime, IonToggle } from '@ionic/react'
import { connect } from 'react-redux'
import Layout from '../../components/layout'
import PasswordInput from '../../components/passwordInput'
import { forwardTo, getDefaultRoute, isDesktop } from '../../lib/utils'
import { registerRequest, setSysLocale } from '../../store/actions'
import { withTranslation } from '../../lib/translate'
import { FieldError } from '../../components/common'
import { validateForm } from '../../lib/utils'
import Loading from '../../components/spinner'

import './index.css'
require('./index.css')
if(isDesktop()) {
	require('./desktop.css')
} else {
	require('./mobile.css')
}
class Register extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			first_name: '',
			last_name: '',
			email: '',
			password: '',
			birthday: '',
			mobile: '',
			opt_out_marketing: false,
			is_subscribed: false,
			formErrors: {
				first_name: '',
				last_name: '',
				email: '',
				password: '',
				birthday: '',
				mobile: ''
			}
		}
		this.handleInput = this.handleInput.bind(this)
		this.handleRegister = this.handleRegister.bind(this)
		this.redirectLoggedIn = this.redirectLoggedIn.bind(this)
		this.formConfig = {
			email: { type: 'email', required: true },
			password: { type: 'password', required: true },
			first_name: { type: 'text', required: true },
			last_name: { type: 'text', required: true },
			mobile: { type: 'tel', required: false },
			birthday: { type: 'text', required: false },
			opt_out_marketing: { type: 'boolean', required: false }
		}
	}

	componentDidMount() {
		let language = window.navigator.userLanguage || window.navigator.language
		let sysLanguage = language && language.indexOf('-') !== -1 ? language.substr(0,2) : language
		this.props.dispatch(setSysLocale(sysLanguage))
		this.redirectLoggedIn()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth !== this.props.auth){
			this.redirectLoggedIn()
		}
	}

	handleInput (key, val) {
		this.setState({[key]: val })
	}

	handleRegister () {
		const { sysLocale } = this.props
		let formErrors = validateForm(this.formConfig, this.state, this.props.__)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			const { email, password, first_name, last_name, birthday, mobile, opt_out_marketing } = this.state
			this.props.dispatch(registerRequest({ email, password, first_name, last_name, birthday, mobile, device_id: 'n/a', username: email, is_subscribed: !opt_out_marketing, sysLocale: sysLocale }))
			this.setState({
				first_name: '',
				last_name: '',
				email: '',
				password: '',
				birthday: '',
				mobile: '',
				opt_out_marketing: false,
				is_subscribed: false
			})
		}
	}

	redirectLoggedIn() {
		const { auth, history } = this.props
		const { loggedIn } = auth

		if (loggedIn){
			const defaultRoute = getDefaultRoute()
			forwardTo(history, defaultRoute.path)
		}
	}

	render (){
		const { __ } = this.props
		const { email, password, first_name, last_name, birthday, mobile, formErrors, is_subscribed } = this.state
		return (
			<Loading transparent>
				<Layout headerTitle={ __('Register')} blank={ true } color="transparent" contentClassName="animated-gif-01" noPadding={ true }>
					<div className="register-wrapper">
						<div className='register-title'>{ __('My Profile') }</div>
						<IonList>
							<IonItem>
								<IonLabel position="floating">{ __('First Name')}*</IonLabel>
								<IonInput onIonChange={ e => this.handleInput('first_name', e.target.value)} value={ first_name }/>
								<FieldError className="field-error" value={ __(this.state.formErrors.first_name)} />
							</IonItem>
							<IonItem>
								<IonLabel position="floating">{ __('Last Name')}*</IonLabel>
								<IonInput onIonChange={ e => this.handleInput('last_name', e.target.value)} value={ last_name }/>
								<FieldError className="field-error" value={ __(this.state.formErrors.last_name)} />
							</IonItem>
							<IonItem>
								<IonLabel position="floating">{ __('Password')}*</IonLabel>
								<PasswordInput onIonChange={ e => this.handleInput('password', e.target.value)} value={ password }/>
								<FieldError className="field-error" value={ __(this.state.formErrors.password)} />
							</IonItem>
							<IonItem>
								<IonLabel position="floating">{ __('Email')}*</IonLabel>
								<IonInput onIonChange={ e => this.handleInput('email', e.target.value)} required={ true } type="email" pattern="email" inputmode="email" value={ email }></IonInput>
								<FieldError className="field-error" value={ __(this.state.formErrors.email)} />
							</IonItem>
							<IonItem>
								<IonLabel position="floating">{ __('Date of Birth')}</IonLabel>
								<IonDatetime pickerOptions={{ cssClass: 'desktop-date-picker' }} onIonChange={(e) => this.handleInput('birthday', e.target.value)} clearInput required={ true } displayFormat="DD/MM/YYYY" pickerFormat= "DD/MMMM/YYYY" value={ birthday } placeholder={ __('Select Date')} cancelText={ __('Cancel')} doneText={ __('Done')}></IonDatetime>
								<FieldError className="field-error" value={ __(this.state.formErrors.birthday)} />
							</IonItem>
							<IonItem>
								<IonLabel position="floating">{ __('Mobile Number')}</IonLabel>
								<IonInput onIonChange={(e) => this.handleInput('mobile', e.target.value)} clearInput required={ true } type="tel" pattern="tel" inputmode="tel" value={ mobile }></IonInput>
								<FieldError className="field-error" value={ __(formErrors.mobile) } />
							</IonItem>
							{/* <IonItem line="none">
								<IonCheckbox color="secondary" slot="start" checked={ opt_out_marketing } onIonChange={e => this.handleInput('opt_out_marketing', e.detail.checked)} />
								<IonLabel className="ion-text-wrap">
									<IonNote>{ __('Opt out of marketing data?') }</IonNote>
								</IonLabel>
							</IonItem> */}
							<p className="ion-text-center"><span className="blue-note">{ __('By signing up you\'re agreeing to our')}</span> <span className="red-note">{__('terms and conditions of use')}</span></p>
							<IonItem lines="none" className="email-opt-item">
								<div className="email-opt-toggle">
									<h2>{ __('Email Opt in') }</h2>
									<p>{ __('Are you happy to receive marketing emails and offers?') }</p>
								</div>
								<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }} slot="end">
									<IonToggle checked={ is_subscribed } onIonChange={(e) => this.handleInput('is_subscribed', e.detail.checked) } />
								</div>
							</IonItem>
						</IonList>
						<div className="top-medium">
							<IonButton expand="full" color="tertiary" onClick={() => this.handleRegister()}>{ __('Register')}</IonButton>
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const stateToProps = (state) => {
	const { auth, sysLocale } = state.auth
	return {
		auth,
		sysLocale
	}
}

export default connect(stateToProps)(withTranslation(Register))
