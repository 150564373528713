import { home, logIn, globe, logOut, warning, personAdd, people, restaurant, informationCircleOutline, fileTrayStacked } from 'ionicons/icons'
import { isDesktop } from './lib/utils'

import Dashboard from './screens/dashboard'
import Account from './screens/account'
import Cart from './screens/cart'
import Orders from './screens/orders/selectRestaurants'
import Loyalty from './screens/loyalty'
import Locations from './screens/locations'
import Feedback from './screens/feedback'
import Refer from './screens/refer'
import Social from './screens/social'
import Radio from './screens/radio'
import AllergenInfo from './screens/allergenInfo'
import Terms from './screens/terms'
import Privacy from './screens/privacy'
import Login from './screens/login'
import Register from './screens/register'
import ResetPassword from './screens/resetPassword'
import Menu from './screens/orders/menu'
import Categories from './screens/orders/categories'
import Checkout from './screens/checkout'
import MyOrders from './screens/myOrders'
import MyOrderDetails from './screens/myOrderDetails'
import ApplyVouchers from './screens/applyVouchers'
import WebMenu from './screens/orders/webMenu'
import WebByob from './screens/orders/webBuildYourOwn'
import OrderComplete from './screens/orderComplete'

const navConfig = {
	routes: [
		{ label: 'Home', path: '/dashboard', component: Dashboard, icon: home, exact: true, default: true },
		{ label: 'My Profile', path: '/account', component: Account, iconName: 'profile', protected: true },
		{ label: 'Basket', path: '/cart', component: Cart, iconName: 'basket', notInDrawer: true, protected: false },
		{ label: 'My Orders', path: '/orders', component: Orders, iconName: 'orders', notInDrawer: true,protected: false },
		{ label: 'My Orders', path: '/my-orders', component: MyOrders, iconName: 'orders', protected: true },
		{ label: 'My Orders Details', path: '/my-orders-details', component: MyOrderDetails, iconName: 'orders', notInDrawer: true, protected: true },
		{ label: 'Order Complete', path: '/order-complete', component: OrderComplete, iconName: 'orders', notInDrawer: true, protected: true },
		{ label: 'Menu', path: '/menu', component: isDesktop() ? WebMenu : Menu, iconName: 'menu', notInDrawer: true, protected: false },
		{ label: 'Categories', path: '/categories', component: Categories, iconName: 'categories', notInDrawer: true, protected: false },
		{ label: 'Build Your Own', path: '/buildyourown', component: WebByob, iconName: '', notInDrawer: true, protected: false },
		{ label: 'Loyalty Rewards', miniLabel: 'Rewards', path: '/loyalty', component: Loyalty, iconName: 'voucher', protected: true },
		{ label: 'Locations', path: '/locations', component: Locations, icon: globe, protected: false },
		{ label: 'Leave Feedback', path: '/feedback', component: Feedback, iconName: 'feedback', protected: true },
		{ label: 'Refer a Friend', path: '/refer', component: Refer, icon: personAdd, protected: true },
		{ label: 'Social Media', path: '/social', component: Social, iconName: 'social', protected: false },
		{ label: 'Island Radio', path: '/radio', component: Radio, iconName: 'radio', protected: false },
		{ label: 'Allergen Info', path: '/allergen-info', component: AllergenInfo, icon: warning, protected: false },
		{ label: 'ApplyVouchers', path: '/applyVouchers', component: ApplyVouchers, iconName: 'profile', notInDrawer: true, protected: true }
	],
	authRoutes: [
		{ label: 'Login', path: '/login', component: Login, icon: logIn, fn: 'login' },
		{ label: 'Logout', path: '/logout', icon: logOut, fn: 'logout' }
	],
	additionalRoutes: [
		{ label: 'Terms & Conditions', path: '/terms', component: Terms },
		{ label: 'Privacy Policy', path: '/privacy', component: Privacy },
		{ label: 'Checkout', path: '/checkout', component: Checkout, notInDrawer: true }
	],
	notInMenuRoutes: [
		{ path: '/register', component: Register },
		{ path: '/reset-password', component: ResetPassword },
		{ path: '/checkout', component: Checkout }
	],
	webRoutes: [
		{ label: 'Home', path: 'https://www.islandpoke.com/', icon: home, left: true },
		{ label: 'Join the team', path: 'https://www.islandpoke.com/join-the-team/', icon: people, left: true },
		{ label: 'Our story', path: 'https://www.islandpoke.com/our-story/', icon: informationCircleOutline ,left: true },
		{ label: 'Our food', path: 'https://www.islandpoke.com/our-food/', icon: restaurant, left: true },
		{ label: 'Catering', path: 'https://www.islandpoke.com/catering/', iconName: 'orders', left: false },
		{ label: 'Our projects', path: 'https://www.islandpoke.com/our-projects/', icon: fileTrayStacked, left: false },
		{ label: 'Our locations', path: 'https://www.islandpoke.com/our-locations/', icon: globe, left: false }
	],
	webRightPopoverRoutes: [
		{ label: 'Our Locations', path: '/locations', component: Locations, protected: true },
		{ label: 'Start an Order', path: '/dashboard', component: Dashboard, protected: true },
		{ label: 'My Orders', path: '/my-orders', component: MyOrders, protected: true },
		{ label: 'My Rewards', path: '/loyalty', component: Loyalty, protected: true },
		{ label: 'My Profile', path: '/account', component: Account, protected: true }
		// { label: 'Logout', path: '/logout', fn: 'logout' }
		// { path: '/reset-password', component: ResetPassword }
	]
}

export default navConfig
