import React from 'react'
import { IonButton, IonInput, IonItem, IonLabel, IonList, isPlatform, IonToggle } from '@ionic/react'
import { connect } from 'react-redux'
import Layout from '../../components/layout'
import { updateProfile, getProfile } from '../../store/actions'
import { withTranslation } from '../../lib/translate'
import { FieldError } from '../../components/common'
import { validateForm, isDesktop } from '../../lib/utils'
import Loading from '../../components/spinner'
import { Plugins } from '@capacitor/core'
import Mobiscroll from '../../components/mobiscroll'
import appConfig from '../../appConfig'
import { DesktopView } from '../../components/desktopView'

require('./index.css')
if(isDesktop()) {
	require('./desktop.css')
}

const { DatePicker, SelectOption } = Mobiscroll
const { Device } = Plugins

class Account extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			first_name: this.props.profile.first_name || '',
			last_name: this.props.profile.last_name || '',
			email: this.props.profile.email || '',
			birthday: this.props.profile.birthday ? this.props.profile.birthday : '',
			mobile: this.props.profile.mobile || '',
			locale: this.props.profile.locale || appConfig.localization.defaultLocale,
			formErrors: {
				first_name: '',
				last_name: '',
				email: '',
				birthday: '',
				mobile: '',
				locale: ''
			},
			appDeviceVersion: '',
			is_subscribed: this.props.profile.is_subscribed ? this.props.profile.is_subscribed : false
		}

		this.formConfig = {
			email: { type: 'email', required: true },
			first_name: { type: 'text', required: true },
			last_name: { type: 'text', required: true },
			mobile: { type: 'tel', required: false },
			birthday: { type: 'text', required: false },
			locale: { type: 'text', required: false }
		}
	}

	async componentDidMount() {
		if (Object.keys(this.props.profile).length === 0){
			this.props.dispatch(getProfile())
		}
		
		const info = await Device.getInfo()
		const appDeviceVersion = info.appVersion
		this.setState({ appDeviceVersion: appDeviceVersion })
	}

	componentDidUpdate(prevProps) {
		if (this.props.profile.first_name !== prevProps.profile.first_name ){
			this.setState({ first_name: this.props.profile.first_name })
		}
		if (this.props.profile.last_name !== prevProps.profile.last_name){
			this.setState({ last_name: this.props.profile.last_name })
		}
		if (this.props.profile.email !== prevProps.profile.email){
			this.setState({ email: this.props.profile.email })
		}
		if (this.props.profile.mobile !== prevProps.profile.mobile){
			this.setState({ mobile: this.props.profile.mobile })
		}
		if (this.props.profile.birthday !== prevProps.profile.birthday){
			this.setState({ birthday: this.props.profile.birthday ? this.props.profile.birthday : null })
		}
		if (this.props.profile.locale !== prevProps.profile.locale){
			this.setState({ locale: this.props.profile.locale || appConfig.localization.defaultLocale })
		}
		if (this.props.profile.is_subscribed !== prevProps.profile.is_subscribed) {
			this.setState({ is_subscribed: this.props.profile.is_subscribed })
		}
	}

	handleInput = (key, val) => {
		this.setState({[key]: val })
	}

	handleLanguageInput = (event, data) => {
		this.setState({ locale: data.getVal() })
	}

	handleUpdateProfile = () =>{
		let formErrors = validateForm(this.formConfig, this.state, this.props.__)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			const { formErrors, ...rest } = this.state
			this.props.dispatch(updateProfile({ ...rest }))
		}
	}

	formatDataForSelect = (langs) => {
		let arrForSelect = []
		Object.keys(langs).forEach(key => {
			arrForSelect.push({ text: langs[key], value: key })
		})
		return [{ text: '', value: null }, ...arrForSelect]
	}

	render (){
		const { __, profile } = this.props
		const { email, first_name, last_name, birthday, mobile, locale, formErrors, appDeviceVersion, is_subscribed } = this.state
		return (
			<Loading transparent>
				<Layout headerTitle={ __('My Profile')} color={isDesktop() ? "transparent" : "white"} contentClassName={ isDesktop() ? "animated-gif-01" : "" } noPadding={ isDesktop() ? true : false }>
					<div className='desktop-profile-wrapper'>
						<DesktopView>
							<div className='desktop-profile-header'>{ __('My Profile')}</div>
						</DesktopView>
						<IonList>
							<IonItem>
								<IonLabel position="floating">{ __('First Name')}*</IonLabel>
								<IonInput onIonChange={ e => this.handleInput('first_name', e.target.value)} value={ first_name }/>
								<FieldError className="field-error" value={ __(this.state.formErrors.first_name)} />
							</IonItem>
							<IonItem>
								<IonLabel position="floating">{ __('Last Name')}*</IonLabel>
								<IonInput onIonChange={ e => this.handleInput('last_name', e.target.value)} value={ last_name }/>
								<FieldError className="field-error" value={ __(this.state.formErrors.last_name)} />
							</IonItem>
							<IonItem>
								<IonLabel position="floating">{ __('Email')}*</IonLabel>
								<IonInput onIonChange={ e => this.handleInput('email', e.target.value)} required={ true } type="email" pattern="email" inputmode="email" value={ email }></IonInput>
								<FieldError className="field-error" value={ __(this.state.formErrors.email)} />
							</IonItem>
							{/* <IonItem>
								<IonLabel position="floating">{ __('Date of Birth')}</IonLabel>
								<IonDatetime onIonChange={(e) => this.handleInput('birthday', e.target.value)} pickerOptions={{ cssClass: 'desktop-date-picker' }} clearInput required={ true } displayFormat="DD/MM/YYYY" pickerFormat= "DD/MMMM/YYYY" value={ birthday } placeholder={ __('Select Date')} cancelText={ __('Cancel')} doneText={ __('Done')}></IonDatetime>
								<FieldError className="field-error" value={ __(this.state.formErrors.birthday)} />
							</IonItem> */}
							<div className="date-picker-wrapper">
								<label className={`date-picker-label date-picker-label--primary`} htmlFor="demo-non-form">{ __('Date of Birth') }*</label>
								<DatePicker
									className="data-picker-input"
									display="bottom"
									setText={__('Done')}
									value={ birthday }
									onSet={(e, a) => this.handleInput('birthday', a.element.value)}
									dateFormat="dd-mm-yy"
									placeholder={ __('Select Date')}
									lang = {profile.locale}
									// onBeforeShow={ () => { this.props.dispatch(beforeShowTimePicker()) }}
									// onClose={ () => {
									// 	this.props.dispatch(beforeCloseTimePicker())
									// 	this.handlePeriodicalSaga(true)
									// }}
									// onShow={ () => this.handlePeriodicalSaga(false) }
								/>
							</div>
							<IonItem>
								<IonLabel position="floating">{ __('Mobile Number')}</IonLabel>
								<IonInput onIonChange={(e) => this.handleInput('mobile', e.target.value)} clearInput required={ true } type="tel" pattern="tel" inputmode="tel" value={ mobile }></IonInput>
								<FieldError className="field-error" value={ __(formErrors.mobile) } />
							</IonItem>
							{/* <IonItem>
								<IonLabel position="floating">{ __('Preferred Language')}</IonLabel>
								<IonSelect onIonChange={(e) => this.handleInput('locale', e.target.value)} value={ locale } placeholder={ __('Select one')} cancelText={ __('Cancel')} okText={ __('Ok')}>
									{ Object.keys(appConfig.localization.supportedLocales).map((k, i) =>
										<IonSelectOption key={ 'locale-option-' + i } value={ k }>{ appConfig.localization.supportedLocales[k] }</IonSelectOption>
									)}
								</IonSelect>
							</IonItem> */}
							<>
								<label className={`select-picker-label select-picker-label--primary`} htmlFor="demo-non-form">{ __('Preferred Language')}</label>
								<SelectOption
									display="center"
									onSet={(e, a) => this.handleLanguageInput(e, a)}
									data={ this.formatDataForSelect(appConfig.localization.supportedLocales) }
									label="Location"
									value={ locale }
									inputStyle="box"
									placeholder={ __('Select one') }
									cancelText = {__('Cancel')}
									setText={__('OK')}
									// disabled={ this.props.isShowTimePicker ? true : false }
									// onClose={ () => { this.handlePeriodicalSaga(true) }}
									// onShow={ () => this.handlePeriodicalSaga(false) }
								/>
							</>
							<IonItem lines="none" className="email-opt-item">
								<div className="email-opt-toggle">
									<h2>{ __('Email Opt in') }</h2>
									<p>{ __('Are you happy to receive marketing emails and offers?') }</p>
								</div>
								<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }} slot="end">
									<IonToggle checked={ is_subscribed } onIonChange={(e) => this.handleInput('is_subscribed', e.detail.checked) } />
								</div>
							</IonItem>
						</IonList>
						<br />
						<IonButton expand="full" color="tertiary" onClick={() => this.handleUpdateProfile()}>{ __('Update Profile')}</IonButton>
						{
							isPlatform('mobile') ?
								<div className='version-box'><p>Version: {appDeviceVersion}</p></div> :
								null
						}
					</div>
				</Layout>
			</Loading>
		)
	}
}

const stateToProps = (state) => {
	const { profile } = state.auth
	return {
		profile
	}
}

export default connect(stateToProps)(withTranslation(Account))
