import React from 'react'
import { IonTitle, IonToolbar, IonButtons, IonList, IonItem, IonLabel } from '@ionic/react'
import { isDesktop } from '../../lib/utils'
import ArrowButton from '../arrowButton'

require('./index.css')
if (isDesktop()){
	require('./desktop.css')
} else {
	require('./mobile.css')
}


const Header = ({ title, hideBack, hideForward, onForwardHandler, onBackHandler, history, subHeader, subHeaderByob, subHeaderSelectCategory, scrollToSection, sectionIndex }) => {
	const currentPath = history.location.pathname
	return (
		<IonToolbar className={isDesktop() && currentPath === '/menu' ? 'web-menu dynamic-header' : 'dynamic-header'}>
			{
				!isDesktop()
					?
					<>
						{ !hideBack ?
							<IonButtons slot="start">
								<ArrowButton onBackHandler={ onBackHandler } type='arrowBack'/>
							</IonButtons>
							:
							<IonButtons slot="start">
								<ArrowButton style={{ visibility: 'hidden'}} type='arrowBack'/>
							</IonButtons>}
						<IonTitle className="ion-text-center">{ title }</IonTitle>
						{ !hideForward ?
							<IonButtons slot="end">
								<ArrowButton type='arrowForward' onForwardHandler={ onForwardHandler } />
							</IonButtons>
							:
							<IonButtons slot="end">
								<ArrowButton type='arrowForward' style={{ visibility: 'hidden'}}/>
							</IonButtons> }
					</>
					:
					<IonList className='web-menu-subheader-list'>
						{
							(Object.keys(subHeader ? subHeader : {}) || []).
								sort((a,b) => subHeader[a].category.sort_order > subHeader[b].category.sort_order ? 1 : -1).
								map((key, i) => {
									if (subHeader[key].category.attributes.is_hidden) {
										return null
									}
									if (subHeader[key].category.attributes.is_byob) {
										return (
											<IonItem key={i} lines="none" className="web-menu-subheader-item" onClick={() => subHeaderSelectCategory(subHeader[key], true)}>
												<div tabIndex="-1"></div>
												<IonLabel color="white" >{subHeaderByob.toUpperCase()}</IonLabel>

											</IonItem>
										)
									}
									return null
								})
						}
						{
							(Object.keys(subHeader ? subHeader : {}) || []).
								sort((a,b) => subHeader[a].category.sort_order > subHeader[b].category.sort_order ? 1 : -1).
								map((key, i) => {
									if (subHeader[key].category.attributes.is_hidden || subHeader[key].category.attributes.is_byob) {
										return null
									}
									const subCategoryHeader = subHeader[key] && subHeader[key].subCategories ? subHeader[key].subCategories : {}
									return Object.keys(subCategoryHeader || []).
										sort((a,b) => subCategoryHeader[a].subCategory.sort_order > subCategoryHeader[b].subCategory.sort_order ? 1 : -1).
										map((subHead, i) => {
											if (!subCategoryHeader[subHead].subCategory.attributes.is_byob) {
												return (
													<IonItem key={i} lines="none" className={sectionIndex === subHead ? 'web-menu-subheader-item active' : 'web-menu-subheader-item'} onClick={() => scrollToSection(subHead)}>
														<div tabIndex="-1"></div>
														<IonLabel color="white" >{subHead.toUpperCase()}</IonLabel>
													</IonItem>
												)
											}
											return null
										})
								})
						}
					</IonList>
			}
		</IonToolbar>
	)
}

export default Header
