import { combineReducers } from 'redux'
import auth from './auth/reducers'
import common from './common/reducers'
import data from './data/reducers'
import order from './order/reducers'

export default combineReducers({
	auth,
	common,
	data,
	order
})
