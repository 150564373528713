import React from 'react'
import { IonButton, IonList, IonItem, IonLabel, IonInput, IonButtons, IonTitle, IonSlides, IonSlide, IonContent, IonIcon, IonModal, IonToolbar } from '@ionic/react'
import { withRouter } from 'react-router'
import { logoInstagram, arrowForward, close, arrowBack } from 'ionicons/icons'
import { connect } from 'react-redux'
import QRCode from 'qrcode.react'
import Layout from '../../components/layout'
import { getProfile, sendVoucherCode, getVouchers, sendMobile, mobileCheckSms } from '../../store/actions'
import { SET_MODAL } from '../../store/constants'
import { validateForm } from '../../lib/utils'
import { FieldError } from '../../components/common'
import { withTranslation } from '../../lib/translate'
import appConfig from '../../appConfig.js'
import Loading from '../../components/spinner'
import { DesktopView } from '../../components/desktopView'
import { MobileView } from '../../components/mobileView'
import GridStamp from './gridStamp'


import './index.css'

const s3Url = appConfig.configS3.url

const slideOpts = {
	initialSlide: 0,
	speed: 400
}

const desktopSlideOpts = {
	...slideOpts,
	centeredSlides: true
}


class Loyalty extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			slideOpts,
			slide: true,
			showModal: false,
			showSlider: true,
			code: '',
			mobile: '',
			verification: '',
			rightArrowSlide: false,
			leftArrowSlide: true,
			formErrors: {
				code: ''
			},
			formErrors2: {
				mobile: ''
			},
			formErrors3: {
				verification: ''
			}
		}
		this.formConfig = {
			code: { type: 'text', required: true }
		}
		this.formConfig2 = {
			mobile: { type: 'tel', required: true }
		}
		this.formConfig3 = {
			verification: { type: 'integer', required: true }
		}
	}

	componentDidMount() {
		if (Object.keys(this.props.profile).length === 0){
			this.props.dispatch(getProfile())
		}
		if (this.props.location && this.props.location.state && this.props.location.state.tab) {
			const slideOpts = this.state.slideOpts
			this.props.location.state.tab === 'scan' ? slideOpts.initialSlide = 0 : slideOpts.initialSlide = 1
			this.setState({ slideOpts })
		}

		this.interval = setInterval(() => this.props.dispatch(getVouchers()), 20000)
	}

	componentDidUpdate(prevProps) {
		if (this.props.vouchers.length && prevProps.vouchers.length !== this.props.vouchers.length) {
			this.setState({ showSlider: false }, () => setTimeout(this.setState({ showSlider: true }), 1000))
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval)
	}

	tryRequire = (path) => {
		let ret
		try {
			ret = 'url(' + require(path) + '), '
		} catch (err) {
			return ''
		}
		return ret
	}

	showModal = (show) => {
		let slideOpts = this.state.slideOpts
		slideOpts.initialSlide = 0
		this.setState({ showModal: show, slideOpts })
		this.clearFields()
	}

	showModal2 = (show) => {
		this.props.dispatch({ type: SET_MODAL, modal: 'mobileValidate', value: show })
		this.clearFields()
	}

	showModal3 = (show) => {
		this.props.dispatch({ type: SET_MODAL, modal: 'mobileCheckSms', value: show })
		this.clearFields()
	}

	handleInput = (name, value) => {
		this.setState({ [name]: value })
	}

	clearFields = () => {
		this.setState({ code: '', mobile: '', verification: '' })
	}

	sendCode = () => {
		let formErrors = validateForm(this.formConfig, this.state, this.props.__)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			const { code } = this.state
			const data = { code }
			this.clearFields()
			this.props.dispatch(sendVoucherCode(data))
			this.showModal(false)
		}
	}

	sendMobile = () => {
		let formErrors2 = validateForm(this.formConfig2, this.state, this.props.__)
		this.setState({ formErrors2 })
		if (Object.keys(formErrors2).length === 0) {
			const { mobile } = this.state
			const data = { mobile }
			this.clearFields()
			this.showModal2(false)
			this.showModal3(true)
			this.props.dispatch(sendMobile(data))
		}
	}

	sendCheckSms = () => {
		let formErrors3 = validateForm(this.formConfig3, this.state, this.props.__)
		this.setState({ formErrors3 })
		if (Object.keys(formErrors3).length === 0) {
			const data = { validation_code: this.state.verification }
			this.clearFields()
			this.props.dispatch(mobileCheckSms(data))
			this.showModal3(false)
		}
	}

	getCurrentSlide = () => {
		if (this.slides) {
			this.slides.getActiveIndex().then(res => {
				if (this.props.profile && this.props.profile.vouchers){
					const vouchers = this.props.profile.vouchers.length - 1
					if (vouchers === res){
						this.setState({ rightArrowSlide: true })
					} else {
						this.setState({ rightArrowSlide: false })
					}
					if (res === 0) {
						this.setState({ leftArrowSlide: true })
					} else {
						this.setState({ leftArrowSlide: false })
					}
				}
			})
		}
	}

	handleSlides = (dir) => {
		if (dir === 'left' && this.slides) {
			this.slides.slidePrev()
		} else if (dir === 'right' && this.slides) {
			this.slides.slideNext()
		} else {
			return null
		}
	}

	render() {
		const { __, history, profile, mobileValidate, mobileCheckSms } = this.props
		const { slideOpts, code, mobile, verification, rightArrowSlide, leftArrowSlide } = this.state
		const vouchers = profile && profile.vouchers && profile.vouchers.length > 0 ? true : false
		return (
			<>
				<Loading transparent>
					<Layout headerTitle={ __('Loyalty') } noPadding={ true } swiper={ true } color="transparent" contentClassName="animated-gif-01">
						<DesktopView>
							<div className='desktop-loyalty-wrapper'>
							<div className="desktop-loyalty-header">{ __('My rewards') }</div>
							<div className='desktop-loyalty'>
								<div className="swiper-item">
									<div className="swiper-header">{ __('Stamp card')}</div>
									<div className="swiper-content">
										<GridStamp profile={ profile } __= { __ } history={ history }/>
									</div>
								</div>
								<div className={'desktop-loyalty-swiper' + (!vouchers ? ' no-vouchers' : '')}>
									{
										vouchers ?
											<>
												<IonIcon onClick={() => this.handleSlides('left')} icon={arrowBack} mode='ios' className={leftArrowSlide ? 'loyalty-arrow-left disabled' : 'loyalty-arrow-left'}/>
													<IonSlides pager={ true } options={ desktopSlideOpts } ref={(r) => this.slides = r} onIonSlideDidChange={() => this.getCurrentSlide()}>
														{
															profile.vouchers.map((voucher, index) => (
																<IonSlide key={index}>
																	<img src={`${s3Url + voucher.reward.round_image}`} alt="Reward preview"/>
																</IonSlide>
															))
														}
													</IonSlides>
												<IonIcon onClick={() => this.handleSlides('right')} icon={arrowForward} mode='ios' className={rightArrowSlide ? 'loyalty-arrow-right disabled' : 'loyalty-arrow-right'}/>
											</>
											:
											<h2 className="no-vouchers">{ __("You do not have rewards yet")}</h2>
									}
								</div>
							</div>
							</div>
						</DesktopView>
						<MobileView>
						{
							Object.keys(profile).length > 0 && this.state.showSlider ? <IonContent className="swiper-holder" color="transparent">
								{leftArrowSlide ? <IonIcon icon={arrowBack} className="loyalty-arrow-left"/> : null}
								{rightArrowSlide ? <IonIcon icon={arrowForward} className="loyalty-arrow-right"/> : null}
								<IonSlides pager={true} options={slideOpts} ref={(r) => this.slides = r} onIonSlideDidChange={() => this.getCurrentSlide()}>
									<IonSlide>
										<div className="swiper-items">
											<div className="swiper-item">
												<div className="swiper-header">{ __('Loyalty card') }</div>
												<div className="swiper-content">
													<p>{ __('Scan to collect stamps') }</p>
													<div className="qr-holder">
														{ profile.qr_code ? <QRCode value={profile.qr_code} size={ 150 } /> : <p>{ __('QR code not available') }</p> }
													</div>
												</div>
												<IonButton expand="block" color="primary" className="default-button login-button" onClick={() => this.showModal(true)}>{ __('Voucher code') }</IonButton>
											</div>
										</div>
									</IonSlide>
									<IonSlide>
										<div className="swiper-item">
											<div className="swiper-header">{ __('Stamp card')}</div>
											<div className="swiper-content">
												<p className="stamp-header">{ __('Get your 10th bowl free')}</p>
												<div className="stamp-grid-padding">
													<div className="loyalty-grid">
														<div className={profile.available_balance > 0 ? 'loyalty-grid-stamp stamp1' : 'loyalty-grid-stamp'}>1</div>
														<div className={profile.available_balance > 1 ? 'loyalty-grid-stamp stamp2' : 'loyalty-grid-stamp'}>2</div>
														<div className={profile.available_balance > 2 ? 'loyalty-grid-stamp stamp3' : 'loyalty-grid-stamp'}>3</div>
														<div className={profile.available_balance > 3 ? 'loyalty-grid-stamp stamp4 stamped' : 'loyalty-grid-stamp stamped'}>{ __('Free Topping')}</div>
														<div className={profile.available_balance > 4 ? 'loyalty-grid-stamp stamp5' : 'loyalty-grid-stamp'}>5</div>
														<div className={profile.available_balance > 5 ? 'loyalty-grid-stamp stamp6' : 'loyalty-grid-stamp'}>6</div>
														<div className={profile.available_balance > 6 ? 'loyalty-grid-stamp stamp7' : 'loyalty-grid-stamp'}>7</div>
														<div className={profile.available_balance > 7 ? 'loyalty-grid-stamp stamp8' : 'loyalty-grid-stamp'}>8</div>
														<div className={profile.available_balance > 8 ? 'loyalty-grid-stamp stamp9' : 'loyalty-grid-stamp'}>9</div>
														<div className="loyalty-grid-palm"></div>
														<div className={profile.available_balance > 9 ? 'loyalty-grid-stamp stamp10 stamped' : 'loyalty-grid-stamp stamped'}>{ __('Free Bowl')}</div>
														<div className="loyalty-grid-palm"></div>
													</div>
												</div>
												<p className="stamp-note"><IonIcon icon={ logoInstagram } color="secondary" style={{ fontSize: '14px', marginBottom: '-3px' }} /> { appConfig.social.instagram_label } &nbsp;&nbsp;|&nbsp;&nbsp; { appConfig.social.website_label }</p>
												<p className="stamp-note bold">{ __('Cannot be used in conjunction with any other offer of loyalty card.') } <span onClick={() => history.push({ pathname: 'terms' })}>{ __('Read full T&Cs') }</span></p>
											</div>
										</div>
									</IonSlide>
									{/* <IonSlide>
										<div className="swiper-items">
											<div className="swiper-item">
												<div className="swiper-header">{ __('Enter code') }</div>
												<div className="swiper-content">
													<p>{ __('Enter a valid voucher code below to receive your reward') }</p>
													<div className="voucher-code">
														<IonItem>
															<IonLabel position="floating">{ __('Voucher code')}</IonLabel>
															<IonInput onIonChange={ e => this.handleInput('code', e.target.value) } clearInput required={ true } type="text" pattern="text" inputmode="text" value={ code }></IonInput>
															<FieldError className="field-error" value={ __(this.state.formErrors.code)} />
														</IonItem>
													</div>
													<IonButton onClick={() => this.sendCode()} expand="block">{ __('Submit Code')}</IonButton>
												</div>
											</div>
										</div>
									</IonSlide> */}
									{
										profile.vouchers ? profile.vouchers.map((voucher, index) =>
											<IonSlide key={index}>
												<div className="swiper-item">
													<div>
														<div className="swiper-header">{ __('Voucher') }</div>
														<div className="reward-image" style={{ backgroundImage: voucher.reward.image ? this.tryRequire('../../assets/images/' + voucher.reward.image) + 'url(\'https://island-poke.s3.amazonaws.com/' + voucher.reward.image + '\')' : require('../../assets/images/missing-image.png') }}></div>
														<div className="swiper-content">
															<p>{ __(voucher.reward.name) }</p>
															<span>{ __(voucher.reward.description) }</span><br /><br />
															<span>{ __(voucher.reward.small_print) }</span>
														</div>
													</div>
												</div>
											</IonSlide>
										) : null
									}
								</IonSlides>
							</IonContent> : null
						}
						</MobileView>
					</Layout>
				</Loading>
				<IonModal cssClass='loyalty-modal' isOpen={this.state.showModal} backdrop-dismiss="true" onDidDismiss={() => this.showModal(false)}>
					<div>
						<IonToolbar color="primary">
							<IonButtons slot="start">
								<IonButton button clear style={{ visibility: 'hidden' }}>
									<IonIcon slot="icon-only" icon={ close } />
								</IonButton>
							</IonButtons>

							<IonTitle className="ion-text-center modal-title">{ __('Enter code')}</IonTitle>

							<IonButtons slot="end">
								<IonButton button clear onClick={() => this.showModal(false)}>
									<IonIcon slot="icon-only" icon={ close } />
								</IonButton>
							</IonButtons>
						</IonToolbar>
						<div className="ion-text-center box-padded">
							<p>{ __('Enter a valid voucher code below to receive your reward')}</p>
							<IonList>
								<IonItem>
									<IonLabel position="floating">{ __('Voucher code')}</IonLabel>
									<IonInput onIonChange={ e => this.handleInput('code', e.target.value) } clearInput required={ true } type="text" pattern="text" inputmode="text" value={ code }></IonInput>
									<FieldError className="field-error" value={ __(this.state.formErrors.code)} />
								</IonItem>
							</IonList>
						</div>
						<IonButton onClick={() => this.sendCode()} expand="block">{ __('Submit Code')}</IonButton>
					</div>
				</IonModal>

				<IonModal cssClass='loyalty-modal'isOpen={mobileValidate} backdrop-dismiss="true" onDidDismiss={() => this.showModal2(false)}>
					<div>
						<IonToolbar color="primary">
							<IonButtons slot="start">
								<IonButton button clear style={{ visibility: 'hidden' }}>
									<IonIcon slot="icon-only" icon={ close } />
								</IonButton>
							</IonButtons>

							<IonTitle className="ion-text-center modal-title">{ __('Validate account')}</IonTitle>

							<IonButtons slot="end">
								<IonButton button clear onClick={() => this.showModal2(false)}>
									<IonIcon slot="icon-only" icon={ close } />
								</IonButton>
							</IonButtons>
						</IonToolbar>
						<div className="ion-text-center box-padded">
							<p>{ __('For account validation purposes, enter your mobile number to receive a verification code')}</p>
							<IonList>
								<IonItem>
									<IonLabel position="floating">{ __('Enter Mobile Number')}</IonLabel>
									<IonInput onIonChange={ e => this.handleInput('mobile', e.target.value) } clearInput required={ true } type="tel" pattern="tel" inputmode="tel" value={ mobile }></IonInput>
									<FieldError className="field-error" value={ __(this.state.formErrors2.mobile)} />
								</IonItem>
							</IonList>
						</div>
						<IonButton onClick={() => this.sendMobile()} expand="block">{ __('Submit')}</IonButton>
					</div>
				</IonModal>

				<IonModal cssClass='loyalty-modal' isOpen={mobileCheckSms} backdrop-dismiss="true" onDidDismiss={() => this.showModal3(false)}>
					<div>
						<IonToolbar color="primary">
							<IonButtons slot="start">
								<IonButton button clear style={{ visibility: 'hidden' }}>
									<IonIcon slot="icon-only" icon={ close } />
								</IonButton>
							</IonButtons>

							<IonTitle className="ion-text-center modal-title">{ __('Enter verification code')}</IonTitle>

							<IonButtons className="close" slot="end">
								<IonButton button clear onClick={() => this.showModal3(false)}>
									<IonIcon slot="icon-only" icon={ close } />
								</IonButton>
							</IonButtons>
						</IonToolbar>
						<div className="ion-text-center box-padded">
							<p>{ __('Enter the verification code you received')}</p>
							<IonList>
								<IonItem>
									<IonLabel position="floating">{ __('Enter verification code')}</IonLabel>
									<IonInput onIonChange={ e => this.handleInput('verification', e.target.value) } clearInput required={ true } type="number" pattern="number" inputmode="number" value={ verification }></IonInput>
									<FieldError className="field-error" value={ __(this.state.formErrors3.verification)} />
								</IonItem>
							</IonList>
						</div>
						<IonButton onClick={() => this.sendCheckSms()} expand="block">{ __('Submit')}</IonButton>
					</div>
				</IonModal>
			</>
		)
	}
}

const stateToProps = (state) => {
	const { profile } = state.auth
	const { mobileValidate, mobileCheckSms } = state.common
	const { basket } = state.order
	//console.log('VOUCHERS ', profile, profile.vouchers, mobileValidate)
	return {
		profile,
		vouchers: profile.vouchers || [],
		mobileValidate,
		mobileCheckSms,
		basket
	}
}

export default connect(stateToProps)(withRouter(withTranslation(Loyalty)))
