import React from 'react'
import { IonButton,	IonTextarea, IonIcon } from '@ionic/react'
import { starOutline, star } from 'ionicons/icons'
import Layout from '../../components/layout'
import { FieldError } from '../../components/common'
import { validateForm } from '../../lib/utils'
import { withTranslation } from '../../lib/translate'
import { sendFeedback } from '../../store/actions'
import Loading from '../../components/spinner'
import './index.css'

class Feedback extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			food: 0,
			service: 0,
			customerService: false,
			techSupport: false,
			comment: '',
			formErrors: {}
		}
		this.formConfig = {
			food: { type: 'integer', required: true, invalidValue: 0 },
			service: { type: 'integer', required: true, invalidValue: 0 }
		}
	}

	createStarSet(size, name) {
		return (
			<>
				{
					[...Array(size).keys()].map(i => {
						return this.createStar(name, i + 1)
					})
				}
			</>
		)
	}

	createStar = (name, value) => {
		if (this.state[name] >= value) {
			return <IonIcon key={ value } icon={ star } className="star" color="primary" onClick={ () => this.setValue(name, value) } />
		} else {
			return <IonIcon key={ value } icon={ starOutline } className="star" color="medium" onClick={ () => this.setValue(name, value) } />
		}
	}

	handleFeedback = () => {
		let formErrors = validateForm(this.formConfig, this.state, this.props.__)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			const data = {
				feedback_type: 'standard in-app feedback form',
				feedback_response: this.state.comment,
				food_score: this.state.food,
				service_score: this.state.service
			}
			this.setState({
				food: 0,
				service: 0,
				customerService: false,
				techSupport: false,
				comment: '',
				formErrors: {}
			})
			this.props.dispatch(sendFeedback(data))
		}
	}

	setValue(field, value) {
		this.setState({ [field]: value })
	}

	render() {
		const { __ } = this.props
		return (
			<Loading transparent>
				<Layout headerTitle={ __('Feedback') } color='white'>
					<h3>{ __('Please leave your feedback below')}</h3>
					{/* <div className="box-holder">
						<IonList>
							<IonItem>
								<IonLabel>
									<h2>{ __('Customer Service') }</h2>
									<IonNote>{ __('Any comments related to your visit')}</IonNote>
								</IonLabel>
								<IonCheckbox slot="end" color="secondary" className="top-small" value={ this.state.customeService } onIonChange={ event => this.setValue('customerService', event.detail.checked) }/>
							</IonItem>
							<IonItem line="none">
								<IonLabel>
									<h2>{ __('Tech Support') }</h2>
									<IonNote>{ __('For technical help using our app') }</IonNote>
								</IonLabel>
								<IonCheckbox slot="end" color="secondary" className="top-small" value={ this.state.techSupport } onIonChange={ event => this.setValue('techSupport', event.detail.checked) } />
							</IonItem>
						</IonList>
					</div> */}
					<div className="box-holder top-medium pad10">
						<div className="feedback-label">{ __('Rate Your Bowl') }</div>
						<div style={{ marginBottom: 10 }}>
							{ this.createStarSet(5, 'food') }
						</div>
						<FieldError className="field-error pad5l" value={ __(this.state.formErrors.food) } />
						<div className="feedback-label">{ __('Rate Our Team')}</div>
						<div style={{ marginBottom: 10 }}>
							{ this.createStarSet(5, 'service') }
						</div>
						<FieldError className="field-error pad5l" value={ __(this.state.formErrors.service) } />
						<div className="feedback-label">{ __('Additional Comments') }</div>
						<IonTextarea value={ this.state.comment } onIonChange={ event => this.setValue('comment', event.detail.value) }></IonTextarea>
						<div className="separator"></div>
						<div className="top-medium">
							<IonButton expand="block" color="primary" onClick={ this.handleFeedback }>{ __('Submit') }</IonButton>
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

export default withTranslation(Feedback)
