import {
	SET_LOCATION_AND_TIME,
	SELECT_CATEGORY,
	ADD_TO_BASKET,
	CLEAR_BASKET,
	GET_MY_ORDERS,
	SET_ORDER_DETAILS,
	CREATE_ORDER,
	ADD_VOUCHER,
	SET_DISCOUNTS,
	ADD_SCANNED_CARD,
	REMOVE_PAYMENT_CARD,
	LIST_PAYMENT_CARDS,
	SET_ORDERS_PROP
} from './constants'

export const setLocationAndTime = (restaurantId, time, restaurant) => ({ type: SET_LOCATION_AND_TIME, restaurantId, time, restaurant })

export const selectCategory = (category) => ({ type: SELECT_CATEGORY, category })

export const addToBasket = (data) => ({ type: ADD_TO_BASKET, data })

export const clearBasket = () => ({ type: CLEAR_BASKET })

export const getMyOrders = () => ({ type: GET_MY_ORDERS })

export const setOrderDetails = (data) => ({ type: SET_ORDER_DETAILS, data })

export const createOrder = (additionalOrderData = {}, options = {}) => ({ type: CREATE_ORDER, additionalOrderData, options })

export const addVoucher = (voucher) => ({ type: ADD_VOUCHER, voucher })

export const setDiscounts = (discounts) => ({ type: SET_DISCOUNTS, discounts })

export const addScannedCard = (name = '', payment_token = '', options = {}) => ({ type: ADD_SCANNED_CARD, name, options, payment_token })

export const removePaymentCard = (cardToken, options = {}) => ({ type: REMOVE_PAYMENT_CARD, cardToken, options })

export const getPaymentCards = () => ({ type: LIST_PAYMENT_CARDS })

export const setOrdersProp = (key, value, merge = false) => ({ type: SET_ORDERS_PROP, key, value, merge })
