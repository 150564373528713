import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { addToBasket } from '../../../store/actions'
import { IonButton, IonIcon, IonSlides, IonSlide, IonList, IonItem, IonLabel, IonInput, IonListHeader, IonRadioGroup, IonRadio, IonCheckbox } from '@ionic/react'
import Layout from '../../../components/layout'
import SimpleLoader from '../../../components/simpleLoader'
import { withTranslation } from '../../../lib/translate'
import Modal from '../../../components/modal'
import Alert from '../../../components/alert'
import appConfig from '../../../appConfig'
import { isEmptyObject, forwardTo, isDefined, isDesktop, currencySign, price } from '../../../lib/utils'
import * as icons from 'ionicons/icons'
import { DesktopView } from '../../../components/desktopView'


require('./index.css')
if (isDesktop()) {
	require('./desktop.css')
} else {
	require('./mobile.css')
}

const s3Url = appConfig.configS3.url

class Categories extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedCategory: {},
			selectedProduct: null,
			showProductModal: false,
			is_byob: false,
			byob_type: 'is_base_it',
			bowl: [],
			base_it_items: [],
			top_it_items: [],
			finish_it_items: [],
			drinksAndSnacks: [],
			sauces: [],
			is_bowl: false,
			is_sauce: false,
			additional: false,
			modifiers: {
				radio: null,
				check: []
			},
			showAlert: false,
			quantity: 1,
			basket: {},
			notes: ''

		}
		this.onBackHandler = this.onBackHandler.bind(this)
		this.onForwardHandler = this.onForwardHandler.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.closeAlert = this.closeAlert.bind(this)
	}

	componentDidMount(){
		const { selectedCategory } = this.props
		const is_byob = selectedCategory && selectedCategory.category ? selectedCategory.category.attributes.is_byob : false
		const is_bowl = selectedCategory && selectedCategory.category ? selectedCategory.category.attributes.is_bowl : false
		const is_sauce = selectedCategory && selectedCategory.category ? selectedCategory.category.attributes.is_sauce : false
		this.setState({ selectedCategory, is_byob, is_bowl, is_sauce, additional: false })
	}

	changeQuantity = (val, product, type) => {
		const { drinksAndSnacks, is_sauce, bowl, sauces } = this.state
		let quantity = product.quantity ? product.quantity : 1
		if (sauces.length > 0) {
			let item = sauces.find(sauce => sauce.product[0].id === product.product[0].id)
			let q = 0
			if (item) {
				quantity = item && item.quantity ? item.quantity : 0
				q = quantity + val
				if (q < 1) {
					q = 0
				}
				item.quantity = q
			}
		}
		let qty = quantity + val
		if (qty < 1) {
			qty = 0
		}
		let newProduct = {...product}
		newProduct.quantity = qty
		this.setState({ quantity: qty }, () => {
			if (type) {
				// have to handle this
				this.quantityModal(newProduct, is_sauce ? bowl : drinksAndSnacks)
			} else {
				this.displayProduct(newProduct)
			}
		})
	}

	displayProduct = (product) => {
		const { __, currency } = this.props
		let item = null
		let itemPrice = null
		let bowlItem = []
		let quantity = product.quantity ? product.quantity : 1
		item = product.product[0]
		itemPrice = price(item.price)
		bowlItem.push(product)
		const selectedProduct =
			<div className='item-content'>
				<div className='item-header'>
					<div className='item-name'>{item.order_name.toUpperCase()}</div>
					<div className='item-close'><IonIcon slot="icon-only" icon="close" onClick={ this.closeModal }></IonIcon></div>
				</div>
				<div className='item-image' style={{backgroundImage: `url('${s3Url + item.image}')`}}></div>
				{item.description !== '' ? <div className='item-description'>{item.description}</div> : null }
				<div className='item-actions'>
					{this.selectBowlSizeOption(product)}
					<div className='item-actions-header'>{ __('Update Quantity') }</div>
					<div className='item-action'>
						<div className='quantity-button' onClick={() => { this.changeQuantity(-1, product) }}>
							<IonButton expand="full"> <IonIcon slot="icon-only" icon={icons.remove} /></IonButton>
						</div>
						<div className='item-quantity'>{quantity}</div>
						<div className='quantity-button' onClick={() => { this.changeQuantity(1, product) }}>
							<IonButton expand="full"> <IonIcon slot="icon-only" icon={icons.add} /> </IonButton>
						</div>
					</div>
					{currency === 'gbp' ?
						<div className='item-price'>{itemPrice ? currencySign(currency) : ''}{ itemPrice }</div> :
						<div className='item-price'>{ itemPrice }{itemPrice ? currencySign(currency) : ''}</div>
					}
				</div>
				<IonButton expand='full' className='select-base' onClick={() => {this.showModalBase(product, 'modifiers')}}>{ __('Select Base') }</IonButton>
			</div>
		this.setState({selectedProduct, showProductModal: true, bowl: bowlItem})
	}

	drawHorizontalSubCategories = (subCategory, index, byob, categoryName) => {
		const { byob_type, base_it_items, top_it_items, finish_it_items, is_sauce, is_byob, additional, sauces, is_bowl } = this.state
		const { __, currency, basket } = this.props
		const slideOpts = {
			initialSlide: 0,
			speed: 400,
			// slidesPerView: 3,
			spaceBetween: 10,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			breakpoints: {
				320: {
					slidesPerView: 3
				},
				480: {
					slidesPerView: 3
				},
				640: {
					slidesPerView: 3
				},
				991: {
					slidesPerView: 5
				},
				1440: {
					slidesPerView: 5
				},
				1920: {
					slidesPerView: 6
				},
				2160: {
					slidesPerView: 7
				},
				2560: {
					slidesPerView: 7
				}
			}
		}
		const subCategoryName = subCategory.subCategory.name

		return (
			<div key={index}>
				<DesktopView>
					{
						(is_sauce || !is_byob || additional) && index === 0
							?
							<div className='desktop-list-title'>{additional ? 'ANYTHING ELSE?' : is_sauce ? 'ADD A ' + categoryName : categoryName}</div>
							:
							null
					}
				</DesktopView>
				<div className={'horizontal-slider-content' + byob} key={index}>
					<div className='desktop-sauce-wrapper'>
						<div className={'category-title' + byob}>{ __(subCategoryName) }</div>
						<div className='horizontal-slider'>
							<IonSlides pager={false} options={slideOpts}>
								{subCategory.products.
									sort((a, b) => a.product[0].sort_order > b.product[0].sort_order ? 1 : -1).
									map((product, i) => {
										const item = product.product[0]
										const itemPrice = price(item.price)
										let selected = false

										if (byob_type === 'is_finish_it') {
											selected = finish_it_items.find(product => product.product[0].id === item.id ? true : false)
										} else if (byob_type === 'is_top_it') {
											selected = top_it_items.find(product => product.product[0].id === item.id ? true : false)
										} else {
											selected = base_it_items.find(product => product.product[0].id === item.id ? true : false)
										}
										if (sauces.length > 0) {
											selected = sauces.find(product => product.product[0].id === item.id ? true : false)
										}
										if (basket && basket.items.length > 0 && !is_sauce && !is_byob && !is_bowl ) {
											selected = basket.items.find(product => product.product === item.id ? true : false)
										}
										return (
											<IonSlide key={item.id}
												onClick={() => {
													this.pickItems(product, byob)
												}}>
												<div className='item-slide'>
													<div className={selected ? 'item-slide-image-selected' : 'item-slide-image'}>
														<img alt='' style={{ opacity: selected ? 0.5 : 1 }} src={s3Url + item.image} />
														{selected ?
															<div className='selected-item-checkmark'>
																<IonIcon color='white' icon={icons.checkmark}/>
															</div>
											 : null }
													</div>
													<p className='item-slide-name'>{item.order_name}</p>
													<p className='item-slide-price'> {itemPrice ? this.formatPrice(itemPrice) : '' }</p>
												</div>
											</IonSlide>
										)
									})}
							</IonSlides>
						</div>
					</div>
				</div>
			</div>
		)
	}

	pickItems = (product, byob) => {
		const { __ } = this.props
		const { byob_type, finish_it_items, drinksAndSnacks } = this.state
		if (byob !== '') {
			let b_type = ''
			if (byob_type === 'is_base_it') {
				b_type = 'is_top_it'
				const base_it_items = []
				base_it_items.push(product)
				this.setState({ byob_type: b_type, base_it_items })
			} else if (byob_type === 'is_top_it') {
				b_type = 'is_finish_it'
				const top_it_items = []
				top_it_items.push(product)
				this.doubleUpModal(product)
				this.setState({ byob_type: b_type, top_it_items})
			} else {
				let items = [...finish_it_items]
				let findItem = finish_it_items.length > 0 ? !!finish_it_items.find(item => item.product[0].id === product.product[0].id) : false
				if (findItem) {
					items = finish_it_items.filter(item => item.product[0].id !== product.product[0].id)
				} else {
					items.push(product)
				}
				this.setState({ finish_it_items: items })
			}
		} else {
			let items = [...drinksAndSnacks, product]
			let selectedProduct =
				<div>
					<div className='item-third-header'>
						<IonLabel size={20}>{ product.product[0].order_name.toUpperCase()}</IonLabel>
						<IonIcon icon={icons.close} onClick={()=> { this.closeModal() }}/>
					</div>
					<IonList className='item-third-list'>
						{product.product[0].sequences.map((sequnce, i) => {
							return sequnce.options.map((option, idx) => {
								return (
									<IonItem key={idx} onClick={() => {this.quantityModal(product, items, idx)}}>
										<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
											<IonLabel>{option.name}</IonLabel>
										</div>
									</IonItem>
								)
							})
						})}
					</IonList>
					<IonButton expand='full' disabled className='select-base-button'> { __('Add to Basket') }</IonButton>
				</div>


			if (product.product[0].sequences.length > 0) {
				this.setState({ showProductModal: true, selectedProduct })
			} else {
				this.quantityModal(product, items)
			}
		}
	}

	addTopItExtra = (type, obj) => {
		const { top_it_items } = this.state
		if (type !== 'no') {
			top_it_items[0].top_it_attributes = obj
			top_it_items[0].double = type === 'double' ? true : false
			this.setState({ showProductModal: false, top_it_items })
		} else {
			delete top_it_items[0].double
			delete top_it_items[0].top_it_attributes
			this.setState({ showProductModal: false, top_it_items })
		}
	}

	setModifiers = (modifier, type) => {
		const { modifiers } = this.state
		const newModifiers = {...modifiers}
		if (type === 'radio') {
			newModifiers[type] = modifier
		} else {
			let filteredModifier = !!newModifiers[type].find(mod => mod === modifier)
			if (!filteredModifier) {
				newModifiers[type].push(modifier)
			} else {
				newModifiers[type] = newModifiers[type].filter(mod => mod !== modifier)
			}
		}
		this.setState({ modifiers: newModifiers})
	}

	setBowl = (min) => {
		const { location } = this.props
		const { modifiers } = this.state
		if (!modifiers.radio && modifiers.check.length < 1) {
			this.setState({ showAlert: true })
		} else {
			if (min > modifiers.check.length) {
				this.setState({ showAlert: true })
			} else {
				let categories = location.state.categories
				let cat = {}
				Object.keys(categories).forEach((key) => {
					if (categories[key].category.attributes.is_sauce) {
						cat = categories[key]
					}
				})
				this.setState({ is_bowl: false, is_sauce: true, showProductModal: false, selectedCategory: cat, quantity: 1 })
			}
		}
	}

	showModalBase = (product) => {
		const { __, currency } = this.props
		const item = product.product[0]
		let min = null
		let selectedProduct =
			<div className='item-content'>
				<div className='item-header'>
					<div className='item-name'>{ __('Options') }</div>
					<div className='item-close'><IonIcon slot="icon-only" icon="close" onClick={ () => { this.displayProduct(product)} }></IonIcon></div>
				</div>
				<div className='item-actions-base'>
					{item.modifiers.map((modifier, index) => {
						// eslint-disable-next-line no-console
						console.log('modifier', modifier)
						min = modifier.min_select
						let radio = null
						if (modifier.max_select === 1){
							radio = true
						} else if (modifier.max_select > 1){
							radio = false
						} else {
							radio = false
						}

						return (
							<IonList key={index}>
								<IonListHeader className='list-header'>
									<IonLabel>{ __(modifier.name) }</IonLabel>
								</IonListHeader>
								<div>
									{ radio ?
										<IonRadioGroup>
											<div className="select-base-content">
												{

													modifier.items.map((item, i) => {
														const itemPrice = price(item.price)
														return (
															<IonList key={i}>
																<IonItem>
																	<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
																		<IonRadio slot="start" value={item.item_sku} onIonSelect={(e) => { this.setModifiers(e.target.value, 'radio')}}/>
																	</div>
																	<IonLabel>{item.description}</IonLabel>
																	<p>{itemPrice ? this.formatPrice(itemPrice) : ''}</p>
																</IonItem>
															</IonList>
														)
													})

												}
											</div>
											<div className='arrow-down'>
												<IonIcon slot="icon-only" color='medium' size="large" icon={icons.arrowDown} />
											</div>
										</IonRadioGroup>

										:

										<div>
											{
												modifier.items.map((item, i) => {
													const itemPrice = price(item.price)
													return (
														<IonList key={i}>
															<IonItem>
																<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
																	<IonCheckbox value={item.item_sku} slot="start" onIonChange={(e) => {this.setModifiers(e.target.value, 'check')}}/>
																</div>
																<IonLabel>{item.description}</IonLabel>
																<p>{ itemPrice ? '+' + this.formatPrice(itemPrice) : null }</p>
															</IonItem>
														</IonList>
													)
												})
											}
										</div>

									}
								</div>
							</IonList>
						)
					})}
					<div className='special-notes'>
						<IonLabel> { __('Special Notes and Allergies') }</IonLabel>
						<IonInput type='text' value={this.state.notes} placeholder={ __('Enter notes here') } onIonChange={(e)=> {this.setState({ notes: e.target.value})}}></IonInput>
					</div>
				</div>
				<IonButton expand='full' className='select-base' onClick={() => { this.setBowl(min)}}> { __('Add to Basket') }</IonButton>
			</div>


		this.setState({ selectedProduct })
	}

	formatPrice = price => this.props.currency === 'gbp' ? currencySign(this.props.currency) + price : price + currencySign(this.props.currency)

	doubleUpModal = (product) => {
		const { __, currency } = this.props
		const productName = product.product[0].published_name ? product.product[0].published_name.toUpperCase() : ''
		const displayPrice = product.product[0].attributes.displayPrice
		// const canDoubleUp = product.product[0].attributes.canDoubleUp
		// const comboText = product.product[0].attributes.comboText ? product.product[0].attributes.comboText.toUpperCase() : ''
		// const combo = product.product[0].attributes
		const attributes = product.product[0].attributes

		const canDoubleUp = attributes.canDoubleUp
		const doubleUpText = attributes.doubleUpText ? attributes.doubleUpText.toUpperCase() : ''
		const doubleUpObj = { displayPrice: attributes.doubleUpPrice || displayPrice, comboSku: attributes.doubleUpSku || attributes.comboSku, comboText: doubleUpText || attributes.comboText, image: attributes.doubleUpImg }

		const canCombo = attributes.canCombo
		const comboText = attributes.comboText ? attributes.comboText.toUpperCase() : ''
		const comboObj = { displayPrice: attributes.comboPrice, comboSku: attributes.comboSku, comboText, image: attributes.comboImg }

		let selectedProduct = <div>
			<div className='item-third-header'>
				<IonLabel size={20}>{ __('Double up?') }</IonLabel>
			</div>
			<div className='item-actions'>
				<div className='item-actions-header'>{__('Would you like to double up your protein for an extra')}&nbsp;{this.formatPrice(displayPrice)}</div>
				{ canDoubleUp ? <IonButton text-wrap expand='full' style={{ whiteSpace: 'pre-wrap' }} color='tertiary' disabled={false} onClick={() => {this.addTopItExtra('double', doubleUpObj)}}>{__('Yes')} - {doubleUpText || productName} - {this.formatPrice(doubleUpObj.displayPrice)}</IonButton> : null }
				{ canCombo ? <IonButton style={{ whiteSpace: 'pre-wrap' }} expand='full' color='tertiary' disabled={false} onClick={() => {this.addTopItExtra('combo', comboObj)}}>{__('Yes')} - { comboText } - {this.formatPrice(comboObj.displayPrice)}</IonButton> : null }
				<IonButton className='no-thanks-button' expand='full' fill='clear' disabled={false} onClick={() => {this.addTopItExtra('no')}}>{ __ ('No thanks') }</IonButton>
			</div>
		</div>
		this.setState({ showProductModal: true, selectedProduct })
	}

	setSauce = (product) => {
		const { sauces } = this.state
		let s = null
		let aleradyAddedSauce = sauces.find(sauce => sauce.product[0].id === product.product[0].id)
		if (aleradyAddedSauce) {
			sauces.forEach((sauce) => {
				if (sauce.product[0].id === aleradyAddedSauce.product[0].id) {
					sauce.product.quantity = product.quantity
				}
			})
			if (product.quantity === 0) {
				s = sauces.filter(sauce => sauce.product[0].id !== product.product[0].id)
			}
			let newSauces = s ? s : sauces
			this.setState({ sauces: newSauces, showProductModal: false })
		} else {
			let newSauces = [...sauces, product]
			this.setState({ sauces: newSauces, showProductModal: false })
		}
	}

	quantityModal = (product, items, sequenceOptionIndex) => {
		const { __ } = this.props
		const { is_sauce, sauces } = this.state
		let quantity = product.quantity ? product.quantity : 1
		if ( sauces.length > 0) {
			let item = sauces.find(sauce => sauce.product[0].id === product.product[0].id)
			if (item) {
				quantity = item && item.quantity ? item.quantity : 0
			}
		}

		let selectedProduct =
			<div className='category-content'>
				<div className='item-third-header'>
					<IonLabel size={20}>{ product.product[0].order_name.toUpperCase()}</IonLabel>
					<IonIcon icon={icons.close} onClick={()=> { this.closeModal() }}/>
				</div>
				<div className='item-actions'>
					<div className='item-actions-header'> { __('Update Quantity') }</div>
					<div className='item-action'>
						<div className='quantity-button' onClick={() => { this.changeQuantity(-1, product, 'quantity') }}>
							<IonButton disabled={is_sauce ? quantity > 0 ? false : true : quantity > 1 ? false : true } expand="full"> <IonIcon slot="icon-only" icon={icons.remove} /></IonButton>
						</div>
						<div className='item-quantity'>{quantity}</div>
						<div className='quantity-button' onClick={() => { this.changeQuantity(1, product, 'qunatity') }}>
							<IonButton expand="full"> <IonIcon slot="icon-only" icon={icons.add} /> </IonButton>
						</div>
					</div>
				</div>
				<IonButton expand='full' disabled={false} className='select-base-button' onClick={() => is_sauce ? this.setSauce(product) : this.add('drinksAndSnacks') }> { __('Add to Basket') }</IonButton>
			</div>


		if (!is_sauce) {
			product.quantity = quantity
			if (!isDefined(product.sequences)) {
				product.sequences = product.product[0].sequences.length ? product.product[0].sequences[0].options[sequenceOptionIndex] : null
			}
			this.setState({ showProductModal: true, selectedProduct, drinksAndSnacks: [product] })
		} else {
			this.setState({ showProductModal: true, selectedProduct })
		}
	}

	add = (type, redirect) => {
		const { dispatch, location, basket, history } = this.props
		const { modifiers, drinksAndSnacks, bowl, base_it_items, top_it_items, finish_it_items, sauces, notes } = this.state
		let newBasket = {...basket}
		if (!isDefined(newBasket.items)) {
			newBasket = {items: []}
		}
		if (type === 'drinksAndSnacks') {

			let product = drinksAndSnacks[0]
			let sequences = product.sequences
			let s_items = []
			if (sequences){
				 s_items[0] = {
					 item_sku: sequences.sku,
					 item_price: sequences.price,
					 item_name: sequences.name
				 }
			}
			let item = {
				product: product.product[0].id,
				qty: product.quantity,
				name: product.product[0].order_name,
				price: product.product[0].price,
				sub_items: s_items,
				image: product.product[0].image,
				notes: product.notes,
				sku: s_items && s_items.length > 0 ? s_items[0].item_sku : product.product[0].sku
			}
			newBasket.items.push(item)
			this.setState({
				showProductModal: false,
				additional: false,
				is_byob: false,
				is_bowl: false,
				is_sauce: false,
				quantity: 1
			})
		} else if (type === 'bowl') {
			let findModifier = []
			Object.keys(modifiers).forEach((modifier) => {
				bowl[0].product[0].modifiers.forEach(bowlModifier => {
					if (modifier === 'radio') {
						let item = bowlModifier.items.find(item => item.item_sku === modifiers.radio)
						if (item) {
							let sub_product = {
								item_sku: item.item_sku,
								item_price: item.price,
								item_name: item.description
							}
							findModifier.push(sub_product)
						}
					} else {
						modifiers.check.forEach(check => {
							let item = bowlModifier.items.find(item => item.item_sku === check)
							if (item) {
								let sub_product = {
									item_sku: item.item_sku,
									item_price: item.price,
									item_name: item.description
								}
								findModifier.push(sub_product)
							}
						})

					}
				})
			})

			bowl[0].sub_items = findModifier
			bowl[0].notes = notes
			let product = bowl[0]
			let item = {
				product: product.product[0].id,
				qty: product.quantity || 1,
				name: product.product[0].order_name,
				price: product.product[0].price,
				sub_items: product.sub_items || [],
				image: product.product[0].image,
				notes: product.notes,
				sku: product.product[0].sku
			}
			sauces.forEach((sauce) => {
				let souceItem = {
					item_sku: sauce.product[0].sku,
					item_price: sauce.product[0].price,
					item_name: sauce.product[0].order_name,
					item_qty: sauce.quantity
				}
				item.sub_items.push(souceItem)
			})
			newBasket.items.push(item)
			this.setState({
				showProductModal: false,
				additional: false,
				is_byob: false,
				is_bowl: false,
				is_sauce: false,
				quantity: 1
			})
			forwardTo(history, 'menu')
		} else {
			let categories = location.state.categories
			let cat = {}
			let byob_items = {}
			let byob_item = {}
			Object.keys(categories).forEach((key) => {
				let attributes = categories[key].category.attributes
				if (!attributes.is_sauce && !attributes.is_byob && !attributes.is_bowl) {
					cat = categories[key]
				}
				if (attributes.is_bowl) {
					Object.keys(categories[key].subCategories).forEach((subKey) => {
						let attrSub = categories[key].subCategories[subKey].subCategory.attributes ? categories[key].subCategories[subKey].subCategory.attributes.is_byob : null
						if (attrSub) {
							byob_item = categories[key].subCategories[subKey].products[0]
						}
					})

				}
			})
			byob_items.sub_items = []
			byob_items.notes = this.state.notes
			base_it_items.forEach(item => {
				let sub_product = {
					item_sku: item.product[0].sku,
					item_price: item.product[0].price,
					item_name: item.product[0].order_name
				}
				byob_items.sub_items.push(sub_product)
			})

			top_it_items.forEach(item => {
				let sub_product = {
					item_sku: item.product[0].sku,
					item_price: item.product[0].price,
					item_name: item.product[0].order_name
				}
				byob_items.sub_items.push(sub_product)
				if (item.top_it_attributes) {
					let price = parseFloat(item.top_it_attributes.displayPrice) * 100
					let extra_item = {
						item_sku: item.top_it_attributes.comboSku,
						item_price: price,
						item_name: item.double ? 'Extra ' + item.product[0].published_name : 'Extra ' + item.top_it_attributes.comboText
					}
					byob_items.sub_items.push(extra_item)
				}
			})
			finish_it_items.forEach(item => {
				let sub_product = {
					item_sku: item.product[0].sku,
					item_price: item.product[0].price,
					item_name: item.product[0].order_name
				}
				byob_items.sub_items.push(sub_product)
			})

			byob_items.name = byob_item.product[0].order_name
			byob_items.product = byob_item.product[0].id
			byob_items.sku = byob_item.product[0].sku
			byob_items.price = byob_item.product[0].price
			byob_items.image = byob_item.product[0].image

			let product = byob_items
			let item = {
				product: product.product,
				qty: 1,
				name: product.name,
				price: product.price,
				sub_items: product.sub_items || [],
				image: product.image,
				notes: product.notes,
				sku: product.sku
			}
			newBasket.items.push(item)
			this.setState({
				showProductModal: false,
				selectedCategory: cat,
				additional: true,
				is_byob: false,
				is_bowl: false,
				is_sauce: false,
				quantity: 1,
				base_it_items
			})
		}
		dispatch(addToBasket(newBasket))
	}

	notesModal = () => {
		const { __ } = this.props
		let notesModal =
			<div>
				<div className='item-third-header'>
					<IonLabel size={20}>{ __('Special Notes and Allergies') }</IonLabel>
					<IonIcon icon={icons.close} onClick={()=> { this.closeModal() }}/>
				</div>
				<div className='item-actions'>
					<IonInput type='text' value={this.state.notes} placeholder={ __('Enter notes here') } onIonChange={(e)=> {this.setState({ notes: e.target.value})}}></IonInput>
				</div>
				<IonButton expand='full' disabled={false} className='select-base-button' onClick={() => { this.add() }}> { __('Add to Basket') }</IonButton>
			</div>

		this.setState({ showProductModal: true, selectedProduct: notesModal })
	}

	closeModal = () => {
		this.setState({ showProductModal: false })
	}

	closeAlert = () => {
		this.setState({ showAlert: false })
	}

    displayItems = () => {
    	const { currency } = this.props
    	let byobIndex = null
    	const { __, history } = this.props
    	const { selectedCategory, byob_type, base_it_items, top_it_items, finish_it_items, is_byob, additional, is_sauce } = this.state
    	const categoryName = selectedCategory.category && selectedCategory.category.name ? selectedCategory.category.name : ''
    	const subCategory = selectedCategory.subCategories || {}
    	let top_it_attributes_sku = top_it_items.length && top_it_items[0].top_it_attributes && top_it_items[0].double === false ? top_it_items[0].top_it_attributes.comboSku : null
    	let additionalItem = isEmptyObject(selectedCategory) && !top_it_attributes_sku ? null : selectedCategory.products.find(item => item.product[0].sku === top_it_attributes_sku)
    	let additionalImage = additionalItem ? additionalItem.product[0].bowl_image : ''
    	return Object.keys(subCategory).
    		sort((a, b) => subCategory[a].subCategory.sort_order > subCategory[b].subCategory.sort_order ? 1 : -1).
    		map((key, index) => {
    			if (subCategory[key].subCategory.attributes.display_mode === 'vertical') {
    				return (
    					<div key={index}>
    						<DesktopView>
    							<div className='desktop-list-title'>{ __(additional ? 'ANYTHING ELSE?' : is_sauce ? 'ADD A ' + categoryName : categoryName)}</div>
    						</DesktopView>
    						<div className='subcategory-name'>{key.toUpperCase()}</div>
    						<div className='items-list-content'>
    							{subCategory[key].products.
    								sort((a, b) => a.product[0].sort_order > b.product[0].sort_order ? 1 : -1).
    								map((product, i) => {
    									const item = product.product[0]
    									const itemPrice = price(item.price)
    									return (
    										<div key={i} className='veritcal-item'
    											onClick={() => {this.displayProduct(product)}}
    										>
    											<div className='veritcal-item-image' style={{backgroundImage: `url('${s3Url + item.image}')`}}></div>
    											<div className='vertical-item-name'>{item.order_name}</div>
    											<div className='vertical-item-price'>{ itemPrice ? this.formatPrice(itemPrice) : null}</div>
    										</div>
    									)
    								})}
    						</div>
    					</div>
    				)
    			} else if (subCategory[key].subCategory.attributes.display_mode === 'horizontal') {
    				if (is_byob) {
    					let type = subCategory[key].subCategory.attributes[byob_type]
    					let baseItImage = base_it_items.length > 0 ? base_it_items[0].product[0].image : null
    					let topItImage = top_it_items.length > 0 ? top_it_items[0].double ? top_it_items[0].product[0].bowl_image_double : top_it_items[0].product[0].bowl_image : null
    					if (type) {byobIndex = index}
    					return index === byobIndex ?
    						<div key={index} className='desktop-byob-wrapper'>
    							<DesktopView>
    								<div className='desktop-byob-title'>
    									<IonIcon icon={icons.arrowBack} onClick={() => byob_type !== 'is_base_it' ? this.onBackHandler() : history.goBack()}/>
    									<h2>{ __(additional ? 'ANYTHING ELSE?' : is_sauce ? 'ADD A ' + categoryName : categoryName)}</h2>
    									<IonIcon icon={byob_type !== 'is_finish_it' && is_byob ? icons.arrowForward : null} onClick={() => this.onForwardHandler()} />
    								</div>
    							</DesktopView>
    							<div className='byob-header'>
    								<div className='byob-image' style={{backgroundImage: `url('${s3Url + baseItImage}')`}}>
    									<div className='top-image' style={{backgroundImage: `url('${s3Url + topItImage}')`}} />
    									<div className='additional-top-image' style={{backgroundImage: `url('${s3Url + additionalImage }')`}} />
    									{finish_it_items.map((item, i) => {
    										let finishImage = item.product[0].bowl_image
    										return (
    											<div key={i} className='finish-image' style={{backgroundImage: `url('${s3Url + finishImage}')`}}/>
    										)
    									})}
    								</div>
    							</div>
    							{this.drawHorizontalSubCategories(subCategory[key], index, '-byob', categoryName)}
    						</div> : null
    				} else {
    					return this.drawHorizontalSubCategories(subCategory[key], index, '', categoryName)
    				}
    			} else {
    				return null
    			}
    		})
    }

	onForwardHandler = () => {
		const { byob_type } = this.state
		let b_type = ''
		if (byob_type === 'is_base_it') {
			b_type = 'is_top_it'
		} else if (byob_type === 'is_top_it') {
			b_type = 'is_finish_it'
		}
		this.setState({ byob_type: b_type })
	}

	onBackHandler = () => {
		const { byob_type } = this.state
		let b_type = ''
		if (byob_type === 'is_finish_it') {
			b_type = 'is_top_it'
		} else if (byob_type === 'is_top_it') {
			b_type = 'is_base_it'
		}
		this.setState({ byob_type: b_type })
	}

	render() {
		const { __, data, history } = this.props
		const { selectedCategory, showProductModal, byob_type, is_byob, is_bowl, additional, is_sauce, showAlert, sauces, top_it_items } = this.state
		const { loading } = data
		const categoryName = selectedCategory.category && selectedCategory.category.name ? selectedCategory.category.name : ''
		return (
			<Layout
				headerTitle={additional ? __('Anything else?') : is_sauce ? __('Add a ' + categoryName + '?') : categoryName}
				color="white"
				hideBack={additional ? true : false}
				hideForward={ byob_type !== 'is_finish_it' && is_byob && top_it_items.length > 0 ? false : true }
				onForwardHandler={byob_type !== 'is_finish_it' && is_byob ? this.onForwardHandler : null}
				onBackHandler={byob_type !== 'is_base_it' ? this.onBackHandler : null}
				noPadding={isDesktop() ? true : false}
			>
				<SimpleLoader loading={ loading }>
					{this.displayItems()}
					<div className='view-basket-button'>
						<IonButton
							expand="full"
							disabled={byob_type !== 'is_finish_it' && is_byob ? true : false}
							color="tertiary"
							onClick={() => {
								is_byob ? this.notesModal() : additional ? forwardTo(history, 'menu') : is_sauce && sauces.length < 1 ? this.add('bowl') : sauces.length > 0 ? this.add('bowl') : forwardTo(history, 'cart')
							}}>
							{ __(is_byob ? __('Add') : additional ? __('No thanks') : is_sauce && sauces.length < 1 ? __('No thanks') : sauces.length > 0 ? __('Add') : __('View Basket'))}
						</IonButton>
					</div>
					<Modal
						showProductModal={showProductModal}
						component={this.state.selectedProduct}
						className={ is_bowl ? 'modal' : 'modal-auto-height' }
						closeModal={ this.closeModal } />
					<Alert showAlert={showAlert} closeAlert={ this.closeAlert } __ = { __ }/>
				</SimpleLoader>
			</Layout>
		)
	}
}

const stateToProps = (state) => {
	const { data, order } = state
	return {
		data,
		selectedCategory: order.selectedCategory,
		basket: order.basket,
		currency: order.currency
	}
}

export default connect(stateToProps)(withRouter(withTranslation(Categories)))
