import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { setupConfig, IonApp, IonSplitPane, IonPage } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Plugins } from '@capacitor/core'
//import appConfig from './appConfig'
import navConfig from './navConfig'

//import ProtectedRoute from './components/protectedRoute'
import { getDefaultRoute, mapRoutes, isDesktop } from './lib/utils'
import { withTranslation } from './lib/translate'
import asyncAuth from './lib/asyncAuth'
// import { restoreAuth } from '../auth/actions'
import { setAuthState, init, setMyLocation } from './store/actions'
import StaticHeader from './components/staticHeader'
import Drawer from './components/drawer'
import Tabs from './components/tabs'
import Toast from './components/toast'
// import SimpleLoader from './components/simpleLoader'


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'


// const { Geolocation } = Plugins
/* Theme */
require('./theme/index.css')
if (isDesktop()){
	require('./theme/desktop.css')
} else {
	require('./theme/mobile.css')
}

setupConfig({})

const { restoreAuth } = asyncAuth
const { SplashScreen } = Plugins


class App extends React.Component {

	// state = {
	// 	mylatitude: null,
	// 	mylongitude: null,
	// 	locationLoader: true
	// }

	async componentDidMount(){
		const { auth } = this.props
		if (auth && auth.loggedIn){
			SplashScreen.hide()
		} else {
			await restoreAuth(this.props.dispatch, setAuthState)
			SplashScreen.hide()
		}
		// this.getCurrentPosition()
		this.props.dispatch(init())
	}

	// async getCurrentPosition() {
	// 	const { myLocation } = this.props
	// 	if (!myLocation.latitude && !myLocation.longitude){
	// 		try {
	// 			const coordinates = await Geolocation.getCurrentPosition()
	// 			const myLocation = {
	// 				latitude: coordinates.coords.latitude,
	// 				longitude: coordinates.coords.latitude
	// 			}
	// 			this.getMyLocation(myLocation)
	// 			if (myLocation.latitude && myLocation.longitude ) {
	// 				this.setState({ locationLoader: false })
	// 			}
	// 		} catch (error) {
	// 			this.setState({ locationLoader: false })
	// 		}
	// 	} else {
	// 		this.setState({ locationLoader: false})
	// 	}
	// }

	getMyLocation = (myLocation) => {
		const { dispatch } = this.props
		dispatch(setMyLocation(myLocation))
	}

	render (){
		const { auth } = this.props
		// const { locationLoader } = this.state

		const defaultRoute = getDefaultRoute()
		const routeComponents = mapRoutes(navConfig.routes)
		const authRouteComponents = mapRoutes(navConfig.authRoutes)
		const additionalRouteComponents = mapRoutes(navConfig.additionalRoutes)
		const notInMenuRouteComponents = mapRoutes(navConfig.notInMenuRoutes)

		return (
			// <SimpleLoader loading={ locationLoader } transparent loadingMessage={__('Locating nearest stores')+'...'}>
			<IonApp>
				<IonReactRouter>
					<IonSplitPane contentId="main">
						<Drawer/>
						<IonPage id="main">
							<StaticHeader/>
							<Switch>
								{ routeComponents }
								{ authRouteComponents }
								{ additionalRouteComponents }
								{ notInMenuRouteComponents }
								{ defaultRoute ? defaultRoute.isStack ?
									<Route path="/" component={ defaultRoute.component } />
									:
									<Route path="/" render={() => <Redirect to={ defaultRoute.path } /> } />
									: null }
							</Switch>
							<Tabs auth={ auth } />
							<Toast />
						</IonPage>
					</IonSplitPane>
				</IonReactRouter>
			</IonApp>
			// </SimpleLoader>
		)
	}
}

const stateToProps = (state) => {
	const { auth, profile } = state.auth
	const { myLocation } = state.common
	return {
		auth,
		profile,
		myLocation: myLocation
	}
}

export default connect(stateToProps)(withTranslation(App))

