import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { IonButton, IonList, IonItem, IonLabel, IonInput, IonModal, IonToolbar, IonButtons, IonIcon, IonTitle } from '@ionic/react'
import Layout from '../../components/layout'
import SimpleLoader from '../../components/simpleLoader'
import { withTranslation } from '../../lib/translate'
import { dataRequest, dataClearError, showToast, addVoucher, setDiscounts, sendVoucherCode, sendMobile, mobileCheckSms } from '../../store/actions'
import { forwardTo, validateForm, isDesktop, protectedOrder } from '../../lib/utils'
import { SET_MODAL } from '../../store/constants'
import { close } from 'ionicons/icons'
import { FieldError } from '../../components/common'
import { DesktopView } from '../../components/desktopView'
// import appConfig from '../../appConfig'

require('./index.css')
if(isDesktop()) {
	require('./desktop.css')
} else {
	require('./mobile.css')
}

let appliedVouchers = null
let filteredVouchers = null

class ApplyVouchers extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			discounts: null,
			setVoucher: null,
			showModal: false,
			code: '',
			mobile: '',
			verification: '',
			formErrors: {
				code: ''
			},
			formErrors2: {
				mobile: ''
			},
			formErrors3: {
				verification: ''
			}
		}
		this.formConfig = {
			code: { type: 'text', required: true }
		}
		this.formConfig2 = {
			mobile: { type: 'tel', required: true }
		}
		this.formConfig3 = {
			verification: { type: 'integer', required: true }
		}
	}

	handleData = () => {
		const { data, apiMethod, dispatch } = this.props
		const { errors } = data
		const error = errors[apiMethod]

		if (error){
			dispatch(showToast(error.message, 'danger'))
			dispatch(dataClearError(apiMethod))
		} else {
			const { content } = data
			if (content && content[apiMethod]){
				const discounts = content[apiMethod]
				dispatch(setDiscounts(discounts))
				this.setState({ discounts })
			}
		}
	}

	handleProtectOrder = () => {
		const { __, dispatch, history, restaurantId, collectionTime } = this.props
		if (!protectedOrder(restaurantId, collectionTime)) {
			dispatch(showToast(__('Can\' proceed to apply vouchers without selected restaurant and suitable order') , 'warning'))
			forwardTo(history, '/locations')
		}
	}

	componentDidUpdate (prevProps) {
		const { data, restaurantId, collectionTime, profile, basket, dispatch, apiMethod } = this.props
		if (prevProps.data !== data){
			this.handleData()
		}

		if (prevProps.restaurantId === restaurantId && prevProps.collectionTime === collectionTime && !restaurantId && !collectionTime) {
			this.handleProtectOrder()
		}

		if (prevProps.profile.vouchers !== profile.vouchers) {
			dispatch(dataRequest(apiMethod, basket))

			appliedVouchers = basket ? basket.applied_vouchers : []
			filteredVouchers = this.filterVouchers(profile.vouchers, appliedVouchers)
			console.log('kakakaka', filteredVouchers)
		}
	}

	componentDidMount() {
		const { apiMethod, dispatch, basket } = this.props
		dispatch(dataRequest(apiMethod, basket))
	}

	setVoucher = (voucher) => {
		this.setState({ setVoucher: voucher })
	}

	addVoucher = () => {
		const { dispatch, history } = this.props
		const { setVoucher } = this.state

		dispatch(addVoucher(setVoucher))
		this.setState({ setVaucher: null })
		forwardTo(history, 'cart')
	}

	tryRequire = (path) => {
		let ret
		try {
			ret = 'url(' + require(path) + '), '
		} catch (err) {
			return ''
		}
		return ret
	}

    filterVouchers = (vouchers, appliedVouchers) => {
    	const { basket } = this.props
    	const vouchersDiscount = basket && basket.vouchers ? basket.vouchers : []
    	let filteredVouchers = []
    	if (vouchersDiscount) {
    		vouchersDiscount.forEach((discount) => {
    			if (vouchersDiscount.length > 0) {
    				let findedVoucher = vouchers.find(voucher => voucher.id === discount.id)
    				if (findedVoucher) {
    					filteredVouchers.push(findedVoucher)
    				}
    			}
    		})
    	}
    	if (appliedVouchers && appliedVouchers.length > 0) {
    		const ids = appliedVouchers.map(i => i.id)
    		return filteredVouchers.filter(filteredVoucher => ids.indexOf(filteredVoucher.id) === -1)
    	}
    	return filteredVouchers
    }

	sendCode = () => {
		let formErrors = validateForm(this.formConfig, this.state, this.props.__)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			const { code } = this.state
			const data = { code }
			this.clearFields()
			this.props.dispatch(sendVoucherCode(data))
			this.showModal(false)
		}
	}

	handleInput = (name, value) => {
		this.setState({ [name]: value })
	}


	clearFields = () => {
		this.setState({ code: '', mobile: '', verification: '' })
	}
	
	showModal = (show) => {
		this.setState({ showModal: show })
		this.clearFields()
	}

	showModal2 = (show) => {
		this.props.dispatch({ type: SET_MODAL, modal: 'mobileValidate', value: show })
		this.clearFields()
	}

	showModal3 = (show) => {
		this.props.dispatch({ type: SET_MODAL, modal: 'mobileCheckSms', value: show })
		this.clearFields()
	}

	handleInput = (name, value) => {
		this.setState({ [name]: value })
	}

	clearFields = () => {
		this.setState({ code: '', mobile: '', verification: '' })
	}

	sendMobile = () => {
		let formErrors2 = validateForm(this.formConfig2, this.state, this.props.__)
		this.setState({ formErrors2 })
		if (Object.keys(formErrors2).length === 0) {
			const { mobile } = this.state
			const data = { mobile }
			this.clearFields()
			this.showModal2(false)
			this.showModal3(true)
			this.props.dispatch(sendMobile(data))
		}
	}

	sendCheckSms = () => {
		let formErrors3 = validateForm(this.formConfig3, this.state, this.props.__)
		this.setState({ formErrors3 })
		if (Object.keys(formErrors3).length === 0) {
			const data = { validation_code: this.state.verification }
			this.clearFields()
			this.props.dispatch(mobileCheckSms(data))
			this.showModal3(false)
		}
	}

	render() {
		const { __, data, profile, basket, history, mobileValidate, mobileCheckSms } = this.props
		const { setVoucher, code, mobile, verification } = this.state
		const { loading } = data
		const vouchers = profile.vouchers
		appliedVouchers = basket ? basket.applied_vouchers : []
		filteredVouchers = this.filterVouchers(vouchers, appliedVouchers)
		return (
			<>
				<Layout headerTitle={__('Apply Vouchers')} color={ isDesktop() ? 'transparent' : 'white' } contentClassName={ isDesktop() ? 'animated-gif-01' : '' } noPadding={ true }>
					<SimpleLoader loading={ loading }>
						<div className="vouchers-container">
							<DesktopView>
								<div className='desktop-vouchers-title'>{ __('Vouchers') }</div>
							</DesktopView>
							<div className="ion-text-center enter-code-wrapper">
								{/* <p>{ __('Enter a valid voucher code below to receive your reward')}</p>
								<IonList>
									<IonItem>
										<IonLabel position="floating">{ __('Voucher code')}</IonLabel>
										<IonInput onIonChange={ e => this.handleInput('code', e.target.value) } clearInput required={ true } type="text" pattern="text" inputmode="text" value={ code }></IonInput>
										<FieldError className="field-error" value={ __(this.state.formErrors.code)} />
									</IonItem>
								</IonList>
								<IonButton onClick={() => this.sendCode()} expand="block">{ __('Submit Code')}</IonButton> */}
								<IonButton expand="full" color="primary" className="default-button login-button" onClick={() => this.showModal(true)}>{ __('Voucher code') }</IonButton>
							</div>
							<div className="vouchers-head-title">{ __('Available Vouchers') }</div>
							<div className={isDesktop() ? 'vouchers-list-wrapper' : ''}>
								{
									(filteredVouchers || []).map((voucher, index) =>

										<div key={index} onClick={() => this.setVoucher(voucher)} className={setVoucher && setVoucher.id === voucher.id ? 'voucher' : ''}>
											<div className="voucher-reward-image" style={{ backgroundImage: voucher.reward.image ? this.tryRequire('../../assets/images/' + voucher.reward.image) + 'url(\'https://island-poke.s3.amazonaws.com/' + voucher.reward.image + '\')' : require('../../assets/images/missing-image.png') }}></div>
											<div className="voucher-content">
												<p>{ __(voucher.reward.name) }</p>
											</div>
										</div>
									)
								}
							</div>
							<div className='vouchers-buttons'>
								<IonButton 
									color="tertiary" 
									disabled={setVoucher ? false : true } 
									onClick={() => {
										this.addVoucher()
									}} 
									expand="full">{ __('Apply Voucher')}
								</IonButton>
								<IonButton expand='full' onClick={() => {forwardTo(history, 'cart')}} color='tertiary'>{ __('Back')}</IonButton>
							</div>
						</div>
					</SimpleLoader>
				</Layout>
				<IonModal className='modal-auto-height' cssClass='loyalty-modal' isOpen={this.state.showModal} backdrop-dismiss="true" onDidDismiss={() => this.showModal(false)}>
					<div>
						<IonToolbar color="white">
							<IonButtons slot="start">
								<IonButton button clear style={{ visibility: 'hidden' }}>
									<IonIcon slot="icon-only" icon={ close } />
								</IonButton>
							</IonButtons>

							<IonTitle className="ion-text-center modal-title add-voucher-title">{ __('Enter code')}</IonTitle>

							<IonButtons slot="end">
								<IonButton button clear onClick={() => this.showModal(false)}>
									<IonIcon slot="icon-only" icon={ close } />
								</IonButton>
							</IonButtons>
						</IonToolbar>
						<div className="ion-text-center box-padded voucher-input-wrapper">
							<p>{ __('Enter a valid voucher code below to receive your reward')}</p>
							<IonList>
								<IonItem>
									<IonLabel position="floating">{ __('Voucher code')}</IonLabel>
									<IonInput onIonChange={ e => this.handleInput('code', e.target.value) } clearInput required={ true } type="text" pattern="text" inputmode="text" value={ code }></IonInput>
									<FieldError className="field-error" value={ __(this.state.formErrors.code)} />
								</IonItem>
							</IonList>
						</div>
						<IonButton onClick={() => this.sendCode()} className='submit-voucher-button' expand="full">{ __('Submit Code')}</IonButton>
					</div>
				</IonModal>

				<IonModal className='modal-auto-height' cssClass='validate-modal' isOpen={mobileValidate} backdrop-dismiss="true" onDidDismiss={() => this.showModal2(false)}>
					<div>
						<IonToolbar color="white">
							<IonButtons slot="start">
								<IonButton button clear style={{ visibility: 'hidden' }}>
									<IonIcon slot="icon-only" icon={ close } />
								</IonButton>
							</IonButtons>

							<IonTitle className="ion-text-center modal-title add-voucher-title">{ __('Validate account')}</IonTitle>

							<IonButtons slot="end">
								<IonButton button clear onClick={() => this.showModal2(false)}>
									<IonIcon slot="icon-only" icon={ close } />
								</IonButton>
							</IonButtons>
						</IonToolbar>
						<div className="ion-text-center box-padded voucher-input-wrapper">
							<p>{ __('For account validation purposes, enter your mobile number to receive a verification code')}</p>
							<IonList>
								<IonItem>
									<IonLabel position="floating">{ __('Enter Mobile Number')}</IonLabel>
									<IonInput onIonChange={ e => this.handleInput('mobile', e.target.value) } clearInput required={ true } type="tel" pattern="tel" inputmode="tel" value={ mobile }></IonInput>
									<FieldError className="field-error" value={ __(this.state.formErrors2.mobile)} />
								</IonItem>
							</IonList>
						</div>
						<IonButton className='submit-voucher-button' onClick={() => this.sendMobile()} expand="full">{ __('Submit')}</IonButton>
					</div>
				</IonModal>

				<IonModal className='modal-auto-height' cssClass='verification-modal' isOpen={mobileCheckSms} backdrop-dismiss="true" onDidDismiss={() => this.showModal3(false)}>
					<div>
						<IonToolbar color="white">
							<IonButtons slot="start">
								<IonButton button clear style={{ visibility: 'hidden' }}>
									<IonIcon slot="icon-only" icon={ close } />
								</IonButton>
							</IonButtons>

							<IonTitle className="ion-text-center modal-title add-voucher-title">{ __('Enter verification code')}</IonTitle>

							<IonButtons className="close" slot="end">
								<IonButton button clear onClick={() => this.showModal3(false)}>
									<IonIcon slot="icon-only" icon={ close } />
								</IonButton>
							</IonButtons>
						</IonToolbar>
						<div className="ion-text-center box-padded voucher-input-wrapper">
							<p>{ __('Enter the verification code you received')}</p>
							<IonList>
								<IonItem>
									<IonLabel position="floating">{ __('Enter verification code')}</IonLabel>
									<IonInput onIonChange={ e => this.handleInput('verification', e.target.value) } clearInput required={ true } type="number" pattern="number" inputmode="number" value={ verification }></IonInput>
									<FieldError className="field-error" value={ __(this.state.formErrors3.verification)} />
								</IonItem>
							</IonList>
						</div>
						<IonButton className='submit-voucher-button' onClick={() => this.sendCheckSms()} expand="full">{ __('Submit')}</IonButton>
					</div>
				</IonModal>
			</>
		)
	}
}

const stateToProps = (state) => {
	const { data, order, auth } = state
	const { mobileValidate, mobileCheckSms } = state.common
	return {
		data,
		apiMethod: 'checkDiscount',
		basket: order.basket,
		profile: auth.profile,
		mobileValidate,
		mobileCheckSms,
		restaurantId: order.restaurantId,
		collectionTime: order.collectionTime
	}
}

export default connect(stateToProps)(withRouter(withTranslation(ApplyVouchers)))
