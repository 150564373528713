import React from 'react'
import MapContainer from '../../components/map'
import Layout from '../../components/layout'
import { withTranslation } from '../../lib/translate'
import { isDesktop } from '../../lib/utils'
import './index.css'

const Locations = ({ __ }) =>
	<Layout headerTitle={ __('Locations')} color={ isDesktop() ? 'transparent' : '' } contentClassName={ isDesktop() ? "map-page animated-gif-01" : "map-page" } noPadding={ true }>
		<MapContainer />
	</Layout>

export default withTranslation(Locations)
