import React from 'react'
import { connect } from 'react-redux'
import { IonItem, IonButton, IonInput, IonAlert, IonLabel, IonList } from '@ionic/react'
import Layout from '../../components/layout'
import { resetPassword, setModal } from '../../store/actions'
import { FieldError } from '../../components/common'
import { withTranslation } from '../../lib/translate'
import { validateForm, isDesktop } from '../../lib/utils'
import Loading from '../../components/spinner'
import { DesktopView } from '../../components/desktopView'

if(isDesktop()) require('./desktop.css')

class ResetPassword extends React.Component {
	constructor (props) {
		super(props)
		this.state = {
			username: '',
			formErrors: {}
		}
		this.handleInput = this.handleInput.bind(this)
		this.handleResetPassword = this.handleResetPassword.bind(this)
		this.formConfig = {
			username: { type: 'email', required: true }
		}
	}

	handleInput (key, val) {
		this.setState({[key]: val })
	}

	handleResetPassword() {
		let formErrors = validateForm(this.formConfig, this.state, this.props.__)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			const { username } = this.state
			this.props.dispatch(resetPassword(username))
		}
	}

	render() {
		const { __, isResetPasswordModalOpen } = this.props
		const { username, formErrors } = this.state
		return (
			<>
				<Loading transparent>
					<Layout headerTitle={ __('Reset Password') } color={isDesktop() ? "transparent" : "white"} contentClassName={ isDesktop() ? "animated-gif-01" : "" } noPadding={isDesktop() ? true : null}>
						<div className={isDesktop() ? "desktop-reset-wrapper" : ''}>
							<DesktopView>
								<div className="reset-password-header">{ __('Reset Password') }</div>
							</DesktopView>
							<div className={isDesktop() ? 'desktop-reset-inner' : ''}>
								<h3>{ __('Enter your email to receive instructions to reset your password') }</h3>
								<div className="box-holder">
									<IonList>
										<IonItem className="single-item">
											<IonLabel position="floating">{ __('Email') }</IonLabel>
											<IonInput onIonChange={(e) => this.handleInput('username', e.target.value)} clearInput required={ true } type="email" pattern="email" inputmode="email" value={ username }/>
											<FieldError className="field-error" value={ __(formErrors.username)} />
										</IonItem>
									</IonList>
								</div>
								<div className="top-medium">
									<IonButton expand="full" color="tertiary" onClick={ () => this.handleResetPassword()}>
										{ __('Submit') }
									</IonButton>
								</div>
							</div>
						</div>
					</Layout>
					<IonAlert
						isOpen={ isResetPasswordModalOpen }
						onDidDismiss={() => this.props.dispatch(setModal('isResetPasswordModalOpen', false))}
						header={ __('Success') }
						message={ __('Reset processed. Please check your mail') }
						buttons={[
							{
								text: __('Close'),
								role: 'cancel',
								cssClass: 'secondary',
								handler: () => this.props.dispatch(setModal(('isResetPasswordModalOpen', false)))
							}
						]}
					/>
				</Loading>
			</>
		)
	}
}

const stateToProps = state => {
	const { isResetPasswordModalOpen } = state.common
	return {
		isResetPasswordModalOpen
	}
}

export default connect(stateToProps)(withTranslation(ResetPassword))
