import { take, call, put, select } from 'redux-saga/effects'
import { GET_MY_ORDERS, SET_MY_ORDERS, CREATE_ORDER, CLEAR_BASKET, LIST_PAYMENT_CARDS, SET_ORDERS_PROP, ADD_SCANNED_CARD, GET_PROFILE, REMOVE_PAYMENT_CARD, GET_VOUCHERS } from '../constants'
import { SHOW_TOAST } from '../constants'
import api from '../../lib/api'
import { isDefined, price, total } from '../../lib/utils'
import { loading } from '../common/sagas'
import { showToast } from '../actions'
import Stripe from '../../lib/stripe'
import { translateSaga } from '../common/sagas'
import appConfig from '../../appConfig'

export const getMyOrders = function* () {
	while (true) {
		yield take(GET_MY_ORDERS)
		yield call(loading, function *() {
			const response = yield call(api.getMyOrders)
			yield put({ type: SET_MY_ORDERS, value: response.data })
		})
	}
}

const checkIntentResult = function* (intentResult) {
	// Stripe.checkIntentResult i maybe already called
	const status = isDefined(intentResult.isValid) ? intentResult : yield call(Stripe.checkIntentResult, intentResult)
	if (status.isValid) {
		yield put(showToast(status.message, 'success'))
	} else {
		yield put(showToast(status.message, 'danger'))
	}
}

export const createOrderFlow = function* () {
	while (true) {
		const { additionalOrderData, options } = yield take(CREATE_ORDER)
		const { paymentType, complete, history } = options

		yield call(loading, function *() {
			try {
				const orderStore = yield select( store => store.order)
				const contentStore = yield select( store => store.data.content)
				if (!orderStore || !orderStore.basket) {
					yield put({ type: SHOW_TOAST, message: yield call(translateSaga, 'Your basket is empty'), toastType: 'warning' })
					return false
				}
				if (!orderStore || !orderStore.restaurant) {
					yield put({ type: SHOW_TOAST, message: yield call(translateSaga, 'Please select restaurant'), toastType: 'warning' })
					return false
				}
				if (!orderStore || !orderStore.time) {
					yield put({ type: SHOW_TOAST, message: yield call(translateSaga, 'Please select collection time'), toastType: 'warning' })
					return false
				}
				if (!orderStore || !orderStore.currency) {
					yield put({ type: SHOW_TOAST, message: yield call(translateSaga, 'Please select restaurant'), toastType: 'warning' })
					return false
				}

				let applied_vouchers_arr = [] // to store applied vouchers
				const applied_vouchers = orderStore.basket.applied_vouchers // applied in basket

				if (contentStore.checkDiscount) { // vouchers returned from check discount
					applied_vouchers.forEach(voucher => {
						const item = contentStore.checkDiscount.find(discount => voucher.id === discount.id)
						if (item) {applied_vouchers_arr.push(item)}
					})
				}
				let orderData = {
					items: JSON.stringify(orderStore.basket.items),
					applied_vouchers: JSON.stringify(applied_vouchers_arr),
					collection_time: orderStore.time * 1000,
					business_location_id: orderStore.restaurant.business_location_id,
					currency: orderStore.currency,
					eat_in: false,
					take_away: true,
					tab_order: false,
					// payment_token should arive with additionalOrderData argument,
					...additionalOrderData
				}

				const result = yield call(api.createOrder, orderData)

				if (result.error) {
					yield put({ type: SHOW_TOAST, message: yield call(translateSaga, result.error.message), toastType: 'warning' })
					if (isDefined(complete)) { complete('fail') }
				} else {
					const clientSecret = result.data.client_secret
					const currency = result.data.order.currency || appConfig.general.defaultCurrency
					const country = orderStore.restaurant.country_code || appConfig.general.default_country_code
					const items = (orderStore.basket.items || []).map(item => ({
						label: item.name,
						amount: item.qty * item.price
					})).filter(item => item.amount > 0).map(i => ({ ...i, amount: parseFloat(price(i.amount))}))
					orderData._total = parseFloat(total(orderStore.basket.items, orderStore.basket.applied_vouchers, orderStore.basket))

					if (!clientSecret || clientSecret === '') {
						yield put(showToast('Payment error.', 'danger'))
					} else {
						if (clientSecret === '-1') { //total for order  is: 0
							yield call(checkIntentResult, {
								status: 'succeeded'
							})
						} else {
							try {
								if (paymentType === 'google') {
									// pay with google pay
									yield call(Stripe.payWithGooglePay, clientSecret, orderData._total, currency)
									const intentResult = yield call(Stripe.confirmPaymentIntent, clientSecret, { fromGooglePay: true })
									yield call(checkIntentResult, intentResult)
								} else if (paymentType === 'apple') {
									// pay with apple pay
									const intentResult = yield call(Stripe.payWithApplePay, clientSecret, currency, country, items)
									yield call(checkIntentResult, intentResult)
								} else {
									// pay with regular payment card
									const intentResult = yield call(Stripe.confirmPaymentIntent, clientSecret, { paymentMethodId: orderData.payment_token })
									yield call(checkIntentResult, intentResult)
								}
							} catch (e) {
								yield put(showToast('Payment error.\n' + (e.message || ''), 'danger'))
							}
						}
						yield put({ type: CLEAR_BASKET })
						if (isDefined(complete)) { complete('success') }
						yield put({ type: GET_VOUCHERS })
						if (isDefined(history)) {
							history.push({ pathname: 'order-complete' })
						}
					}
				}
			} catch (e) {
				yield put({ type: SHOW_TOAST, message: yield call(translateSaga, 'Problem processing payment'), toastType: 'warning' })
				if (isDefined(complete)) { complete('fail') }
				throw e
			}
		})
	}
}

export const getPaymentCardsFlow = function* () {
	while (true) {
		yield take(LIST_PAYMENT_CARDS)
		yield call(loading, function *() {
			const cards = yield call(api.getPaymentCards)
			yield put({ type: SET_ORDERS_PROP, key: 'cards', value: cards })
		})
	}
}

export const addScannedCard = function* () {
	while (true) {
		const action = yield take(ADD_SCANNED_CARD)
		yield call(loading, function *() {
			const { options, payment_token, name } = action
			const savedCards = yield call(api.addPaymentCard, { payment_token, name })
			yield put({ type: SET_ORDERS_PROP, key: 'cards', value: savedCards })
			yield put(showToast('Card added successfully', 'success'))

			//everytime when user add card that card will be default card. Because of that we call get profile
			yield put({ type: GET_PROFILE })
			if (options.cb) {
				yield call(options.cb)
			}
		})
	}
}

export const removePaymentCardsFlow = function* () {
	while (true) {
		const action = yield take(REMOVE_PAYMENT_CARD)
		yield call(loading, function *() {
			const { cardToken, options } = action

			const data = {
				payment_token: cardToken
			}
			const result = yield call(api.removePaymentCard, data)
			if (result.error) {
				yield put(showToast(yield call(translateSaga, result.error.message), 'warning'))
			} else {
				yield put({ type: SET_ORDERS_PROP, key: 'cards', value: result.data.cards.data })
				yield put(showToast(yield call(translateSaga, 'Card removed successfully'), 'success'))
			}
			if (options.cb) {
				yield call(options.cb)
			}
		})
	}
}
