import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IonToast } from '@ionic/react'
import { isDefined } from '../../lib/utils'
import { resetToast } from '../../store/actions'
import { withTranslation } from '../../lib/translate'

class Toast extends Component {
	render() {
		const { message, toastType, resetToast, __ } = this.props
		const displayToast = isDefined(message) && message !== ''
		return (
			<IonToast
				isOpen={ displayToast }
				onDidDismiss={ resetToast }
				color={ toastType }
				message={ message }
				duration={ 5000 }
				showCloseButton={ true }
				closeButtonText={ __('Close')}
			/>
		)
	}
}

const stateToProps = state => {
	const { common } = state
	const { message, toastType } = common.toast

	return {
		message: isDefined(message) && message !== '' ? message : '',
		toastType: isDefined(toastType) && toastType !== '' ? toastType : 'warning'
	}
}

const dispatchToProps = dispatch => {
	return {
		resetToast: () => dispatch(resetToast())
	}
}

export default connect(stateToProps, dispatchToProps)(withTranslation(Toast))
