import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withTranslation } from '../../lib/translate'
import { IonModal } from '@ionic/react'

class Modal extends React.Component {
		state = {
			showModal: false
		}

		render() {
			const { showProductModal, closeModal } = this.props
			return (
				<IonModal className={this.props.className} showBackdrop={true} isOpen={showProductModal} onDidDismiss={() => { closeModal() }}>
					{this.props.component}
				</IonModal>
			)
		}
}

const stateToProps = (state) => {
	const { data } = state
	return {
		data
	}
}

export default connect(stateToProps)(withRouter(withTranslation(Modal)))
