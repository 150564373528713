import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { IonList, IonItem, IonLabel, IonButton, IonIcon } from '@ionic/react'
import { addToBasket, addVoucher } from '../../store/actions'
import Alert from '../../components/alert'
import Modal from '../../components/modal'
import * as icons from 'ionicons/icons'
import Layout from '../../components/layout'
import SimpleLoader from '../../components/spinner'
import { withTranslation } from '../../lib/translate'
import { forwardTo, price, currencySign, total, isDesktop } from '../../lib/utils'
import { DesktopView } from '../../components/desktopView'
import moment from '../../lib/moment'
import { createOrder } from '../../store/actions'

require('./index.css')
if (isDesktop()) {
	require('./desktop.css')
} else {
	require('./mobile.css')
}

class Cart extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			modalContent: null,
			showModal: false,
			basket: null,
			showAlert: false
		}
		this.closeModal = this.closeModal.bind(this)
	}

	componentDidMount() {
		const { basket } = this.props
		this.setState({basket})
	}

	componentDidUpdate(prevProps) {
		const { basket } = this.props
		if (prevProps.basket !== this.props.basket) {
			this.setState({ basket })
		}
	}

	changeQuantity = (val, product, index) => {
		let quantity = product.qty
		let qty = quantity + val

		if (qty < 1) {
			qty = 0
		}
		let newProduct = {...product}
		newProduct.qty = qty
		this.modalContent(newProduct, index)
	}

	closeModal = () => {
		this.setState({ showModal: false })
	}

	modalContent = (product, index) => {
		const { __ } = this.props
		let quantity = product.qty
		let content =
			<div className='category-content'>
				<div className='item-third-header'>
					<IonLabel size={20}>{ product.name.toUpperCase()}</IonLabel>
					<IonIcon icon={icons.close} onClick={()=> { this.closeModal() }}/>
				</div>
				<div className='item-actions'>
					<div className='item-actions-header'>{__('Update Quantity')}</div>
					<div className='item-action'>
						<div className='quantity-button' onClick={() => { this.changeQuantity(-1, product, index) }}>
							<IonButton disabled={quantity > 0 ? false : true } expand="full"> <IonIcon slot="icon-only" icon={icons.remove} /></IonButton>
						</div>
						<div className='item-quantity'>{quantity}</div>
						<div className='quantity-button' onClick={() => { this.changeQuantity(1, product, index) }}>
							<IonButton expand="full"> <IonIcon slot="icon-only" icon={icons.add} /> </IonButton>
						</div>
					</div>
				</div>
				<IonButton expand='full' disabled={false} className='select-base-button' onClick={() => this.updateBasket(product, index) }>{__('UPDATE')}</IonButton>
			</div>

		this.setState({ modalContent: content, showModal: true })
	}

	updateBasket = (product, index) => {
		const { dispatch } = this.props
		const newBasket = {...this.state.basket}
		let quantity = product.qty
		let items = newBasket.items
		if (quantity === 0) {
			items.splice(index, 1)
		} else {
			items[index] = product
		}
		newBasket.items = items
		dispatch(addToBasket(newBasket))
		this.setState({ showModal: false })
	}

	closeAlert = () => {
		this.setState({ showAlert: false })
	}

	removeAppliedVoucher = () => {
		const { dispatch } = this.props
		dispatch(addVoucher(null))
	}

	handleCheckout = () => {
		const { history, dispatch } = this.props
		const { basket } = this.state
		const basketItems = basket ? basket.items : []
		const appliedVouchers = basket ? basket.applied_vouchers : null
		const basketTotal = total(basketItems, appliedVouchers, basket)

		if (basketTotal === '0.00') {
			const additionalOrderData = {
				payment_token: 'skip_payment'
			}
			dispatch(createOrder(additionalOrderData, { history }))
		} else {
			forwardTo(history, 'checkout')
		}
	}

	render() {
		const {__, history, currency } = this.props
		const { showModal, basket, showAlert } = this.state
		const basketItems = basket ? basket.items : []
		// const restaurantName = basket && basket.restaurant ? basket.restaurant.name : ''
		const restaurantAddress = basket && basket.restaurant ? basket.restaurant.address : ''
		// const collection_time = basketItems.length > 0 ? moment.unix(basket.collection_time).format('LT') : null
		const appliedVouchers = basket ? basket.applied_vouchers : null
		const basketVouchers = basket ? basket.vouchers : null

		if (basketItems.length === 0) {
			return (
				<Layout headerTitle={ __('Your Basket') } color={ isDesktop() ? 'transparent' : 'white' } contentClassName={ isDesktop() ? 'animated-gif-01' : '' } noPadding={true}>
					<div className='desktop-basket-wrapper'>
						<DesktopView>
							<div className='desktop-basket-header'>{ __('Your Basket')}</div>
						</DesktopView>
						<div className='basket-header-empty'>
							<IonLabel>{ __('Your basket is empty') }</IonLabel>
						</div>
						<div className='basket-buttons'>
							<IonButton expand='full' color='tertiary' onClick={() => { isDesktop() ? forwardTo(history, 'dashboard') : forwardTo(history, 'orders')}}>{ __('Start Order') }</IonButton>
						</div>
					</div>
				</Layout>
			)
		}
		const formatPrice = price => currency === 'gbp' ? currencySign(currency) + price : price + ' ' + currencySign(currency)
		return (
			<Layout headerTitle={__('Your Basket') } color={ isDesktop() ? 'transparent' : 'white' } contentClassName={ isDesktop() ? 'animated-gif-01' : '' } noPadding={true}>
				<SimpleLoader transparent transparentForce loaderStyle={{ color: 'var(--ion-color-primary)' }}>
					<div className='desktop-basket-wrapper'>
						<DesktopView>
							<div className='desktop-basket-header'>{ __('Your Basket')}</div>
						</DesktopView>
						<div className='desktop-basket-inner'>
							<div className='basket-header'>
								<IonLabel>{__('Pick up your order at IP')} {restaurantAddress}</IonLabel>
							</div>
							<div className='basket-content'>
								<div className='basket-title'>
									<IonLabel>{ __('Your order')}</IonLabel>
								</div>
								<div className='basket-list-items'>
									<IonList lines='none'>
										{(basketItems || []).map((item, index) => {
											const itemPrice = price(item.price * item.qty)
											return (
												<div key={index} className='basket-item'>
													<IonItem>
														<div className='basket-item-content'>
															<IonItem>
																<IonIcon slot='start' icon={ icons.closeCircle} size='small' color='tertiary' onClick={ () => { this.modalContent(item, index) } }/>
																<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
																	<IonLabel>{item.qty}x {item.name}</IonLabel>
																</div>
																<p>{itemPrice ? formatPrice(itemPrice) : null}</p>
															</IonItem>
															<IonList className='basket-sub-items' lines='none'>
																{ (item.sub_items || []).map((sub_item, idx) => {
																	const subItemPrice = price(sub_item.item_qty ? sub_item.item_price * item.qty * sub_item.item_qty : sub_item.item_price * item.qty)
																	return (
																		<IonItem key={idx}>
																			<div className='basket-subItem-content'>
																				<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
																					<IonLabel slot='start'>+{sub_item.item_qty ? sub_item.item_qty * item.qty + 'x' : item.qty > 1 ? item.qty + 'x': null} {sub_item.item_name}</IonLabel>
																				</div>
																				<p>{ subItemPrice ? '+ ' + formatPrice(subItemPrice) : null }</p>
																			</div>
																		</IonItem>
																	)
																})}
															</IonList>
														</div>
													</IonItem>
													{ item.notes && item.notes !== '' ?
														<div className="basket-special-notes">
															<IonLabel>{ __('Special Notes and Allergies') }:</IonLabel>
															<p>{item.notes}</p>
														</div>
													 : null }
												</div>
											)
										})}
									</IonList>
									{appliedVouchers && appliedVouchers.length > 0 ?
										<div>
											<div className='basket-title'>
												<IonLabel>{ __('Vouchers')}</IonLabel>
											</div>
											<IonList lines='none'>
												{(appliedVouchers || []).map((voucher, index) => {
													let findedVoucher = basketVouchers.find(basketVoucher => basketVoucher.id === voucher.id)
													if (!findedVoucher) {return null}
													let voucherPrice = price(findedVoucher.cost)
													return (
														<div key={index} className='basket-item'>
															<IonItem>
																<div className='basket-item-content'>
																	<IonItem>
																		<IonIcon slot='start' icon={ icons.closeCircle} size='small' color='tertiary' onClick={ () => { this.setState({ showAlert: true }) }}/>
																		<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
																			<IonLabel>{voucher.reward.name}</IonLabel>
																		</div>
																		<p>{voucherPrice ? '- ' + formatPrice(voucherPrice) : null}</p>
																	</IonItem>
																</div>
															</IonItem>
														</div>
													)
												})}
											</IonList>
										</div>
										: null}
									{basketItems.length > 0 ?
										<div className='basket-total'>
											<div className=''>{ __('Total') }</div>
											<div className='basket-total-price'>{formatPrice(total(basketItems, appliedVouchers, basket))}</div>
										</div>
										: null}
								</div>
							</div>
							<div className='basket-buttons not-empty-basket'>
								<IonButton expand='full' color='tertiary' onClick={() => { forwardTo(history, 'applyVouchers')}}> {appliedVouchers && appliedVouchers.length > 0 ? __('Replace Vouchers') : __('Apply Vouchers') }</IonButton>
								<IonButton expand='full' color='tertiary' onClick={() => { forwardTo(history, 'menu')}}>{ __('Add more items?')}</IonButton>
								<IonButton expand='full' color='tertiary' onClick={() => { !this.props.loggedIn ? history.push({ pathname: 'login', state: { caller: 'cart' } }) : this.handleCheckout() }}>{ __('Checkout')}</IonButton>
							</div>
						</div>
					</div>
					<Modal
						showProductModal={showModal}
						component={this.state.modalContent}
						className={'modal-auto-height'}
						closeModal={ this.closeModal }
					/>
					<Alert showAlert={showAlert} closeAlert={ this.closeAlert } type='voucher' removeAppliedVoucher={this.removeAppliedVoucher}/>
				</SimpleLoader>
			</Layout>
		)
	}
}

const stateToProps = store => {
	const { data, order, auth } = store

	return {
		order,
		data,
		apiMethod: 'restaurants',
		basket: order.basket,
		currency: order.currency,
		loggedIn: auth.auth.loggedIn
	}
}

export default connect(stateToProps)(withRouter(withTranslation(Cart)))
