import React from 'react'
import { connect } from 'react-redux'
import { IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonItem,	IonList, IonText, IonContent } from '@ionic/react'
import { arrowBack, arrowForward } from 'ionicons/icons'

import { Map, GoogleApiWrapper, Marker, InfoWindow } from '../googleMapsReact'
import SimpleLoader from '../simpleLoader'
import { Nl2Br, FallbackText } from '../common'
import { dataRequest, dataClearError, showToast } from '../../store/actions'
import appConfig from '../../appConfig'
import { withTranslation } from '../../lib/translate'
import { MobileView } from '../mobileView'
import { DesktopView } from '../desktopView'
import WebMap from '../webMap'
import { isDesktop, getDistance } from '../../lib/utils'
import { Plugins } from '@capacitor/core'


require('./index.css')
if (isDesktop()) {
	require('./desktop.css')
} else {
	require('./mobile.css')
}


const { Geolocation } = Plugins

const markerIcon = {
	url: isDesktop() ? require('../../assets/images/pin.svg') : require('../../assets/images/map-pin.svg'),
	scaledSize: isDesktop() ? { width: 50, height: 50 } : { width: 40, height: 20 },
	anchor: { x: 25, y: 50 }
}

const markerIconSelected = {
	url: isDesktop() ? require('../../assets/images/pin-active.svg') : require('../../assets/images/map-pin-selected.svg'), 
	scaledSize: isDesktop() ? { width: 50, height: 50 } : { width: 60, height: 24 },
	anchor: { x: 25, y: 50 }
}

export class MapContainer extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			stores: [],
			map: null,
			locationId: 0,
			locationHolderNested: false,
			activeMarker: {},
			selectedPlace: {},
			showInfoWindow: false
		}
	}

	componentDidUpdate (prevProps) {
		const { data, apiMethod, dispatch } = this.props
		if (prevProps.data !== data){
			const { errors } = data
			const error = errors[apiMethod]

			if (error){
				dispatch(showToast(error.message, 'danger'))
				dispatch(dataClearError(apiMethod))
			} else {
				const { content } = data
				if (content && content[apiMethod]){
					const rawStores = content[apiMethod]
					rawStores.forEach(rs => {
						const [lat, lng] = rs.position.split(',')
						rs.latitude = parseFloat(lat)
						rs.longitude = parseFloat(lng)
					})
					// rawStores.sort(this.compareDistance)
					this.setState({ stores: rawStores.sort(this.compareDistance)}, ()=> {
						if (this.state.map){
							this.onMapReady(null, this.state.map)
						}
					})
				}
			}
		}
	}

	compareDistance = (a, b) => {
		const { myLocation } = this.state
			const aDist = getDistance(a.position, myLocation.latitude, myLocation.longitude )
			const bDist = getDistance(b.position, myLocation.latitude, myLocation.longitude )

			if (aDist < bDist)
			{return -1}
			if (aDist > bDist)
			{return 1}
			return 0
		
	}

	async componentDidMount() {
		const { apiMethod, dispatch } = this.props
		const coordinates = await Geolocation.getCurrentPosition()
		this.setState({
			myLocation: {
				latitude: this.props.myLocation.latitude || coordinates.coords.latitude,
				longitude: this.props.myLocation.longitude || coordinates.coords.longitude,
			}
		})
		dispatch(dataRequest(apiMethod, {}))
	}

	onMapReady = (mapProps, map) => {
		const { stores } = this.state
		const sortStores = stores.sort(this.compareDistance)
		if (sortStores[0]){
			map.panTo({ lat: sortStores[0].latitude, lng: sortStores[0].longitude })
		}
		this.setState({ map })
	}

	displayMarkers = () => {
		return this.state.stores.map((store, index) => {
			return (
				<Marker key={ index } id={ index } position={{
					lat: store.latitude,
					lng: store.longitude
				}}
				icon={ index === this.state.locationId ? markerIconSelected : markerIcon }
				onClick={ this.onMarkerClick }
				name={ store.name }
				address={ store.address }
				openingTimes={ store.opening_times }
				/>
			)
		})
	}

	onMarkerClick = (props, marker) => {
		const { map, stores } = this.state
		const store = stores[props.id]
		const { latitude, longitude } = store

		map.panTo({ lat: latitude, lng: longitude })

		let selectedPlace={
			name: props.name,
			address: props.address,
			times: props.openingTimes
		}

		this.setState({
			locationId: props.id,
			activeMarker: marker,
			selectedPlace,
			showInfoWindow: true
		})
	}

	handleInfoWindowClose = () => {
		this.setState({
			activeMarker: {},
			showInfoWindow: false
		})
	}

	onMapClicked = () => {
		if (this.state.showInfoWindow) {
			this.setState({
				activeMarker: {},
				showInfoWindow: false
			})
		}
	}

	changeMarker = direction => {
		let nextId
		if (direction === 'next') {
			if (this.state.locationId === this.state.stores.length - 1) {
				nextId = 0
			} else {
				nextId = this.state.locationId + 1
			}
		} else {
			if (this.state.locationId === 0) {
				nextId = this.state.stores.length - 1
			} else {
				nextId = this.state.locationId - 1
			}
		}

		const lat = this.state.stores[nextId].latitude
		const lng = this.state.stores[nextId].longitude
		this.state.map.panTo({ lat, lng })

		this.setState({ locationId: nextId }, () => this.displayMarkers())
	}

	nestLocationLayer = () => {
		if (document){
			const mapTG = document.querySelector('.gm-style')
			if (mapTG){
				const locationHolder = document.querySelector('.map-location-holder')
				if (locationHolder){
					mapTG.appendChild(locationHolder)
				}
			}
		}
	}

	mapBoundsChanged = () => {
		if (!this.state.locationHolderNested){
			this.setState({
				locationHolderNested: true
			})
			this.nestLocationLayer()
		}
	}


	render() {
		const { __, data } = this.props
		const { stores, locationId, activeMarker, showInfoWindow, selectedPlace } = this.state
		const { loading } = data
		const currentStore = stores[locationId] || stores[0]
		let times = selectedPlace && selectedPlace.times ? selectedPlace.times.replace(/(?:\r\n|\r|\n)/g, '<br />') : null
		return !stores[0] ? <SimpleLoader loading={ true } transparent={true}></SimpleLoader> : (
			<SimpleLoader loading={ loading } transparent={true}>
				<DesktopView>
					<div className="desktop-locations-title">
						<h2>{ __('All Locations')}</h2>
					</div>
					<div className="map-wrapper">
						<WebMap stores={ stores } />
						<div className="desktop-map">
							<Map
								google={ this.props.google }
								zoom={ 20 }
								className="map"
								onClick={this.onMapClicked}
								initialCenter={ stores[0] ? { lat: stores[0].latitude, lng: stores[0].longitude } : { lat: 0, lng: 0 }}
								streetViewControlOptions={{ position: 7 }}
								zoomControlOptions={{ position: 7 }}
								onBounds_changed={ this.mapBoundsChanged }
								onReady={ this.onMapReady }>
								{ this.displayMarkers()}
								{
									selectedPlace ?
										<InfoWindow
											marker={ activeMarker }
											visible={ showInfoWindow }
											onClose={ this.handleInfoWindowClose }
										>
											<div className="show-marker-info">
												<p className="show-marker-info-name"><FallbackText>{selectedPlace.name}</FallbackText></p>
												<p className="show-marker-info-address"><FallbackText>{selectedPlace.address}</FallbackText></p>
												<p className="show-marker-info-times" dangerouslySetInnerHTML={{__html: times ? times : ''}}></p>
											</div>
										</InfoWindow>
										:
										null
								}
							</Map>
						</div>
					</div>
				</DesktopView>
				<MobileView>
					<Map
						google={ this.props.google }
						zoom={ 12 }
						className="map"
						initialCenter={ stores[0] ? { lat: stores[0].latitude, lng: stores[0].longitude } : { lat: 0, lng: 0 }}
						streetViewControlOptions={{ position: 7 }}
						zoomControlOptions={{ position: 7 }}
						onBounds_changed={ this.mapBoundsChanged }
						onReady={ this.onMapReady }>
						{ this.displayMarkers()}
					</Map>
					<div className="map-location-holder">
						<IonToolbar color="primary" style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
							<IonButtons slot="start">
								<IonButton button clear onClick={() => this.changeMarker('prev')}>
									<IonIcon slot="icon-only" icon={ arrowBack } />
								</IonButton>
							</IonButtons>

							<IonTitle className="ion-text-center">{ currentStore ? currentStore.name : '--' }</IonTitle>

							<IonButtons slot="end">
								<IonButton button clear onClick={() => this.changeMarker('next')}>
									<IonIcon slot="icon-only" icon={ arrowForward } />
								</IonButton>
							</IonButtons>
						</IonToolbar>

						<IonContent scrollEvents={ true }>
							{ currentStore ? (
								<IonList className="h-padded location-section">
									<IonItem>
										<IonText>
											<h4>{ __('Opening Times')}</h4>
											<p><Nl2Br>{ currentStore.opening_times }</Nl2Br></p>
										</IonText>
									</IonItem>
									<IonItem>
										<IonText>
											<h4>{ __('Address')}</h4>
											<p><FallbackText>{ currentStore.address }</FallbackText>, <FallbackText>{ currentStore.town }</FallbackText> <FallbackText>{ currentStore.postcode }</FallbackText></p>
										</IonText>
									</IonItem>
									<IonItem>
										<IonText>
											<h4>{ __('Telephone Number')}</h4>
											<p><FallbackText>{ currentStore.telephone }</FallbackText></p>
										</IonText>
									</IonItem>
									<IonItem>
										<IonText>
											<h4>{ __('Email Address')}</h4>
											<p><FallbackText>{ currentStore.email }</FallbackText></p>
										</IonText>
									</IonItem>
								</IonList>
							) : null }
						</IonContent>
					</div>
				</MobileView>
			</SimpleLoader>
		)
	}
}

const stateToProps = (state) => {
	const { data, common } = state
	return {
		data,
		apiMethod: 'restaurants',
		myLocation: common.myLocation
	}
}

export default GoogleApiWrapper({
	apiKey: appConfig.services.google_api_key
})(connect(stateToProps)(withTranslation(MapContainer)))
