import React from 'react'
import { withRouter } from 'react-router'
import { IonPage, IonContent, useIonViewDidEnter, useIonViewDidLeave, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react'
import { Plugins } from '@capacitor/core'

import appConfig from '../../appConfig'
import Header from '../../components/header'
import Footer from '../../components/footer'
import RightPopover from '../../components/rightPopover'
import { isDesktop } from '../../lib/utils'
import { MobileView } from '../mobileView'
import { DesktopView } from '../desktopView'
import { isDefined } from '../../lib/utils'

require('./index.css')
if (isDesktop()) {
	require('./desktop.css')
} else {
	require('./mobile.css')
}


const defaultHeaderTitle = appConfig.theme.nav.label
const { App } = Plugins

const Layout = ({ children, history, headerTitle, hideSecondToolbar, hideBack, hideForward = true, color, poster, blank, noPadding, contentClassName, swiper, onBackHandler, onForwardHandler, subHeader, subHeaderByob, subHeaderSelectCategory, scrollToSection, sectionIndex }) => {
	useIonViewWillEnter(() => {
		// eslint-disable-next-line no-console
		console.log('1. WillEnter event fired')
	})
	useIonViewDidEnter(() => {
		// eslint-disable-next-line no-console
		console.log('2. DidEnter event fired')
	})
	useIonViewWillLeave(() => {
		// eslint-disable-next-line no-console
		console.log('3. WillLeave event fired')
	})
	useIonViewDidLeave(() => {
		// eslint-disable-next-line no-console
		console.log('4. DidLeave event fired')
	})
	if (!App.listeners || !App.listeners.backButton){
		App.addListener('backButton', () => {
			if (history.location && history.location.pathname && appConfig.general.appExitRoutes.indexOf(history.location.pathname) !== -1){
				App.exitApp()
			} else {
				if (isDefined(onBackHandler)) {
					onBackHandler()
				} else {
					history.goBack()
				}
			}
		})
	}
	return (
		<div className="content-wrapper">
			<DesktopView>
				<RightPopover />
			</DesktopView>
			<IonPage className={ blank ? 'blank' : '' }>
				{ blank ? null :
					<>
						{ hideSecondToolbar ? null :
							<Header title={ headerTitle || defaultHeaderTitle } onBackHandler={ onBackHandler } hideBack={ hideBack } hideForward={ hideForward } onForwardHandler={ onForwardHandler } history={ history } subHeader={ subHeader } subHeaderByob={ subHeaderByob } subHeaderSelectCategory={ subHeaderSelectCategory} scrollToSection={ scrollToSection } sectionIndex={ sectionIndex }/>
						}
					</>
				}
				<IonContent fullscreen={true} color={ color ? color : 'light' } className={ contentClassName || '' }>
					<DesktopView>
							{ poster ? <div className={ 'poster ' + poster }/> : null }
							<div className='desktop-content-wrapper'>
								{ swiper ? children : <div className={ noPadding ? 'no-padding desktop' : 'ion-padding desktop' }>
									{ children }
								</div> }
								<Footer />
							</div>
						</DesktopView>
						<MobileView>
							{ poster ? <div className={ 'poster ' + poster }/> : null }
							{ swiper ? children : <div className={ noPadding ? 'no-padding' : 'ion-padding' }>{ children }</div> }
						</MobileView>
				</IonContent>
			</IonPage>
		</div>
	)
}

export default withRouter(Layout)
