export const SET_AUTH = 'SET_AUTH'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const LOGOUT = 'LOGOUT'
export const REDIRECT_AFTER_LOGIN = 'REDIRECT_AFTER_LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const SEND_FIREBASE_TOKEN = 'SEND_FIREBASE_TOKEN'
export const SET_PROFILE_PROP = 'SET_PROFILE_PROP'
export const GET_PROFILE = 'GET_PROFILE'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const SEND_FEEDBACK = 'SEND_FEEDBACK'
export const SEND_REFER = 'SEND_REFER'
export const SEND_VOUCHER_CODE = 'SEND_VOUCHER_CODE'
export const GET_VOUCHERS = 'GET_VOUCHERS'
export const SET_VOUCHERS = 'SET_VOUCHERS'
export const SEND_MOBILE = 'SEND_MOBILE'
export const MOBILE_CHECK_SMS = 'MOBILE_CHECK_SMS'
export const RESTORE_AUTH = 'RESTORE_AUTH'
export const SET_CARDS = 'SET_CARDS'
export const SET_SYS_LOCALE = 'SET_SYS_LOCALE'
