import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { addToBasket, showToast,dataRequest, dataClearError, selectCategory } from '../../../store/actions'
import { IonButton, IonIcon, IonSlides, IonSlide, IonList, IonItem, IonLabel, IonInput, IonListHeader, IonRadioGroup, IonRadio, IonCheckbox, IonToolbar, IonButtons, IonTitle } from '@ionic/react'
import Layout from '../../../components/layout'
import SimpleLoader from '../../../components/simpleLoader'
import { withTranslation } from '../../../lib/translate'
import Modal from '../../../components/modal'
import Alert from '../../../components/alert'
import appConfig from '../../../appConfig'
import { isEmptyObject, forwardTo, isDefined, isDesktop, currencySign, price, protectedOrder } from '../../../lib/utils'
import * as icons from 'ionicons/icons'
import { FallbackText, Nl2Br } from '../../../components/common'
import { close } from 'ionicons/icons'

import './index.css'

const s3Url = appConfig.configS3.url

class WebMenu extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedCategory: {},
			selectedProduct: null,
			showProductModal: false,
			is_byob: false,
			byob_type: 'is_base_it',
			bowl: [],
			base_it_items: [],
			typeOfAlert: '',
			top_it_items: [],
			finish_it_items: [],
			drinksAndSnacks: [],
			sauces: [],
			is_bowl: false,
			is_sauce: false,
			additional: false,
			modifiers: {
				radio: null,
				check: []
			},
			showAlert: false,
			quantity: 1,
			basket: {},
			notes: '',
			menu: [],
			selectedMenu: null,
			categories: {},
			default_byob_item: {},
			bowlSizeSelectedItem: 0,
			sectionIndex: null,
			comboSequences: {},
			comboProduct: null,
			selectedSauce: {}
		}
		this.onBackHandler = this.onBackHandler.bind(this)
		this.onForwardHandler = this.onForwardHandler.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.closeAlert = this.closeAlert.bind(this)
		this.bowlRefs = {}
	}

	handleData = () => {
		const { data, apiMethod, dispatch, history, __ } = this.props
		const { errors } = data
		const error = errors[apiMethod]

		if (error){
			dispatch(showToast(error.message, 'danger'))
			dispatch(dataClearError(apiMethod))
		} else {
			const { content } = data
			if (content && content[apiMethod]){
				const menu = content[apiMethod]
				if (!menu || menu.available_products && menu.available_products.length === 0){
					dispatch(showToast(__('This restaurant has no products available') , 'warning'))
					forwardTo(history, '/dashboard')
					dispatch(dataClearError(apiMethod))
				}
				const categories = this.createCategory(menu)
				this.setState({ menu, categories })
			}
		}
	}

	handleProtectOrder = () => {
		const { __, dispatch, history, restaurantId, collectionTime } = this.props

		if (!protectedOrder(restaurantId, collectionTime)) {
			dispatch(showToast(__('Please select the restaurant in order to access it\'s menu') , 'warning'))
			forwardTo(history, '/locations')
		}
	}

	componentDidMount(){
		const { selectedCategory } = this.props
		const { apiMethod, dispatch, restaurantId } = this.props
		dispatch(dataRequest(apiMethod, {restaurant_id: restaurantId}))
		const is_byob = selectedCategory && selectedCategory.category ? selectedCategory.category.attributes.is_byob : false
		const is_bowl = selectedCategory && selectedCategory.category ? selectedCategory.category.attributes.is_bowl : false
		const is_sauce = selectedCategory && selectedCategory.category ? selectedCategory.category.attributes.is_sauce : false
		this.setState({ selectedCategory, is_byob, is_bowl, is_sauce, additional: false })
	}

	componentDidUpdate (prevProps, prevState) {
		const { data, restaurantId, collectionTime } = this.props
		const { categories } = this.state
		let default_byob_item = {}
		if (prevProps.data !== data){
			this.handleData()
		}

		if (prevProps.restaurantId === restaurantId && prevProps.collectionTime === collectionTime && !restaurantId && !collectionTime) {
			this.handleProtectOrder()
		}

		if (prevState.categories && prevState.categories !== categories && categories) {
			(Object.keys(categories ? categories :{}) || []).forEach((key) => {
				let attributes = categories[key].category.attributes
				if (attributes.is_bowl) {
					Object.keys(categories[key].subCategories).forEach((subKey) => {
						let attrSub = categories[key].subCategories[subKey].subCategory.attributes ? categories[key].subCategories[subKey].subCategory.attributes.is_byob : null
						if (attrSub) {
							default_byob_item = categories[key].subCategories[subKey].products[0]
						}
					})
				}
			})
			this.setState({ default_byob_item })
		}
	}

	changeQuantity = (val, product, type) => {
		const { drinksAndSnacks, is_sauce, bowl, sauces } = this.state
		let quantity = product.quantity ? product.quantity : 1
		if (sauces.length > 0) {
			let item = sauces.find(sauce => sauce.product[0].id === product.product[0].id)
			let q = 0
			if (item) {
				quantity = item && item.quantity ? item.quantity : 0
				q = quantity + val
				if (q < 1) {
					q = 0
				}
				item.quantity = q
			}
		}
		let qty = quantity + val
		if (qty < 1) {
			qty = 0
		}
		let newProduct = {...product}
		newProduct.quantity = qty
		this.setState({ quantity: qty }, () => {
			if (type) {
				// have to handle this
				this.quantityModal(newProduct, is_sauce ? bowl : drinksAndSnacks)
			} else {
				this.displayProduct(newProduct)
			}
		})
	}

	displayProduct = (product) => {
		const { __, currency } = this.props

		let item = null
		let itemPrice = null
		let bowlItem = []
		let quantity = product.quantity ? product.quantity : 1
		item = product.product[0]
		itemPrice = price(item.price)
		bowlItem.push(product)
		const selectedProduct =
			<div className='item-content'>
				{/* <div className='item-header'>
					<div className='item-name'>{item.order_name.toUpperCase()}</div>
					<div className='item-close'><IonIcon slot="icon-only" icon="close" onClick={ this.closeModal }></IonIcon></div>
				</div> */}
				<IonToolbar color="white">
					<IonButtons slot="start">
						<IonButton button clear style={{ visibility: 'hidden' }}>
							<IonIcon slot="icon-only" icon={ close } />
						</IonButton>
					</IonButtons>

					<IonTitle className="ion-text-center modal-title item-name">{ __(item.order_name.toUpperCase()) }</IonTitle>

					<IonButtons slot="end">
						<IonButton button clear onClick={ this.closeModal }>
							<IonIcon slot="icon-only" icon={ close } />
						</IonButton>
					</IonButtons>
				</IonToolbar>
				{/* <div className='item-image' style={{backgroundImage: `url('${s3Url + item.image}')`}}></div>
				<div className='item-description'>{item.description}</div> */}
				<div className='item-actions'>
					{this.selectBowlSizeOption(product)}
					<div className='item-actions-header'>{ __('Update Quantity') }</div>
					<div className='item-action'>
						<div className='quantity-button' onClick={() => { this.changeQuantity(-1, product) }}>
							<IonButton expand="full"> <IonIcon slot="icon-only" icon={icons.remove} /></IonButton>
						</div>
						<div className='item-quantity'>{quantity}</div>
						<div className='quantity-button' onClick={() => { this.changeQuantity(1, product) }}>
							<IonButton expand="full"> <IonIcon slot="icon-only" icon={icons.add} /> </IonButton>
						</div>
					</div>
					<div className='item-price'>{ itemPrice ? this.formatPrice(itemPrice) : ''}</div>
				</div>
				<IonButton expand='full' className='select-base' onClick={() => {this.showModalBase(product, 'modifiers')}}>{ __('Select Base') }</IonButton>
			</div>
		this.setState({selectedProduct, showProductModal: true, bowl: bowlItem})
	}

	drawHorizontalSubCategories = (subCategory, index, byob, categoryName) => {
		const { byob_type, base_it_items, top_it_items, finish_it_items, is_sauce, is_byob, sauces, is_bowl } = this.state
		const { __, currency, basket } = this.props
		const slideOpts = {
			initialSlide: 0,
			speed: 400,
			// slidesPerView: 3,
			spaceBetween: 10,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			breakpoints: {
				320: {
					slidesPerView: 3
				},
				480: {
					slidesPerView: 3
				},
				640: {
					slidesPerView: 3
				},
				991: {
					slidesPerView: 5
				},
				1440: {
					slidesPerView: 5
				},
				1920: {
					slidesPerView: 6
				},
				2160: {
					slidesPerView: 7
				},
				2560: {
					slidesPerView: 7
				}
			}
		}
		const subCategoryName = subCategory.subCategory.name
		return (
			<div key={index}>
				<div className={'horizontal-slider-content' + byob} key={index}>
					<div className='desktop-sauce-wrapper'>
						<div className={'category-title' + byob}>{ __(subCategoryName) }</div>
						<div className='horizontal-slider'>
							<IonSlides pager={false} options={slideOpts}>
								{subCategory.products.
									sort((a, b) => a.product[0].sort_order > b.product[0].sort_order ? 1 : -1).
									map((product, i) => {
										const item = product.product[0]
										const itemPrice = price(item.price)
										let selected = false

										if (byob_type === 'is_finish_it') {
											selected = finish_it_items.find(product => product.product[0].id === item.id ? true : false)
										} else if (byob_type === 'is_top_it') {
											selected = top_it_items.find(product => product.product[0].id === item.id ? true : false)
										} else {
											selected = base_it_items.find(product => product.product[0].id === item.id ? true : false)
										}
										if (sauces.length > 0) {
											selected = sauces.find(product => product.product[0].id === item.id ? true : false)
										}
										if (basket && basket.items.length > 0 && !is_sauce && !is_byob && !is_bowl ) {
											selected = basket.items.find(product => product.product === item.id ? true : false)
										}
										return (
											<IonSlide key={item.id}
												onClick={() => {
													this.pickItems(product, byob)
												}}>
												<div className='item-slide'>
													<div className={selected ? 'item-slide-image-selected' : 'item-slide-image'}>
														<img alt='' style={{ opacity: selected ? 0.5 : 1 }} src={s3Url + item.image} />
														{selected ?
															<div className='selected-item-checkmark'>
																<IonIcon color='white' icon={icons.checkmark}/>
															</div>
											 : null }
													</div>
													<p className='item-slide-name'>{item.order_name}</p>
													<p className='item-slide-price'>{ itemPrice ? this.formatPrice(itemPrice) : '' }</p>
												</div>
											</IonSlide>
										)
									})}
							</IonSlides>
						</div>
					</div>
				</div>
			</div>
		)
	}

	pickItems = (product, byob) => {
		const { __ } = this.props
		const { byob_type, finish_it_items, drinksAndSnacks } = this.state
		if (byob !== '') {
			let b_type = ''
			if (byob_type === 'is_base_it') {
				b_type = 'is_top_it'
				const base_it_items = []
				base_it_items.push(product)
				this.setState({ byob_type: b_type, base_it_items })
			} else if (byob_type === 'is_top_it') {
				b_type = 'is_finish_it'
				const top_it_items = []
				top_it_items.push(product)
				this.doubleUpModal(product)
				this.setState({ byob_type: b_type, top_it_items})
			} else {
				let items = [...finish_it_items]
				let findItem = finish_it_items.length > 0 ? !!finish_it_items.find(item => item.product[0].id === product.product[0].id) : false
				if (findItem) {
					items = finish_it_items.filter(item => item.product[0].id !== product.product[0].id)
				} else {
					items.push(product)
				}
				this.setState({ finish_it_items: items })
			}
		} else {
			let items = [...drinksAndSnacks, product]
			let selectedProduct =
				<div>
					{/* <div className='item-third-header'>
						<IonLabel size={20}>{ product.product[0].order_name.toUpperCase()}</IonLabel>
						<IonIcon icon={icons['close']} onClick={()=> { this.closeModal() }}/>
					</div> */}
					<IonToolbar color="white">
						<IonButtons slot="start">
							<IonButton button clear style={{ visibility: 'hidden' }}>
								<IonIcon slot="icon-only" icon={ close } />
							</IonButton>
						</IonButtons>

						<IonTitle className="ion-text-center modal-title item-name">{ product.product[0].order_name.toUpperCase() }</IonTitle>

						<IonButtons slot="end">
							<IonButton button clear onClick={() => this.closeModal()}>
								<IonIcon slot="icon-only" icon={ close } />
							</IonButton>
						</IonButtons>
					</IonToolbar>
					<IonList className='item-third-list'>
						{product.product[0].sequences.map((sequnce, i) => {
							return sequnce.options.map((option, idx) => {
								return (
									<IonItem key={idx} onClick={() => {this.quantityModal(product, items, idx)}}>
										<div tabIndex="-1"></div>
										<IonLabel>{option.name}</IonLabel>
									</IonItem>
								)
							})
						})}
					</IonList>
					<IonButton expand='full' disabled className='select-base-button'> { __('Add to Basket') }</IonButton>
				</div>


			if (product.product[0].sequences.length > 0) {
				this.setState({ showProductModal: true, selectedProduct })
			} else {
				this.quantityModal(product, items)
			}
		}
	}

	addTopItExtra = (type, obj) => {
		const { top_it_items } = this.state
		if (type !== 'no') {
			top_it_items[0].top_it_attributes = obj
			top_it_items[0].double = type === 'double' ? true : false
			this.setState({ showProductModal: false, top_it_items })
		} else {
			delete top_it_items[0].double
			delete top_it_items[0].top_it_attributes
			this.setState({ showProductModal: false, top_it_items })
		}
	}

	setModifiers = (modifier, type, index, min) => {
		const { modifiers } = this.state
		const newModifiers = {...modifiers}
		if (type === 'radio' && index === 0) {
			newModifiers[type] = modifier
			newModifiers.radioMin = min
		} else if (type === 'radio' && index !== 0) {
			newModifiers.check = [modifier]
			newModifiers.checkMin = min
		} else {

			let filteredModifier = !!newModifiers[type].find(mod => mod === modifier)
			if (!filteredModifier) {
				newModifiers[type].push(modifier)
			} else {
				newModifiers[type] = newModifiers[type].filter(mod => mod !== modifier)
			}
			newModifiers.checkMin = min
		}
		this.setState({ modifiers: newModifiers})
	}

	openSaucesCategory = (type) => {
		const { comboSequences, comboProduct } = this.state
		if (type && type === 'sequences' && comboProduct) {
			if (comboProduct.product[0].sequences.length === Object.keys(comboSequences).length) {
				this.setState({ is_bowl: false, is_sauce: true, setClassName: 'modal-auto-height', showProductModal: true, showSaucesModal: true, selectedSauce: this.setSelectedCategory(), quantity: 1 }, () => {
					this.showSaucesModal(this.setSelectedCategory(), 0, '')
				})
			} else {
				let type;
				const selectedSequence = comboProduct.product[0].sequences[0];
				if(comboProduct.product[0].sequences.length === 2 && Object.keys(comboSequences).length === 0){
					type = 'drinkAndDessert'
				}else if(comboProduct.product[0].sequences.length === 2) {
					const ret = selectedSequence.items.find(item=> item.item_sku === Object.values(comboSequences)[0]);
					type = ret ? "dessert" : "drink"
				}else{
					type = selectedSequence.name === "Choisir une boisson" ? "drink" :  "dessert";
				}
				this.setState({ typeOfAlert: type, showAlert: true, comboBaseFlag: true })
			}
		} else {
			this.setState({ is_bowl: false, is_sauce: true, setClassName: 'modal-auto-height', showProductModal: true, showSaucesModal: true, selectedSauce: this.setSelectedCategory(), quantity: 1 }, () => {
				this.showSaucesModal(this.setSelectedCategory(), 0, '')
			})
		}
	}

	setSequences = (sequence, type, index, min) => {
		const { comboSequences } = this.state
		const newcomboSequences = {...comboSequences}
		newcomboSequences[index] = sequence
		this.setState({ comboSequences: newcomboSequences})
	}

	getModifierComboSkus = (sku, bowl) => {
		const modifier = bowl && bowl[0].product && bowl[0].product[0].modifiers && bowl[0].product[0].modifiers[0].items && bowl[0].product[0].modifiers[0].items.length > 0 ?
			bowl[0].product[0].modifiers[0].items.find(item => item.item_sku === sku) : null
		if (modifier && modifier.attributes && modifier.attributes.canCombo) {
			return modifier.attributes.comboSkus
		}
		return null
	}

	findAvailableProductBySku = (sku) => {
		const { data } = this.props
		const { content } = data
		const { productAvailability } = content
		return (productAvailability.available_products || []).find(product => product.product[0].sku === sku)
	}

	displaySequencesModal = (item, type) => {
		if (item) {
			this.showModalBase(item, 'sequences', type)
			this.setState({comboProduct: item})
		} else {
			this.setState({ is_bowl: false, is_sauce: true, showProductModal: false, selectedCategory: this.setSelectedCategory(), quantity: 1 })
		}
	}
	setSelectedCategory = () => {
		const { location } = this.props
		const { modifiers } = this.state
		let categories = location.state && location.state.categories || this.state.categories
		let cat = {}
		Object.keys(categories).forEach((key) => {
			if (categories[key].category.attributes.is_sauce) {
				cat = categories[key]
			}
		})
		return cat
	}
	displayCombo = (comboSkus, product, type) => {
		const { __, currency } = this.props
		const foundProducts = []
		comboSkus.forEach(comboSku => {
			if (this.findAvailableProductBySku(comboSku)) {
				foundProducts.push(this.findAvailableProductBySku(comboSku))
			}
		})
		let selectedProduct =
		<div>
			<div className='item-third-header'>
				<IonLabel size={20}>{ __('Make it a combo?') }</IonLabel>
			</div>
			<div className='item-actions'>
				<div className='item-actions-header'>{__('Would you like to upgrade to a combo?')}</div>
				{foundProducts.map((prod,i) => {
					const item = prod.product[0]
					const itemName = item.order_name
					const itemPrice = item.price ? ((item.price - product[0].product[0].price) / 100).toFixed(2) : 0
					const buttonLabel = itemName + ' ' + (currency === 'gbp' ? ' + ' + currencySign(currency) + ' ' + itemPrice : ' + ' + itemPrice + ' ' + currencySign(currency))
					return <IonButton key={i} text-wrap expand='full' style={{ whiteSpace: 'pre-wrap' }} color='tertiary' disabled={false} onClick={() => {this.displaySequencesModal(prod, type)}}>{ buttonLabel }</IonButton>
				})}
				<IonButton className='no-thanks-button' expand='full' fill='clear' disabled={false} onClick={() => {type === 'bowl' ? this.openSaucesCategory() : this.displaySaucesByob()}}>{ __ ('No thanks') }</IonButton>
			</div>
		</div>

		this.setState({ showProductModal: true, selectedProduct })
	}

	setBowl = () => {
		const { __, location, currency } = this.props
		const { modifiers, bowl } = this.state
		if (!modifiers.radio) {
			this.setState({ showAlert: true })
		} else {
			if (modifiers.checkMin && modifiers.checkMin > modifiers.check.length) {
				this.setState({ showAlert: true })
			} else {
				const canCombo = bowl[0].product[0].additional_attributes ? bowl[0].product[0].additional_attributes.canCombo : false
				const comboSkus = bowl[0].product[0].additional_attributes ? bowl[0].product[0].additional_attributes.comboSkus : []
				if (canCombo) {
					this.displayCombo(comboSkus, bowl, 'bowl')

				} else {
					let categories = location.state && location.state.categories || this.state.categories
					let cat = {}
					Object.keys(categories).forEach((key) => {
						if (categories[key].category.attributes.is_sauce) {
							cat = categories[key]
						}
					})
					this.setState({ is_bowl: false, is_sauce: true, setClassName: 'modal-auto-height', showProductModal: true, selectedCategory: cat, quantity: 1 }, () => {
						this.showSaucesModal(cat, 0, '')
					})
				}
			}
		}
	}

	showModalBase = (product, type, itemType) => {
		const { __ } = this.props
		const item = product.product[0]
		let min = null
		let selectedProduct =
			<div className='item-content'>
				<IonToolbar color="white">
					<IonButtons slot="start">
						<IonButton button clear style={{ visibility: 'hidden' }}>
							<IonIcon slot="icon-only" icon={ close } />
						</IonButton>
					</IonButtons>

					<IonTitle className="ion-text-center modal-title item-name">{ __('Options') }</IonTitle>

					<IonButtons slot="end">
						<IonButton button clear onClick={() => type === 'modifiers' ? this.displayProduct(product) : this.setState({showProductModal: false})}>
							<IonIcon slot="icon-only" icon={ close } />
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<div className='item-actions-base'>
					{item[type].map((modifier, index) => {
						// eslint-disable-next-line no-console
						let radio = null
						if (modifier.max_select === 1){
							radio = true
						} else if (modifier.max_select > 1){
							radio = false
						} else {
							radio = false
						}
						return (
							<IonList key={index}>
								<IonListHeader className='list-header'>
									<IonLabel>{ __(modifier.name) }</IonLabel>
								</IonListHeader>
								<div>
									{ radio ?
										<IonRadioGroup onIonChange={(e) => { type === 'modifiers' ? this.setModifiers(e.target.value, 'radio', index, modifier.min_select) : this.setSequences(e.target.value, 'radio', index, modifier.min_select)}}>
											<div className="select-base-content">
												{

													modifier.items.map((item, i) => {
														const itemPrice = price(item.price)
														return (
															<IonList key={i}>
																<IonItem lines='none' className='select-base-item'>
																	<IonLabel>{item.description}</IonLabel>
																	<div tabIndex="-1"></div>
																	<IonRadio slot="end" value={item.item_sku} />
																	<p className='item-action-modifier-price'>{ itemPrice ? this.formatPrice(itemPrice) : ''}</p>
																</IonItem>
															</IonList>
														)
													})

												}
											</div>
											<div className='arrow-down'>
												<IonIcon slot="icon-only" mode='ios' color='medium' size="medium" icon={icons.arrowDown} />
											</div>
										</IonRadioGroup>

										:

										<>
											<div className='select-modifiers-content'>
												{
													modifier.items.map((item, i) => {
														const itemPrice = price(item.price)
														return (
															<IonList key={i}>
																<IonItem lines='none' className='select-modifier-item'>
																	<IonLabel>{item.description}</IonLabel>
																	<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }} slot="end">
																		<IonCheckbox value={item.item_sku} onIonChange={(e) => {this.setModifiers(e.target.value, 'check', index, modifier.min_select)}}/>
																	</div>
																	<p className='item-action-modifier-price'>{ itemPrice ? this.formatPrice(itemPrice) : ''}</p>
																</IonItem>
															</IonList>
														)
													})
												}
											</div>
											<div className='arrow-down'>
												<IonIcon slot="icon-only" mode='ios' color='medium' size="medium" icon={icons.arrowDown} />
											</div>
										</>

									}
								</div>
							</IonList>
						)
					})}
				</div>
				<div className='item-actions-footer'>
					<div className='special-notes'>
						<IonLabel> { __('Special Notes and Allergies') }</IonLabel>
						<IonInput type='text' value={this.state.notes} placeholder={ __('Enter notes here') } onIonChange={(e)=> {this.setState({ notes: e.target.value})}}></IonInput>
					</div>
					<IonButton expand='full' className='select-base' onClick={() => { type === 'modifiers' ? this.setBowl() : itemType === 'bowl' ? this.openSaucesCategory(type) : this.displaySaucesByob() }}> { __('Add to Basket') }</IonButton>
				</div>
			</div>


		this.setState({ selectedProduct })
	}

	formatPrice = price => this.props.currency === 'gbp' ? currencySign(this.props.currency) + price : price + currencySign(this.props.currency)

	doubleUpModal = (product) => {
		const { __, currency } = this.props
		const productName = product.product[0].published_name ? product.product[0].published_name.toUpperCase() : ''
		const displayPrice = product.product[0].attributes.displayPrice
		// const canDoubleUp = product.product[0].attributes.canDoubleUp
		// const comboText = product.product[0].attributes.comboText ? product.product[0].attributes.comboText.toUpperCase() : ''
		// const combo = product.product[0].attributes
		const attributes = product.product[0].attributes

		const canDoubleUp = attributes.canDoubleUp
		const doubleUpText = attributes.doubleUpText ? attributes.doubleUpText.toUpperCase() : ''
		const doubleUpObj = { displayPrice: attributes.doubleUpPrice || displayPrice, comboSku: attributes.doubleUpSku || attributes.comboSku, comboText: doubleUpText || attributes.comboText, image: attributes.doubleUpImg }

		const canCombo = attributes.canCombo
		const comboText = attributes.comboText ? attributes.comboText.toUpperCase() : ''
		const comboObj = { displayPrice: attributes.comboPrice, comboSku: attributes.comboSku, comboText, image: attributes.comboImg }
		let selectedProduct = <div>
			<div className='item-third-header'>
				<IonLabel size={20}>{ __('Double up?') }</IonLabel>
			</div>
			<div className='item-actions'>
				<div className='item-actions-header'>{__('Would you like to double up your protein for an extra')} {this.formatPrice(doubleUpObj.displayPrice)}</div>
				{ canDoubleUp ? <IonButton text-wrap expand='full' style={{ whiteSpace: 'pre-wrap' }} color='tertiary' disabled={false} onClick={() => {this.addTopItExtra('double', doubleUpObj)}}>{__('Yes')} - {doubleUpText || productName} - {this.formatPrice(doubleUpObj.displayPrice)}</IonButton> : null }
				{ canCombo ? <IonButton style={{ whiteSpace: 'pre-wrap' }} expand='full' color='tertiary' disabled={false} onClick={() => {this.addTopItExtra('combo', comboObj)}}>{__('Yes')} - { comboText } - {this.formatPrice(comboObj.displayPrice)}</IonButton> : null }
				<IonButton className='no-thanks-button' expand='full' fill='clear' disabled={false} onClick={() => {this.addTopItExtra('no')}}>{ __ ('No thanks') }</IonButton>
			</div>
		</div>
		this.setState({ showProductModal: true, selectedProduct })
	}

	setSauce = (product) => {
		const { sauces, selectedSauce } = this.state
		let s = null
		let aleradyAddedSauce = sauces.find(sauce => sauce.product[0].id === product.product[0].id)
		if (aleradyAddedSauce) {
			sauces.forEach((sauce) => {
				if (sauce.product[0].id === aleradyAddedSauce.product[0].id) {
					sauce.product.quantity = product.quantity
				}
			})
			if (product.quantity === 0) {
				s = sauces.filter(sauce => sauce.product[0].id !== product.product[0].id)
			}
			let newSauces = s ? s : sauces
			this.setState({ sauces: newSauces, showProductModal: true }, () => {
				// subCategory, index, byob
				this.showSaucesModal(selectedSauce, 0, '')
			})
		} else {
			if (product.quantity === undefined || product.quantity === 0) {
				product.quantity = 1
			}
			let newSauces = [...sauces, product]
			this.setState({ sauces: newSauces, showProductModal: true }, () => {
				// subCategory, index, byob
				this.showSaucesModal(selectedSauce, 0, '')
			})
		}
	}

	quantityModal = (product, items, sequenceOptionIndex) => {
		const { __ } = this.props
		const { is_sauce, sauces } = this.state
		let quantity = product.quantity ? product.quantity : 1
		if ( sauces.length > 0) {
			let item = sauces.find(sauce => sauce.product[0].id === product.product[0].id)
			if (item) {
				quantity = item && item.quantity ? item.quantity : 0
			}
		}

		let selectedProduct =
			<div className='category-content'>
				{/* <div className='item-third-header'>
					<IonLabel size={20}>{ product.product[0].order_name.toUpperCase()}</IonLabel>
					<IonIcon icon={icons['close']} onClick={()=> { this.closeModal() }}/>
				</div> */}
				<IonToolbar color="white">
					<IonButtons slot="start">
						<IonButton button clear style={{ visibility: 'hidden' }}>
							<IonIcon slot="icon-only" icon={ close } />
						</IonButton>
					</IonButtons>

					<IonTitle className="ion-text-center modal-title item-name">{ product.product[0].order_name.toUpperCase() }</IonTitle>

					<IonButtons slot="end">
						<IonButton button clear onClick={() => this.closeModal()}>
							<IonIcon slot="icon-only" icon={ close } />
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<div className='item-actions'>
					<div className='item-actions-header'> { __('Update Quantity') }</div>
					<div className='item-action'>
						<div className='quantity-button' onClick={() => { this.changeQuantity(-1, product, 'quantity') }}>
							<IonButton disabled={is_sauce ? quantity > 0 ? false : true : quantity > 1 ? false : true } expand="full"> <IonIcon slot="icon-only" icon={icons.remove} /></IonButton>
						</div>
						<div className='item-quantity'>{quantity}</div>
						<div className='quantity-button' onClick={() => { this.changeQuantity(1, product, 'qunatity') }}>
							<IonButton expand="full"> <IonIcon slot="icon-only" icon={icons.add} /> </IonButton>
						</div>
					</div>
				</div>
				<IonButton expand='full' disabled={false} className='select-base-button quantity' onClick={() => is_sauce ? this.setSauce(product) : this.add('drinksAndSnacks') }> { __('Add to Basket') }</IonButton>
			</div>


		if (!is_sauce) {
			product.quantity = quantity
			if (!isDefined(product.sequences)) {
				product.sequences = product.product[0].sequences.length ? product.product[0].sequences[0].options[sequenceOptionIndex] : null
			}
			this.setState({ showProductModal: true, selectedProduct, drinksAndSnacks: [product] })
		} else {
			this.setState({ showProductModal: true, selectedProduct })
		}
	}

	add = (type, redirect) => {
		const { dispatch, location, basket, history } = this.props
		const { modifiers, drinksAndSnacks, bowl, base_it_items, top_it_items, finish_it_items, sauces, notes, comboProduct, comboSequences } = this.state
		let newBasket = {...basket}
		if (!isDefined(newBasket.items)) {
			newBasket = {items: []}
		}
		if (type === 'drinksAndSnacks') {

			let product = drinksAndSnacks[0]
			let sequences = product.sequences
			let s_items = []
			if (sequences){
				 s_items[0] = {
					 item_sku: sequences.sku,
					 item_price: sequences.price,
					 item_name: sequences.name
				 }
			}

			let item = {
				product: product.product[0].id,
				qty: product.quantity,
				name: product.product[0].order_name,
				price: product.product[0].price,
				sub_items: s_items,
				image: product.product[0].image,
				notes: product.notes,
				sku: s_items && s_items.length > 0 ? s_items[0].item_sku : product.product[0].sku
			}
			newBasket.items.push(item)
			this.setState({
				showProductModal: false,
				additional: false,
				is_byob: false,
				is_bowl: false,
				is_sauce: false,
				quantity: 1
			})
		} else if (type === 'bowl') {
			let findModifier = []
			let findCombos = []
			Object.keys(modifiers).forEach((modifier) => {
				bowl[0].product[0].modifiers.forEach(bowlModifier => {
					if (modifier === 'radio') {
						let item = bowlModifier.items.find(item => item.item_sku === modifiers.radio)
						if (item) {
							let sub_product = {
								item_sku: item.item_sku,
								item_price: item.price,
								item_name: item.description
							}
							findModifier.push(sub_product)
							// if (comboProduct && comboSequences) {
							// 	let comboSku = comboProduct.product[0].sku
							// 	sub_product.item_sku = comboSku
							// }
						}
					} else if (modifier === 'check') {
						modifiers.check.forEach(check => {
							let item = bowlModifier.items.find(item => item.item_sku === check)
							if (item) {
								let sub_product = {
									item_sku: item.item_sku,
									item_price: item.price,
									item_name: item.description
								}
								findModifier.push(sub_product)
							}
						})

					}
				})
			})

			if (comboProduct && comboSequences) {
				Object.keys(comboSequences).forEach((sequnce) => {
					let comboItem = comboProduct.product[0].sequences[sequnce].items.find(itm => itm.item_sku === comboSequences[sequnce])
					if (comboItem) {
						let combo_sub_product = {
							item_sku: comboItem.item_sku,
							item_price: comboItem.price,
							item_name: comboItem.description
						}
						findCombos.push(combo_sub_product)
					}
				})
			}

			bowl[0].sub_items = findModifier.concat(findCombos)
			bowl[0].notes = notes
			let product = bowl[0]
			let item = {
				product: product.product[0].id,
				qty: product.quantity || 1,
				name: product.product[0].order_name,
				price: product.product[0].price,
				sub_items: product.sub_items || [],
				image: product.product[0].image,
				notes: product.notes,
				sku: product.product[0].sku
			}
			if (comboProduct) {
				let comboProductPrice = comboProduct.product[0].price
				item.price = item.price + comboProductPrice
				item.price = comboProductPrice
				item.sku = comboProduct.product[0].sku
			}
			sauces.forEach((sauce) => {
				let souceItem = {
					item_sku: sauce.product[0].sku,
					item_price: sauce.product[0].price,
					item_name: sauce.product[0].order_name,
					item_qty: sauce.quantity
				}
				item.sub_items.push(souceItem)
			})

			newBasket.items.push(item)
			this.setState({
				showProductModal: false,
				additional: false,
				is_byob: false,
				is_bowl: false,
				is_sauce: false,
				quantity: 1,
				sauces: [],
				selectedCategory: {},
				selectedProduct: null,
				notes: ''
			})
			forwardTo(history, 'menu')
		} else {
			let categories = location.state.categories
			let cat = {}
			let byob_items = {}
			let byob_item = {}
			Object.keys(categories).forEach((key) => {
				let attributes = categories[key].category.attributes
				if (!attributes.is_sauce && !attributes.is_byob && !attributes.is_bowl) {
					cat = categories[key]
				}
				if (attributes.is_bowl) {
					Object.keys(categories[key].subCategories).forEach((subKey) => {
						let attrSub = categories[key].subCategories[subKey].subCategory.attributes ? categories[key].subCategories[subKey].subCategory.attributes.is_byob : null
						if (attrSub) {
							byob_item = categories[key].subCategories[subKey].products[0]
						}
					})

				}
			})
			byob_items.sub_items = []
			byob_items.notes = this.state.notes
			let findCombos = []
			if (comboProduct && comboSequences) {
				Object.keys(comboSequences).forEach((sequnce) => {
					let comboItem = comboProduct.product[0].sequences[sequnce].items.find(itm => itm.item_sku === comboSequences[sequnce])
					if (comboItem) {
						let combo_sub_product = {
							item_sku: comboItem.item_sku,
							item_price: comboItem.price,
							item_name: comboItem.description
						}
						findCombos.push(combo_sub_product)
					}
				})
			}

			base_it_items.forEach(item => {
				let sub_product = {
					item_sku: item.product[0].sku,
					item_price: item.product[0].price,
					item_name: item.product[0].order_name
				}
				byob_items.sub_items.push(sub_product)
			})

			top_it_items.forEach(item => {
				let sub_product = {
					item_sku: item.product[0].sku,
					item_price: item.product[0].price,
					item_name: item.product[0].order_name
				}
				byob_items.sub_items.push(sub_product)
				if (item.top_it_attributes) {
					let price = parseFloat(item.top_it_attributes.displayPrice) * 100
					let extra_item = {
						item_sku: item.top_it_attributes.comboSku,
						item_price: price,
						item_name: item.double ? 'Extra ' + item.product[0].published_name : 'Extra ' + item.top_it_attributes.comboText
					}
					byob_items.sub_items.push(extra_item)
				}
			})
			finish_it_items.forEach(item => {
				let sub_product = {
					item_sku: item.product[0].sku,
					item_price: item.product[0].price,
					item_name: item.product[0].order_name
				}
				byob_items.sub_items.push(sub_product)
			})

			byob_items.name = byob_item.product[0].order_name
			byob_items.product = byob_item.product[0].id
			byob_items.sku = byob_item.product[0].sku
			byob_items.price = byob_item.product[0].price
			byob_items.image = byob_item.product[0].image

			let product = byob_items
			let item = {
				product: product.product,
				qty: 1,
				name: product.name,
				price: product.price,
				sub_items: product.sub_items.concat(findCombos) || [],
				image: product.image,
				notes: product.notes,
				sku: product.sku
			}
			if (comboProduct) {
				let comboProductPrice = comboProduct.product[0].price
				item.price = comboProductPrice
				item.sku = comboProduct.product[0].sku
			}
			newBasket.items.push(item)
			this.setState({
				showProductModal: false,
				selectedCategory: cat,
				additional: true,
				is_byob: false,
				is_bowl: false,
				is_sauce: false,
				quantity: 1,
				base_it_items
			})
		}
		dispatch(addToBasket(newBasket))
	}

	notesModal = () => {
		const { __ } = this.props
		let notesModal =
			<div>
				{/* <div className='item-third-header'>
					<IonLabel size={20}>{ __('Special Notes and Allergies') }</IonLabel>
					<IonIcon icon={icons['close']} onClick={()=> { this.closeModal() }}/>
				</div> */}
				<IonToolbar color="white">
					<IonButtons slot="start">
						<IonButton button clear style={{ visibility: 'hidden' }}>
							<IonIcon slot="icon-only" icon={ close } />
						</IonButton>
					</IonButtons>

					<IonTitle className="ion-text-center modal-title item-name">{ __('Special Notes and Allergies') }</IonTitle>

					<IonButtons slot="end">
						<IonButton button clear onClick={() => this.closeModal()}>
							<IonIcon slot="icon-only" icon={ close } />
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<div className='item-actions'>
					<IonInput type='text' value={this.state.notes} placeholder={ __('Enter notes here') } onIonChange={(e)=> {this.setState({ notes: e.target.value})}}></IonInput>
				</div>
				<IonButton expand='full' disabled={false} className='select-base-button' onClick={() => { this.add() }}> { __('Add to Basket') }</IonButton>
			</div>

		this.setState({ showProductModal: true, selectedProduct: notesModal })
	}

	closeModal = (cb = null) => {
		this.setState({
			showProductModal: false,
			additional: false,
			is_byob: false,
			is_bowl: false,
			is_sauce: false,
			quantity: 1,
			sauces: [],
			selectedCategory: {},
			selectedProduct: null,
			notes: ''
		})
	}

	closeAlert = () => {
		this.setState({ showAlert: false })
	}

	setRefs = (ref, subCategory, key) => {
		if (!isDefined(this.bowlRefs[key])) {
			this.bowlRefs[key] = {}
		}

		this.bowlRefs[key] = ref
	}

	scrollToSection = (section) => {
		if (!this.bowlRefs) { return }
		Object.keys(this.bowlRefs).forEach((key, i) => {
			if (section === key) {
				this.setState({sectionIndex: key}, () => {
					this.bowlRefs[key].scrollIntoView({behavior: 'smooth'})
				})
			}
		})
	}

    displayItems = () => {
    	const { currency, basket } = this.props
    	const { categories } = this.state
    	return (Object.keys(categories ? categories :{}) || []).
    		sort((a, b) => categories[a].category.sort_order > categories[b].category.sort_order ? 1 : -1).
    		map((key, i) => {
    			if (categories[key].category.attributes.is_hidden || categories[key].category.attributes.is_byob) {
    				return null
    			}
    			const subCategory = categories[key] && categories[key].subCategories ? categories[key].subCategories : {}
    			return Object.keys(subCategory || []).
    				sort((a, b) => subCategory[a].subCategory.sort_order > subCategory[b].subCategory.sort_order ? 1 : -1).
    				map((subKey, i) => {
    					if (!subCategory[subKey].subCategory.attributes.is_byob) {
    						return (
    							<div className='desktop-categories-wrapper' ref={(ref) => this.setRefs(ref, subCategory, subKey)} key={i}>
    								<div className='subcategory-name'>{subKey.toUpperCase()}</div>
    								<div className='web-menu'>
    									{subCategory[subKey].products.
    										sort((a, b) => a.product[0].sort_order > b.product[0].sort_order ? -1 : 1).
    										map((product, i) => {
    											const item = product.product[0]
    											const itemPrice = price(item.price)
    											if (item.is_hidden) {
    												return null
    											}
    											let selected = false
    											if (basket && basket.items.length > 0) {
    												selected = basket.items.find(product => product.product === item.id ? true : false)
    											}
    											return (
    												<div key={i} className={selected ? 'web-menu-item in-basket' :'web-menu-item'}
    													onClick={() => {
    														this.setCategoryType(categories[key])
    														if (subCategory[subKey].subCategory.attributes.display_mode === 'vertical') {
    															this.displayProduct(product)
    														} else {
    															this.pickItems(product, '')
    														}
    													}}
    												>
    													<div className={selected ? 'web-menu-item-image-selected' : 'web-menu-item-image'} style={{backgroundImage: `url('${s3Url + item.image}')`}}>
    														{selected ?
    															<div className='web-selected-item-checkmark'>
    																<IonIcon color='white' icon={icons.checkmark}/>
    															</div>
													 : null }
    														<div className='web-hover-item'>
    															<div className='web-hover-item-info'>
    																<p>{item.order_name}</p>
    																<p>{ itemPrice ? '+' + this.formatPrice(itemPrice) : ''}</p>
    															</div>
    															<IonIcon color='white' icon={icons.checkmark}/>
    														</div>
    													</div>
    													<div className='web-menu-item-info'>
    														<div className='web-menu-item-name'>{item.order_name}</div>
    														<div className='web-menu-item-price'>{ itemPrice ? this.formatPrice(itemPrice): ''}</div>
    													</div>
    													{
    														item.description ?
    															<div className='web-menu-item-description'>{item.description}</div>
    															: null
    													}
    												</div>
    											)
    										})}
    								</div>
    							</div>
    						)
    					} else {
    						return null
    					}
    				})
    		})
    }

	onForwardHandler = () => {
		const { byob_type } = this.state
		let b_type = ''
		if (byob_type === 'is_base_it') {
			b_type = 'is_top_it'
		} else if (byob_type === 'is_top_it') {
			b_type = 'is_finish_it'
		}
		this.setState({ byob_type: b_type })
	}

	onBackHandler = () => {
		const { byob_type } = this.state
		let b_type = ''
		if (byob_type === 'is_finish_it') {
			b_type = 'is_top_it'
		} else if (byob_type === 'is_top_it') {
			b_type = 'is_base_it'
		}
		this.setState({ byob_type: b_type })
	}

	createCategory = (menu) => {
		if (!isEmptyObject(menu)) {
			const categories = {}
			menu.available_products.forEach((product, index) => {
				const category = product.product[0].category
				const subCategory = product.product[0].sub_category
				if (!categories[category.name]) {
					categories[category.name] = {products: [], category, subCategories: {}}
				}
				if (!categories[category.name].subCategories[subCategory.name]) {
					categories[category.name].subCategories[subCategory.name] = {products: [], subCategory}
				}
				categories[category.name].subCategories[subCategory.name].products.push(product)
				categories[category.name].category = category
				categories[category.name].products.push(product)
			})
			return categories
		}
	}

	setByobItem = (item, category, flag) => {
		this.closeModal()
		const { history, dispatch } = this.props
		const { categories } = this.state
		const size = item.product[0].attributes && item.product[0].attributes.size ? item.product[0].attributes.size : null
		dispatch(selectCategory(category))
		if (flag) {
			forwardTo(history, '/buildyourown', { 'categories': categories, 'byob_item': item, 'size': size })
		}
	}

	// selectCategory = (category, flag) => {
	// 	const { history, dispatch } = this.props
	// 	const { categories } = this.state
	// 	dispatch(selectCategory(category))
	// 	if(flag) {
	// 		forwardTo(history, '/buildyourown', { 'categories': categories })
	// 	}
	// }

		selectCategory = (category, flag) => {
			const { history, dispatch, currency, __ } = this.props
			const { categories } = this.state
			const is_byob = category.category.attributes.is_byob ? category.category.attributes.is_byob : false
			let byobSizeProducts = {}
			Object.keys(categories).forEach((key) => {
				let attributes = categories[key].category.attributes
				if (attributes.is_bowl) {
					Object.keys(categories[key].subCategories).forEach((subKey) => {
						let attrSub = categories[key].subCategories[subKey].subCategory.attributes ? categories[key].subCategories[subKey].subCategory.attributes.is_byob : null
						if (attrSub) {
							byobSizeProducts = categories[key].subCategories[subKey]
						}
					})
				}
			})
			if (!isEmptyObject(byobSizeProducts) && byobSizeProducts.products.length > 1 && is_byob) {
				let modalContent =
							<div>
								{/* <div className='item-third-header'>
											<IonLabel size={20}>{__('Choose size')}</IonLabel>
											<IonIcon icon={icons['close']} onClick={()=> { this.closeModal() }}/>
									</div> */}
								<IonToolbar color="white">
									<IonButtons slot="start">
										<IonButton button clear style={{ visibility: 'hidden' }}>
											<IonIcon slot="icon-only" icon={ close } />
										</IonButton>
									</IonButtons>

									<IonTitle className="ion-text-center modal-title item-name">{ __('Choose size') }</IonTitle>

									<IonButtons slot="end">
										<IonButton button clear onClick={() => this.closeModal()}>
											<IonIcon slot="icon-only" icon={ close } />
										</IonButton>
									</IonButtons>
								</IonToolbar>
								<IonList className='item-third-list'>
									{ byobSizeProducts.products.map((product, i) => {
										const name = product.product[0].attributes.size.toUpperCase()
										const priceWithCurrency = this.formatPrice(price(product.product[0].price))
										return (
											<IonItem lines="none" className="choose-size" key={i} onClick={() => { this.setByobItem(product, category, flag)}}>
												<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }} slot="start">
													<IonLabel>{ __(name) }</IonLabel> &nbsp;
												</div>
												<IonLabel slot='end'>{priceWithCurrency}</IonLabel>
											</IonItem>
										)
									})}
								</IonList>
							</div>

				this.setState({ selectedProduct: modalContent, showProductModal: true })
			} else if (!isEmptyObject(byobSizeProducts) && byobSizeProducts.products.length === 1 && is_byob) {
				this.setByobItem(byobSizeProducts.products[0], category, flag)
			} else {
				dispatch(selectCategory(category))
				forwardTo(history, '/buildyourown', { 'categories': categories })
			}
		}

		setCategoryType = (selectedCategory) => {
			const is_byob = selectedCategory && selectedCategory.category ? selectedCategory.category.attributes.is_byob : false
			const is_bowl = selectedCategory && selectedCategory.category ? selectedCategory.category.attributes.is_bowl : false
			const is_sauce = selectedCategory && selectedCategory.category ? selectedCategory.category.attributes.is_sauce : false
			this.setState({ selectedCategory, is_byob, is_bowl, is_sauce, additional: false })
		}

		showSaucesModal = (subCategory, index, byob) => {
			const { byob_type, base_it_items, top_it_items, finish_it_items, is_sauce, is_byob, sauces, is_bowl } = this.state
			const { __, currency, basket } = this.props
			const selectedProduct =
				<React.Fragment key={index}>
					<div className={'sauces-content' + byob} key={index}>
						<IonToolbar color="white">
							<IonButtons slot="start">
								<IonButton button clear style={{ visibility: 'hidden' }}>
									<IonIcon slot="icon-only" icon={ close } />
								</IonButton>
							</IonButtons>

							<IonTitle className="ion-text-center modal-title item-name">{ __('Add a sauce?') }</IonTitle>

							<IonButtons slot="end">
								<IonButton button clear onClick={() => this.closeModal()}>
									<IonIcon slot="icon-only" icon={ close } />
								</IonButton>
							</IonButtons>
						</IonToolbar>
						<div className='desktop-sauce-wrapper'>
							{/* <div className={'sauce category-title' + byob}>
								{ __('Add a sauce?') }
								<IonIcon icon={icons['close']} onClick={()=> { this.closeModal() }}/>
							</div> */}

							<div className='sauces scrollable-y'>
								{subCategory.products.
									sort((a, b) => a.product[0].sort_order > b.product[0].sort_order ? 1 : -1).
									map((product, i) => {
										const item = product.product[0]
										const itemPrice = price(item.price)
										let selected = false

										if (byob_type === 'is_finish_it') {
											selected = finish_it_items.find(product => product.product[0].id === item.id ? true : false)
										} else if (byob_type === 'is_top_it') {
											selected = top_it_items.find(product => product.product[0].id === item.id ? true : false)
										} else {
											selected = base_it_items.find(product => product.product[0].id === item.id ? true : false)
										}
										if (sauces.length > 0) {
											selected = sauces.find(product => product.product[0].id === item.id ? true : false)
										}
										if (basket && basket.items.length > 0 && !is_sauce && !is_byob && !is_bowl ) {
											selected = basket.items.find(product => product.product === item.id ? true : false)
										}
										return (
											<div
												key={item.id}
												onClick={() => {
													this.pickItems(product, byob)
												}}
												className='sauces-item'>
												<div className={selected ? 'sauces-item-slide-image-selected' : 'sauces-item-slide-image'} style={{backgroundImage: `url('${s3Url + item.image}')`}}>
													{/* <img alt='' style={{ opacity: selected ? 0.5 : 1 }} src={s3Url + item.image} /> */}
													{selected ?
														<div className='sauces-selected-item-checkmark'>
															<IonIcon color='white' icon={icons.checkmark}/>
														</div>
													 : null }
												</div>
												<p className='sauces-item-slide-name'>{item.order_name}</p>
												<p className='sauces-item-slide-price'>{ itemPrice ? this.formatPrice(itemPrice) : '' }</p>
											</div>
										)
									})}
							</div>
						</div>
						<IonButton
							expand="full"
							disabled={byob_type !== 'is_finish_it' && is_byob ? true : false}
							color="tertiary"
							className='sauces-add-sauce'
							onClick={() => {
								if (is_sauce) {
									this.add('bowl')
								}
							}}>
							{ __(is_sauce && sauces.length < 1 ? __('No thanks') : sauces.length > 0 ? __('Add') : null)}
						</IonButton>
					</div>
				</React.Fragment>

			this.setState({ selectedProduct })
		}

	selectBowlSizeItem = (option, i, product) => {
		let foundedProduct = this.findAvailableProductBySku(option.sku)
		foundedProduct.product[0]['sku_choices'] = product.product[0].sku_choices
		foundedProduct.product[0]['has_sku_choice'] = foundedProduct.product[0].sku_choices.length === 0 ? product.product[0].has_sku_choice : foundedProduct.product[0].sku_choices
		foundedProduct.product[0]['modifiers'] = foundedProduct.product[0].modifiers.length === 0 ?  product.product[0].modifiers : foundedProduct.product[0].modifiers
		foundedProduct.product[0]['order_name'] = option.description
		this.setState({bowlSizeSelectedItem: i, bowl: foundedProduct}, () => {
			this.displayProduct(foundedProduct)
		})
	}

	selectBowlSizeOption = (product) => {
		const { currency } = this.props
		const { bowlSizeSelectedItem } = this.state
		const hasSku = product.product[0].has_sku_choice
		const itemHasOptions = product.product[0].sku_choices.length > 0 && product.product[0].sku_choices[0].options ? product.product[0].sku_choices[0].options : []
		if (hasSku && itemHasOptions.length > 0) {
			const skuOptionChoices = product.product[0].sku_choices[0].options
			return (
				<IonRadioGroup onIonChange={(e) => {
					const i = e.detail.value
					const option = skuOptionChoices[i]
					this.selectBowlSizeItem(option, i, product )}} value={ bowlSizeSelectedItem }>
					<IonList>
						{skuOptionChoices.map((option, i) => {
							const itemPrice = price(option.price)
							const name = option.name
							return (
								<IonItem key={i}>
									<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}><IonRadio slot="start" value={i} /></div>
									<IonLabel>{name}</IonLabel>
									{currency === 'gbp' ?
										<p>{itemPrice ? currencySign(currency) : ''}{ itemPrice }</p>:
										<p>{ itemPrice }{itemPrice ? currencySign(currency) : ''}</p>
									}
								</IonItem>
							)
						})}
					</IonList>
				</IonRadioGroup>
			)
		} else {
			return null
		}
	}

	translateWeekDays = (opening_times) => {
		const { __ } = this.props

		opening_times = opening_times.replace('Monday', __('Monday'))
		opening_times = opening_times.replace('monday', __('monday'))
		opening_times = opening_times.replace('Tuesday', __('Tuesday'))
		opening_times = opening_times.replace('tuesday', __('tuesday'))
		opening_times = opening_times.replace('Wednesday', __('Wednesday'))
		opening_times = opening_times.replace('wednesday', __('wednesday'))
		opening_times = opening_times.replace('Thursday', __('Thursday'))
		opening_times = opening_times.replace('thursday', __('thursday'))
		opening_times = opening_times.replace('Friday', __('Friday'))
		opening_times = opening_times.replace('friday', __('friday'))
		opening_times = opening_times.replace('Saturday', __('Saturday'))
		opening_times = opening_times.replace('saturday', __('saturday'))
		opening_times = opening_times.replace('Sunday', __('Sunday'))
		opening_times = opening_times.replace('sunday', __('sunday'))

		return opening_times
	}

	render() {
		const { __, data, history, basket, restaurant } = this.props
		const { showProductModal, byob_type, is_byob, additional, typeOfAlert, showAlert, top_it_items, categories, default_byob_item, sectionIndex } = this.state
		const { loading } = data
		let defaultByob = default_byob_item && default_byob_item.product ? default_byob_item.product[0] : {}

		return (
			<Layout
				headerTitle={ __('Menu') }
				subHeader={categories ? categories : {}}
				subHeaderByob={ __('Build your own bowl') }
				subHeaderSelectCategory={this.selectCategory}
				scrollToSection={this.scrollToSection}
				sectionIndex={sectionIndex}
				color="transparent"
				contentClassName="animated-gif-01"
				hideBack={additional ? true : false}
				hideForward={ byob_type !== 'is_finish_it' && is_byob && top_it_items.length > 0 ? false : true }
				onForwardHandler={byob_type !== 'is_finish_it' && is_byob ? this.onForwardHandler : null}
				onBackHandler={byob_type !== 'is_base_it' ? this.onBackHandler : null}
				noPadding={isDesktop() ? true : false}
			>
				<SimpleLoader loading={ loading }>
					<div className='desktop-menu-title '>
						<h1>{ __('Menu')}</h1>
						<div>
							<p><FallbackText>{ __(restaurant.name) }</FallbackText></p>
							<p><FallbackText>{ __(restaurant.address) }</FallbackText></p>
							{ restaurant.opening_times ? <p><Nl2Br>{ this.translateWeekDays(restaurant.opening_times)}</Nl2Br></p> : null }
						</div>
					</div>
					<div className="byob-webmenu">
						{
							!isEmptyObject(defaultByob) ?
								<>
									<div className="byob-image-webmenu" style={{backgroundImage: `url('${require('../../../assets/images/buildabowl.png')}')`}}></div>
									<div className="byob-info-webmenu">
										<h2>{ __('Build your own bowl')} </h2>
										<p>{ __('Can\'t decide? Build your perfect poke bowl with our customer bowl creator') + '.' }</p>
										{/* <p>+ {defaultByob.price ? currencySign(currency) : ''}{price(defaultByob.price)}</p> */}
										{
											(Object.keys(categories ? categories : {}) || []).
												map((key, i) => {
													const is_byob = categories[key].category.attributes.is_byob
													if (!is_byob) { return null }
													return <IonButton key={i} expand='full' onClick={() => this.selectCategory(categories[key], true)}>{ __('Get building') }</IonButton>
												})
										}
									</div>
								</>
								:
								null
						}
					</div>
					{ this.displayItems() }
					{ basket && basket.items && basket.items.length > 0 ? <div className='view-basket-button-webmenu'><IonButton expand='full' onClick={() => forwardTo(history, 'cart')}>{ __('View Basket') }</IonButton></div> :null }
					<Modal
						showProductModal={showProductModal}
						component={this.state.selectedProduct}
						className="modal-auto-height"
						closeModal={ this.closeModal } />
					<Alert type={typeOfAlert} showAlert={showAlert} closeAlert={ this.closeAlert } __={ __ }/>
				</SimpleLoader>
			</Layout>
		)
	}
}

const stateToProps = (state) => {
	const { data, order } = state
	return {
		apiMethod: 'productAvailability',
		restaurantId: order.restaurantId,
		data,
		order,
		selectedCategory: order.selectedCategory,
		basket: order.basket,
		currency: order.currency,
		restaurant: order.restaurant || {},
		collectionTime: order.time
	}
}

export default connect(stateToProps)(withRouter(withTranslation(WebMenu)))
