import {
	SET_LOCATION_AND_TIME,
	SELECT_CATEGORY,
	ADD_TO_BASKET,
	CLEAR_BASKET,
	SET_MY_ORDERS,
	SET_ORDER_DETAILS,
	ADD_VOUCHER,
	SET_DISCOUNTS,
	SET_ORDERS_PROP
} from './constants'
import moment from '../../lib/moment'
import { isDefined } from '../../lib/utils'

let initialState = {
	restaurantId: null,
	time: null,
	selectedCategory: {},
	basket: null,
	restaurant: null,
	currency: '',
	myOrders: [],
	orderDetails: {}
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case SET_LOCATION_AND_TIME: {
		const restaurantId = action.restaurantId
		const time = action.time
		const restaurant = action.restaurant
		const currency = restaurant.currency
		let collection_time = moment(time).unix()
		return { ...state, restaurantId, time: collection_time, restaurant, currency }
	}
	case SELECT_CATEGORY: {
		const category = action.category
		return { ...state, selectedCategory: category }
	}
	case ADD_TO_BASKET: {
		const data = action.data
		const restaurant = state.restaurant
		data.restaurant = {
			index: restaurant.id,
			name: restaurant.name,
			business_location_id: restaurant.business_location_id,
			address: restaurant.address,
			slot_len: 1,
			show_discount: restaurant.show_discount
		}
		data.collection_time = state.time
		data.discount_total = 0
		data.item_count = data.items ? data.items.length : 0
		data.applied_vouchers= []
		return { ...state, basket: data }
	}
	case SET_MY_ORDERS:
		return { ...state, myOrders: action.value }
	case SET_ORDER_DETAILS:
		return { ...state, orderDetails: action.data }
	case CLEAR_BASKET: {
		return { ...state, basket: null }
	}
	case ADD_VOUCHER: {
		const basket = {...state.basket}
		const voucher = action.voucher
		if (voucher) {
			basket.applied_vouchers = [voucher]
		} else {
			basket.applied_vouchers = []
		}
		return { ...state, basket: basket }
	}
	case SET_DISCOUNTS: {
		const basket = {...state.basket}
		const discounts = action.discounts
		basket.vouchers = discounts
		return { ...state, basket: basket }
	}
	case SET_ORDERS_PROP:
		return { ...state, [action.key]: isDefined(action.merge) && action.merge ? { ...state[action.key], ...action.value } : action.value }

	default:
		return state
	}
}

export default reducer
