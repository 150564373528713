import React from 'react'
import { IonAlert } from '@ionic/react'
import { withTranslation } from '../../lib/translate'

class Alert extends React.Component {

	render(){
		const { showAlert, closeAlert, type, clearBasket, removeAppliedVoucher, __ } = this.props
		const message = 'You have item(s) in your basket. Starting a new order will clear your order. Are you sure you wish to continue?'
		if (type && type === 'select') {
			return (
				<IonAlert
					isOpen={showAlert}
					onDidDismiss={() => { closeAlert() }}
					header={__('Are you sure?')}
					message={__(message)}
					buttons={[
						{
							text: __('No'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => {
								closeAlert()
							}
						},
						{
							text: __('Yes'),
							handler: () => {
								clearBasket()
							}
						}
					]}
				/>
			)
		} else if (type && type === 'voucher') {
			return (
				<IonAlert
					isOpen={showAlert}
					onDidDismiss={() => { closeAlert() }}
					header={__('Remove Voucher')}
					message={__('Remove from your basket')}
					buttons={[
						{
					  text: __('No'),
					  role: 'cancel',
					  cssClass: 'secondary',
					  handler: () => {
								closeAlert()
					  }
						},
						{
					  text: __('Yes'),
					  handler: () => {
								removeAppliedVoucher()
					  }
						}
				  ]}
				/>
			)
		} else {
			let errorMessage = 'Action required: Select Base'
			if(type){
				switch(type){
					case 'drink':
						errorMessage =  'Action required: Select Drink'
						break;
					case 'dessert':
						errorMessage =  'Action required: Select Dessert'
						break;
					case 'drinkAndDessert':
						errorMessage = 'Action required: Select Drink and Dessert'
						break;
				}
			}
			return (
				<IonAlert
					isOpen={showAlert}
					onDidDismiss={() => { closeAlert() }}
					header={__('Alert')}
					message={__(errorMessage)}
					buttons={[__('OK')]}
				/>
			)
		}
	}
}

export default withTranslation(Alert)
