import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonMenuToggle, IonButtons, IonButton, IonIcon, IonLabel } from '@ionic/react'
import appConfig from '../../appConfig.js'
import navConfig from '../../navConfig.js'
import { logout } from '../../store/actions'
import { getDefaultRoute, isDesktop } from '../../lib/utils'
import { withTranslation } from '../../lib/translate'


import Icon from '../../components/icon'
import { DesktopView } from '../desktopView/index.js'
import { MobileView } from '../mobileView/index.js'

require('./index.css')
if (isDesktop()){
	require('./desktop.css')
}else{
	require('./mobile.css')
}


const NavItem = withTranslation(({ history, item, hideIcon, handleLogout, __ }) => {
	return (
		<>
		<DesktopView>
			<IonMenuToggle key={ item.path } auto-hide='false'>
				<IonItem className='nav-item' color='primary' href={ item.path }>
					{ hideIcon ? null : item.iconName ? <Icon iconName={ item.iconName }/> : <Icon className="nav-icon" slot="start" icon={ item.icon }/> }
					<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
						<IonLabel className='nav-label'>{ item.label }</IonLabel>
					</div>
				</IonItem>
			</IonMenuToggle>
		</DesktopView>
		<MobileView>
			<IonMenuToggle key={ item.path } auto-hide="false">
				<IonItem className="nav-item" color="primary" button onClick={() => item.fn === 'logout' ? handleLogout() : history.push(item.path)}>
					{ hideIcon ? null : item.iconName ? <Icon iconName={ item.iconName }/> : <Icon className="nav-icon" slot="start" icon={ item.icon }/> }
					<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
						<IonLabel className="nav-label">{ __(item.label)}</IonLabel>
					</div>
				</IonItem>
			</IonMenuToggle>
		</MobileView>
		</>
	)
})

class Drawer extends React.Component {
	constructor(props){
		super(props)
		this.state = {}
		this.handleLogout = this.handleLogout.bind(this)
	}

	handleLogout() {
		this.props.dispatch(logout())
		const defaultRoute = getDefaultRoute()
		this.props.history.push(defaultRoute.path)
	}

	render (){
		const { history, auth } = this.props
		const { loggedIn } = auth
		return (
			<IonMenu className="drawer-menu" side="start" type="overlay" contentId="main">
				<IonHeader color="primary">
					<IonToolbar color='primary'>
						<IonTitle>{ appConfig.theme.nav.label }</IonTitle>
						<IonButtons slot="end">
							<IonMenuToggle>
								<IonButton button clear>
									<IonIcon slot="icon-only" icon="close"/>
								</IonButton>
							</IonMenuToggle>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<DesktopView>
					<IonContent color="primary">
						<div className="contrast-separator contrast-separator-mini"/>
						<IonList lines="none">
							{ navConfig.webRoutes.map(item =>
								<NavItem key={ item.path } history={ history } item={ item }/>
							)}
						</IonList>
						 <IonList lines="none">
							<NavItem history={ history } handleLogout={ this.handleLogout } item={ navConfig.authRoutes.find(item => item.fn === (loggedIn ? 'logout' : 'login'))}/>
						</IonList>
					</IonContent>
				</DesktopView>
				<MobileView>
					<IonContent color="primary">
						<div className="contrast-separator contrast-separator-mini"/>
						<IonList lines="none">
							{ navConfig.routes.filter(route => !!route.path && !route.notInDrawer).map(item =>
								<NavItem key={ item.path } history={ history } item={ item }/>
							)}
						</IonList>
						<IonList lines="none">
							<NavItem history={ history } handleLogout={ this.handleLogout } item={ navConfig.authRoutes.find(item => item.fn === (loggedIn ? 'logout' : 'login'))}/>
						</IonList>
						<div className="contrast-separator contrast-separator-mini"/>
						<IonList lines="none">
							{ navConfig.additionalRoutes.filter(route => !!route.path && !route.notInDrawer).map(item =>
								<NavItem key={ item.path } history={ history } item={ item } hideIcon={ true }/>
							)}
						</IonList>
					</IonContent>
				</MobileView>
			</IonMenu>
		)
	}
}

const stateToProps = (state) => {
	const { auth } = state.auth
	return {
		auth
	}
}

export default connect(stateToProps)(withRouter(Drawer))
