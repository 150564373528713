import React, { Component } from 'react'
import { IonButton, IonGrid, IonRow, IonCol } from '@ionic/react'
import { withTranslation } from '../../lib/translate'

class CardsList extends Component {
	render() {
		const { __, cards, handlePay, handleRemove } = this.props

		return (
			<div className="cards-list">
				<h2 style={{ textAlign: 'center', marginTop: 0 }}>{ __('Your saved cards') }</h2>
				<IonGrid>
					{ cards && cards.length !== 0 ? cards.map(card => {
						const { name, brand, last4, exp, card_token } = card
						return (
							<div key={ card_token }>
								<IonRow>
									<IonCol size="12" className="card_label">
										<strong>{ name }</strong>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol size="12" className="card_label">
										{ brand }, **** **** **** { last4 }, exp. { exp }
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol size="6"><IonButton expand="block" color="secondary" onClick={ () => handlePay(card_token) }>{ __('Pay') }</IonButton></IonCol>
									<IonCol size="6"><IonButton expand="block" color="tertiary" onClick={ () => handleRemove(card_token) }>{ __('Remove') }</IonButton></IonCol>
								</IonRow>
							</div>
						)
					}) : <div>{ __('No saved cards') }</div> }
				</IonGrid>
			</div>
		)
	}
}

export default withTranslation(CardsList)
