export const SET_LOCATION_AND_TIME = 'SET_LOCATION_AND_TIME'
export const SELECT_CATEGORY = 'SELECT_CATEGORY'
export const ADD_TO_BASKET = 'ADD_TO_BASKET'
export const CLEAR_BASKET = 'CLEAR_BASKET'
export const GET_MY_ORDERS = 'GET_MY_ORDERS'
export const SET_MY_ORDERS = 'SET_MY_ORDERS'
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS'
export const CREATE_ORDER = 'CREATE_ORDER'
export const ADD_VOUCHER = 'ADD_VOUCHER'
export const SET_DISCOUNTS = 'SET_DISCOUNTS'
export const ADD_SCANNED_CARD = 'ADD_SCANNED_CARD'
export const REMOVE_PAYMENT_CARD = 'REMOVE_PAYMENT_CARD'
export const LIST_PAYMENT_CARDS = 'LIST_PAYMENT_CARDS'
export const SET_ORDERS_PROP = 'SET_ORDERS_PROP'
