import { take, call, put, select } from 'redux-saga/effects'
import { isDefined } from '../../lib/utils'
import asyncAuth from '../../lib/asyncAuth'
import asyncStorage from '../../lib/asyncStorage'
import api, { createAxiosInstance } from '../../lib/api'
import { translate } from '../../lib/translate' // raw translate, second param (locale) mandatory!
import {
	SENDING_REQUEST,
	REQUEST_ERROR,
	SHOW_TOAST,
	SET_MODAL,
	INIT_FIREBASE_DATABASE
} from '../constants'
import { SET_COMMON_PROP } from '../common/constants'
import {
	LOGIN_REQUEST,
	REGISTER_REQUEST,
	LOGOUT,
	SEND_FIREBASE_TOKEN,
	SET_PROFILE_PROP,
	GET_PROFILE,
	UPDATE_PROFILE,
	SEND_FEEDBACK,
	SEND_REFER,
	SEND_VOUCHER_CODE,
	GET_VOUCHERS,
	SET_VOUCHERS,
	MOBILE_CHECK_SMS,
	SEND_MOBILE,
	RESTORE_AUTH
} from './constants'
import { loading, saveFcmToken } from '../common/sagas'
import { getPaymentCards } from '../actions'

export const authorize = function* (data) {
	const authStore = yield select(state => state.auth)
	if (authStore.loggedIn){ return authStore }
	if (data.isRegistering) {
		data.isRegistering = undefined
		return yield call(api.register, data )
	} else {
		data.isRegistering = undefined
		const { username, password, sysLocale } = data
		return yield call(api.login, username, password, sysLocale)
	}
}

export const logout = function* () {
	yield put({ type: SENDING_REQUEST, sending: true })
	try {
		const response = yield call(asyncAuth.logout)
		yield put({ type: SENDING_REQUEST, sending: false })

		return response
	} catch (error) {
		yield put({ type: REQUEST_ERROR, error: error.message })
		yield put({ type: SENDING_REQUEST, sending: false })
	}
}

export const loginFlow = function* () {
	while (true) {
		const { username, password, sysLocale } = yield take(LOGIN_REQUEST)
		yield call(loading, function *() {
			const response = yield call(authorize, { username, password, sysLocale, isRegistering: false })
			const { token } = response.data
			let profile = response.data.profile

			yield call(createAxiosInstance, token)
			if (!isDefined(profile)) {
				//get user profile
				profile = yield call(getProfileSaga)
			}

			yield call(asyncStorage.setItem, 'token', token)
			yield call(asyncStorage.setItem, 'profile', JSON.stringify(profile))

			yield put({ type: SET_PROFILE_PROP, key: 'auth', value: { loggedIn: true, token } })
			yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile || {} })

			yield call(saveFcmToken)
			yield call(postLogingFlow)

			//forwardTo(authRoutes.protectedLanding)
		})
	}
}

export const restoreAuthFlow = function* () {
	while (true) {
		yield take(RESTORE_AUTH)

		const token = yield call(asyncStorage.getItem, 'token')
		const profile = yield call(asyncStorage.getItem, 'profile')
		if (isDefined(token) && isDefined(profile)) {
			yield put({ type: SET_PROFILE_PROP, key: 'auth', value: { loggedIn: true, token } })
			yield put({ type: SET_PROFILE_PROP, key: 'profile', value: JSON.parse(profile) })
			yield call(saveFcmToken)
			yield call(postLogingFlow)
		}
	}
}

export const logoutFlow = function* () {
	while (true) {
		yield take(LOGOUT)
		yield call(asyncStorage.removeItem, 'token')
		yield call(asyncStorage.removeItem, 'profile')
		yield put({ type: SET_PROFILE_PROP, key: 'auth', value: {} })
		yield put({ type: SET_PROFILE_PROP, key: 'profile', value: {} })
		//forwardTo(authRoutes.unprotectedLanding)
	}
}

const postLogingFlow = function* () {
	yield put({ type: GET_VOUCHERS })
	yield put(getPaymentCards())

	//add firebase listeners for database
	yield put({ type: INIT_FIREBASE_DATABASE })
}

export const registerFlow = function* () {
	while (true) {
		const action = yield take(REGISTER_REQUEST)
		yield call(loading, function *() {
			const response = yield call(authorize, { ...action.data, isRegistering: true })
			yield put({ type: LOGIN_REQUEST, ...action.data })
			if (!response.error) {
				yield put({ type: SHOW_TOAST, message: translate('Registered successfully'), toastType: 'success' })
			}
		})
	}
}

const getProfileSaga = function* (){
	const response = yield call(api.getProfile)
	const profile = response.data.profile
	yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile })
	yield call(asyncStorage.setItem, 'profile', JSON.stringify(profile))
	return profile
}

export const getProfileFlow = function* () {
	while (true) {
		const { skipLoading } = yield take(GET_PROFILE)
		if (skipLoading) {
			yield call(getProfileSaga)
		} else {
			yield call(loading, function *() {
				yield call(getProfileSaga)
			})
		}
	}
}

export const updateProfileFlow = function* () {
	while (true) {
		const action = yield take(UPDATE_PROFILE)
		yield call(loading, function *() {
			const response = yield call(api.updateProfile, action.data)
			const profile = response.data.profile
			yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile, merge: true })
			yield call(asyncStorage.setItem, 'profile', JSON.stringify(profile))
			localStorage.setItem("language", profile.locale);
			window.location.reload(false);
			if (!response.error) {
				yield put({ type: SHOW_TOAST, message: translate('Profile updated successfully', profile.locale), toastType: 'success' })
			}
		})
	}
}

export const sendFeedback = function* () {
	while (true) {
		const action = yield take(SEND_FEEDBACK)
		const profile = yield select(state => state.auth ? state.auth.profile : {})
		yield call(loading, function *() {
			const response = yield call(api.sendFeedback, action.data)
			//console.log('!!!!!',profile)
			if (!response.error) {
				yield put({ type: SHOW_TOAST, message: translate('Feedback sent successfully', profile.locale), toastType: 'success' })
			}
		})
	}
}

export const sendRefer = function* () {
	while (true) {
		const action = yield take(SEND_REFER)
		const profile = yield select(state => state.auth ? state.auth.profile : {})
		yield call(loading, function *() {
			const response = yield call(api.sendRefer, action.data)
			if (!response.error) {
				yield put({ type: SHOW_TOAST, message: translate('Refer to Friend sent successfully', profile.locale), toastType: 'success' })
			}
		})
	}
}

export const sendVoucherCode = function* () {
	while (true) {
		const action = yield take(SEND_VOUCHER_CODE)
		const profile = yield select(state => state.auth ? state.auth.profile : {})
		yield call(loading, function *() {
			const code = action.data.code
			yield put({ type: SET_COMMON_PROP, key: 'voucherCode', value: code })
			const response = yield call(api.sendCode, action.data)
			yield put({ type: SET_COMMON_PROP, key: 'voucherCode', value: null })
			if (!response.error && !response.Error) {
				yield put({ type: SHOW_TOAST, message: translate('Your new voucher will show here', profile.locale), toastType: 'success' })
				yield put({ type: GET_VOUCHERS })
			}
		})
	}
}

export const getVouchers = function* () {
	while (true) {
		yield take(GET_VOUCHERS)
		const response = yield call(api.getVouchers)
		if (!response.error) {
			yield put({ type: SET_VOUCHERS, value: response.data })
		}
	}
}

export const sendMobile = function* () {
	while (true) {
		const action = yield take(SEND_MOBILE)
		yield call(loading, function *() {
			const response = yield call(api.sendMobile, action.data)
			if (!response.error && !response.Error) {
				yield put({ type: SET_MODAL, modal: 'mobileCheckSms', value: true })
			}
		})
	}
}

export const mobileCheckSms = function* () {
	while (true) {
		const action = yield take(MOBILE_CHECK_SMS)
		const profile = yield select(state => state.auth ? state.auth.profile : {})
		yield call(loading, function *() {
			const response = yield call(api.mobileCheckSms, action.data)
			const voucherCode = yield select(state => state.common ? state.common.voucherCode : null)
			if (voucherCode){
				yield put({ type: SEND_VOUCHER_CODE, data: {code: voucherCode}})
			}
			if (!response.error && !response.Error) {
				yield put({ type: SET_MODAL, modal: 'mobileCheckSms', value: false })
				yield put({ type: SHOW_TOAST, message: translate('Mobile verified successfully. You can add voucher code', profile.locale), toastType: 'success' })
			}
		})
	}
}

export const sendFirebaseToken = function* () {
	while (true) {
		const request = yield take(SEND_FIREBASE_TOKEN)
		yield call(loading, function *() {

			yield put({ type: SENDING_REQUEST, sending: true })

			try {
				yield call(api.sendFirebaseToken, request.args)
				yield put({ type: SENDING_REQUEST, sending: false })
			} catch (error) {
				yield put({ type: REQUEST_ERROR, error: error.message })
				yield put({ type: SENDING_REQUEST, sending: false })
			}
		})
	}
}
