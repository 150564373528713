import React from 'react'
import { IonIcon } from '@ionic/react'
import { withTranslation } from '../../lib/translate'
import { withRouter } from 'react-router'
import { logoFacebook, logoInstagram, logoTwitter } from 'ionicons/icons'
import { DesktopView } from '../desktopView'
import './index.css'

const Footer = ({ __, history }) => {
	return (
		<DesktopView>
			<div className='footer'>
				<div>
					<IonIcon icon={logoFacebook}/>
					<IonIcon icon={logoTwitter}/>
					<IonIcon icon={logoInstagram}/>
				</div>
				<p onClick={() => history.push('/privacy')}>{ __('Privacy Policy')}</p>
			</div>
		</DesktopView>
	)
}

export default withRouter(withTranslation(Footer))