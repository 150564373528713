import React from 'react'

import './index.css'

export default class CurvedText extends React.Component {
	state = {
		curved: false
	}
	curveText = () => {
		const holder = document.querySelector('.curved-text')
		if (holder.clientWidth === 0){
			setTimeout(this.curveText, 50)
		} else {
			let arcLn = 0
			const pi = Math.PI
			const radius = this.props.radius || holder.parentNode.clientWidth - holder.clientHeight
			holder.childNodes.forEach(child => {
				arcLn += child.clientWidth
			})
			if (arcLn > radius *2){
				console.error('CurvedText component error: radius too small')
				return false
			}
			const midAngle = arcLn /2 / radius * 180 /pi
			let remainingLn = arcLn
			holder.childNodes.forEach(child => {
				const childAngle = midAngle - (remainingLn - child.clientWidth /2) / radius * 180 /pi
				const childAngleRad = childAngle * pi /180
				const x = child.clientHeight * Math.sin(childAngleRad)
				const y = radius - radius * Math.cos(childAngleRad)
				child.style.transform = 'rotate(' + childAngle + 'deg) translateX(' + x + 'px) translateY(' + y + 'px)'

				remainingLn -= child.clientWidth
			})
			this.setState({ curved: true })
		}
	}
	componentDidMount () {
		this.curveText()
	}
	componentDidUpdate (prevProps) {
		if (prevProps.text !== this.props.text){
			this.setState({ curved: false }, this.curveText)
		}
	}
	render () {
		const { text } = this.props
		const { curved } = this.state
		return (
			<div className={ 'curved-text' + (curved ? ' curved' : '')}>
				{ text.split('').map((letter, i) => {
					letter = letter === ' ' ? '\u00a0' : letter
					return (
						<div key={ 'ct-' + i }>{ letter }</div>
					)})}
			</div>
		)
	}
}
