import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import asyncAuth from '../../lib/asyncAuth'
import { setAuthState, redirectAfterLogin } from '../../store/actions'

const { restoreAuth } = asyncAuth

class ProtectedRoute extends React.Component {
	state = {
		loading: true
	}

	async componentDidMount() {
		const { path, Component, render, auth } = this.props
		const { loggedIn } = auth
		if (loggedIn && (Component || render)) {
			this.setState({ loading: false })
			this.props.dispatch(redirectAfterLogin(null))
		} else {
			const actuallyLoggedIn = await restoreAuth(this.props.dispatch, setAuthState)
			this.setState({ loading: false })
			this.props.dispatch(redirectAfterLogin(actuallyLoggedIn ? null : path))
		}
	}

	render() {
		const { component: Component, render, auth, ...rest } = this.props
		const { loggedIn } = auth
		const { loading } = this.state
		return loading ?
			<div style={{ height: '500px', paddingTop: '50%', paddingLeft: '50%' }} className="full-height">Loading...</div>
			: <Route { ...rest } render={ loggedIn && render ? render : () =>
				loggedIn && Component
					? <Component { ...rest }/>
					: <Redirect to='/login' />
			} />
	}
}

const stateToProps = state => {
	const { auth } = state.auth
	return {
		auth
	}
}

export default connect(stateToProps)(ProtectedRoute)
