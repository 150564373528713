import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Layout from '../../components/layout'
import { IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel } from '@ionic/react'
// import { FieldError } from '../../components/common'
import { isDesktop, forwardTo, currencySign, price, total } from '../../lib/utils'
import { withTranslation } from '../../lib/translate'
import { getMyOrders, setOrderDetails } from '../../store/actions'
import Loading from '../../components/spinner'
import { DesktopView } from '../../components/desktopView'
import moment from '../../lib/moment'

require('./index.css')
if(isDesktop()){
	require('./desktop.css')
} else {
	require('./mobile.css')
}

class MyOrders extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			orderDetails: {}
		}
	}

	componentDidMount(){
		const { dispatch } = this.props
		dispatch(getMyOrders())
	}

	componentDidUpdate(prevProps) {
		if (prevProps.myOrders !== this.props.myOrders && this.props.myOrders.length > 0) {
			this.setState({ orderDetails: this.props.myOrders[0] })
		}
	}

	getOrderDetails = (order) => {
		const { dispatch, history } = this.props
		dispatch(setOrderDetails(order))
		forwardTo(history, 'my-orders-details')
	}

	render() {
		const { __, myOrders } = this.props
		const { orderDetails } = this.state
		let date = new Date()
		const collection_time = moment(orderDetails.collection_time ? orderDetails.collection_time : myOrders && myOrders.length > 0 ? myOrders[0].collection_time : date).format('DD/MM/YY hh:mm A')
		// const collection_code = orderDetails.collection_code ? orderDetails.collection_code.substr(orderDetails.collection_code.length - 3) : null
		const items = orderDetails.items && orderDetails.items.length > 0 ? orderDetails.items : (myOrders && myOrders.length > 0 && myOrders[0].items && myOrders[0].items.length > 0) ? myOrders[0].items :[]
		const tcurrency = orderDetails.currency && orderDetails.currency !== '' ? orderDetails.currency : ''
		const formatPrice = price => tcurrency === 'gbp' ? currencySign(tcurrency) + price : price + currencySign(tcurrency)
		return (
			<Loading>
				<Layout headerTitle={ __('My Orders') } color={isDesktop() ? 'transparent' : 'white'} contentClassName={ isDesktop() ? "animated-gif-01" : "" } noPadding={ true } >
					<DesktopView>
						<div className='my-orders-header'>{ __('My Orders') }</div>
					</DesktopView>
					<div className="my-orders-wraper">
						<div className={ isDesktop() ? 'desktop-orders-list' : ''}>
							{myOrders.map(order => {
								const created_at = moment(order.created_at).format('DD/MM/YY')
								return (
									<div key={order.id} className="order-holder"  onClick={()=> this.setState({orderDetails: order})}>
										<div className='order-inner-holder'>
											<h2>{ __('Order') + '     #' + order.id +': ' + created_at }</h2>
											<h2>{order.restaurant_name}</h2>
										</div>
										<div className={isDesktop() ? 'order-status' : 'payment'}>
											<p>{__(order.status)}</p>
										</div>
									</div>
								)
							})
							}
						</div>
						<div className={isDesktop() ? 'desktop-order-details-wrapper' : ''}>
							<DesktopView>
								<div className="details-title">{ __(orderDetails.status ? orderDetails.status : myOrders && myOrders.length > 0 ? myOrders[0].status : '') }</div>
							</DesktopView>
							<IonGrid className={isDesktop() ? 'order-details-grid' : ''}>
								<IonRow className="row">
									<IonCol><h2>{ __('Order number:') }</h2></IonCol>
									<IonCol className="end"><h2>#{orderDetails.id ? orderDetails.id : myOrders && myOrders.length > 0 ? myOrders[0].id : null}</h2></IonCol>
								</IonRow>
								<IonRow className="row">
									<IonCol><h2>{ __('Restaurant:') }</h2></IonCol>
									<IonCol className="end"><h2>{orderDetails.restaurant_name ? orderDetails.restaurant_name : myOrders && myOrders.length > 0 ? myOrders[0].restaurant_name : null}</h2></IonCol>
								</IonRow>
								<IonRow className="row">
									<IonCol><h2>{ __('Date:') }</h2></IonCol>
									<IonCol className="end"><h2>{collection_time}</h2></IonCol>
								</IonRow>
							</IonGrid>
							<div className="menu-box">
								<IonList className='first-list' lines='none'>
									{(items || []).map((item, index) => {
										const itemPrice = price(item.price * item.qty)
										return (
											<div key={index} className='menu-box-item'>
												<IonItem>
													<div className='menu-box-item-content'>
														<IonItem>
															<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
																<IonLabel>{item.qty}x {item.name}</IonLabel>
															</div>
															<h2>{itemPrice ? formatPrice(itemPrice) : null}</h2>
														</IonItem>
														<IonList className='menu-box-sub-items' lines='none'>
															{ (item.sub_items || []).map((sub_item, idx) => {
																const subItemPrice = price(sub_item.item_qty ? sub_item.item_price * item.qty * sub_item.item_qty : sub_item.item_price * item.qty)
																return (
																	<IonItem key={idx}>
																		<div className='menu-box-subItem-content'>
																			<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
																				<IonLabel slot='start'>+{sub_item.item_qty ? sub_item.item_qty * item.qty + 'x' : item.qty > 1 ? item.qty + 'x': null} {sub_item.item_name}</IonLabel>
																			</div>
																			<h2>{subItemPrice ? `+${formatPrice(subItemPrice)}` : null }</h2>
																		</div>
																	</IonItem>
																)
															})}
														</IonList>
													</div>
												</IonItem>
												{ item.notes && item.notes !== '' ? (
													<div className="munu-box-total notes">
														<IonLabel>{ __('Special Notes and Allergies') }:</IonLabel>
														<h2>{item.notes}</h2>
													</div>
												) : null }
											</div>
										)
									})}
								</IonList>
								{orderDetails.discount_applied ?
									<div className="total-box">
										<h2>{__('Discount')}:</h2>
										<h2>-{formatPrice((parseFloat(orderDetails.discount_applied) / 100).toFixed(2))}</h2>
									</div> : null
								}
								{items.length > 0 ?
									<div className="total-box">
										<h2>{__('Total')}</h2>
										<h2>{formatPrice(total(items, false, false, orderDetails))}</h2>
									</div>
									: null }
							</div>
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const stateToProps = (state) => {
	const { myOrders } = state.order
	const { translationCatalog } = state.common
	return {
		myOrders: myOrders || [],
		currency: state.order.currency,
		translationCatalog
	}
}

export default connect(stateToProps)(withRouter(withTranslation(MyOrders)))
