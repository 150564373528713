import React, { Fragment } from 'react'
import appConfig from '../../appConfig.js'
import { withTranslation } from '../../lib/translate'
import { trim } from '../../lib/utils'

export const FieldError = props => {
	const { value, className } = props
	let err = value === '' ? null : <div className={ className ? className : null }>{ value }</div>
	return err
}

export const Nl2Br = withTranslation(props => {
	const { children, nl, __ } = props
	const newline = nl || '\n'
	return children.split(newline).map((item, key) => {
		const str = trim(item)
		return <Fragment key={ 'nl2br-' + key }>{ str !== '' ? __(str) : '' }<br /></Fragment>
	})
})

export const FallbackText = props => {
	const { children, fallback } = props
	const { emptyString } = appConfig.general
	const fb = fallback || emptyString
	return (
		<>
			{ !children || children === '' ? fb : children }
		</>
	)
}
