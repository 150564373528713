export const SENDING_REQUEST = 'SENDING_REQUEST'
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
export const REQUEST_ERROR = 'REQUEST_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const LOADING = 'LOADING'
export const SHOW_TOAST = 'SHOW_TOAST'
export const RESET_TOAST = 'RESET_TOAST'
export const SET_COMMON_PROP = 'SET_COMMON_PROP'
export const SET_MODAL = 'SET_MODAL'
export const ERROR = 'ERROR'
export const RIGHT_DRAWER = 'RIGHT_DRAWER'
export const INIT = 'INIT'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const SEND_POST_CODE = 'SEND_POST_CODE'
export const INIT_FIREBASE_DATABASE = 'INIT_FIREBASE_DATABASE'
