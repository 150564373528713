import api from './api'
import asyncStorage from './asyncStorage'

const asyncAuth = {
	/**
	 * Logs a user in, returning a promise with `true` when done
	 * @param { string } username The username of the user
	 * @param { string } password The password of the user
	 */
	async login(username, password) {
		const response = await api.login(username, password)
		// Save token to local storage
		const { token, ...rest } = response.data
		const user = JSON.stringify(rest)
		await asyncStorage.setItem('token', token)
		await asyncStorage.setItem('user', user)
		return Promise.resolve(response.data)
	},
	/**
	* Logs the current user out
	*/
	async logout() {
		const res = await asyncStorage.removeItem('token')
		return res
	},
	/**
	* Checks if a user is logged in
	*/
	async loggedIn() {
		const token = await asyncStorage.getItem('token')
		return !!token
	},
	/**
	* Registers a user and then logs them in
	* @param { string } username The username of the user
	* @param { string } password The password of the user
	*/
	async register(data) {
		await api.register(data)
		return asyncAuth.login(data.email, data.password)
	},
	onChange() { },
	async getUser() {
		const data = await asyncAuth.getAuth()
		const { user, token } = data
		return !!token && user ? JSON.parse(user) : null
	},
	async getAuth() {
		const data = await Promise.all([
			asyncStorage.getItem('user'),
			asyncStorage.getItem('token')
		])
		return {
			user: data[0] ? JSON.parse(data[0]) : null,
			token: data[1]
		}
	},
	async restoreAuth(dispatch, action) {
		const data = await asyncAuth.getAuth()
		const { user, token } = data
		if (token){
			dispatch(action(true, user, token))
		}
		return !!token
	}
}

export default asyncAuth
