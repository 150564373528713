import {
	DATA_REQUEST,
	DATA_CLEAR_ERROR,
	DATA_CLEAR_CONTENT
} from './constants'

export const dataRequest = (dataType, body) => ({ type: DATA_REQUEST, dataType, body })

export const dataClearError = (dataType) => ({ type: DATA_CLEAR_ERROR, dataType })

export const dataClearContent = apiMethod => ({ type: DATA_CLEAR_CONTENT, apiMethod })
