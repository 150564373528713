import React from 'react'
import { IonButton } from '@ionic/react'
import Layout from '../../components/layout'
import appConfig from '../../appConfig.js'
import { withTranslation } from '../../lib/translate'
import './index.css'

const openExternalLink = url => window.open(url, '_system')

const Social = ({ __ }) => {
	return (
		<Layout color="white" noPadding={ true } headerTitle={ __('Social Media')}>
			<div className="social-container">
				<div slot="start" className="palm palm-left"></div>
				<div className="ion-text-center">
					<div className="content-title">
						<span>{ __('Spread the Poke word and Aloha spirit')}</span>
					</div>
					<div onClick={() => openExternalLink(appConfig.social.facebook)}>
						<IonButton expand="block" className="top-medium bottom-button social-button" color="tertiary">{ __('Facebook')}</IonButton>
						<div className="social-title">
							/{appConfig.social.facebook_label}
						</div>
					</div>
					<div onClick={() => openExternalLink(appConfig.social.instagram)}>
						<IonButton expand="block" className="top-medium bottom-button social-button" color="tertiary">{ __('Instagram')}</IonButton>
						<div className="social-title">
							@{appConfig.social.instagram_label}
						</div>
					</div>
					<div onClick={() => openExternalLink(appConfig.social.website)}>
						<IonButton expand="block" className="top-medium bottom-button social-button" color="tertiary">{ __('Website')}</IonButton>
						<div className="social-title">
							{appConfig.social.website_label}
						</div>
					</div>
				</div>
				<div slot="end" className="palm palm-right"></div>
			</div>
		</Layout>
	)
}

export default withTranslation(Social)
