import React from 'react'
import appConfig from '../../appConfig'
import { logoInstagram } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'
import { isDesktop } from '../../lib/utils'

require('./index.css')
if(isDesktop()) {
	require('./desktop.css')
} else {
	require('./mobile.css')
}

const GridStamp = (props) => {
	const { profile, history, __ } = props
	return (
		<>
			<p className="stamp-header">{ __('Get your 10th bowl free')}</p>
			<div className="stamp-grid-padding">
				<div className="loyalty-grid">
					<div className={profile.available_balance > 0 ? 'loyalty-grid-stamp stamp1' : 'loyalty-grid-stamp one'}>1</div>
					<div className={profile.available_balance > 1 ? 'loyalty-grid-stamp stamp2' : 'loyalty-grid-stamp two'}>2</div>
					<div className={profile.available_balance > 2 ? 'loyalty-grid-stamp stamp3' : 'loyalty-grid-stamp three'}>3</div>
					<div className={profile.available_balance > 3 ? 'loyalty-grid-stamp stamp4 stamped' : 'loyalty-grid-stamp stamped four'}> { __('Free Topping')}</div>
					<div className={profile.available_balance > 4 ? 'loyalty-grid-stamp stamp5' : 'loyalty-grid-stamp five'}>5</div>
					<div className={profile.available_balance > 5 ? 'loyalty-grid-stamp stamp6' : 'loyalty-grid-stamp six'}>6</div>
					<div className={profile.available_balance > 6 ? 'loyalty-grid-stamp stamp7' : 'loyalty-grid-stamp seven'}>7</div>
					<div className={profile.available_balance > 7 ? 'loyalty-grid-stamp stamp8' : 'loyalty-grid-stamp eight'}>8</div>
					<div className={profile.available_balance > 8 ? 'loyalty-grid-stamp stamp9' : 'loyalty-grid-stamp nine'}>9</div>
					{/* <div className="loyalty-grid-palm"></div> */}
					<div className={profile.available_balance > 9 ? 'loyalty-grid-stamp stamp10 stamped' : 'loyalty-grid-stamp stamped ten'}>{ __('Free Bowl')}</div>
					{/* <div className="loyalty-grid-palm"></div> */}
				</div>
			</div>
			<p className="stamp-note"><IonIcon icon={ logoInstagram } color="secondary" style={{ fontSize: '14px', marginBottom: '-3px' }} /> { __(appConfig.social.instagram_label) } &nbsp;&nbsp;|&nbsp;&nbsp; { appConfig.social.website_label }</p>
			<p className="stamp-note bold">{ __('Cannot be used in conjunction with any other offer of loyalty card.') } <span onClick={() => history.push({ pathname: 'terms' })}>{ __('Read full T&Cs') }</span></p>
		</>
	)
}


export default GridStamp