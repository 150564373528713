import { fork, call, select, put, all } from 'redux-saga/effects'
import { loginFlow, logoutFlow, registerFlow, updateProfileFlow, getProfileFlow, sendFeedback, sendRefer, sendVoucherCode, getVouchers, sendMobile, mobileCheckSms, restoreAuthFlow } from './auth/sagas'
import { initSaga, resetPasswordFlow, sendPostCode, firebaseDatabaseFlow } from './common/sagas'
import { dataFlow } from './data/sagas'
import { getMyOrders, createOrderFlow, addScannedCard, removePaymentCardsFlow, getPaymentCardsFlow } from './order/sagas'
import { LOGOUT, SENDING_REQUEST, SET_COMMON_PROP, SHOW_TOAST, SET_MODAL } from './constants'
import { isDefined } from '../lib/utils'
import { createAxiosInstance } from '../lib/api'
import { translate } from '../lib/translate' // raw translate, second param (locale) mandatory!

// Wrap forks with an Error handler
const wrap = function* (fn, args) {
	try {
		yield call(fn, ...args)
	}
	catch (e) {
		const { response } = e
		const status = response && isDefined(response.status) ? response.status : null
		let error = response && response.data && response.data.error ? response.data.error : null
		let validate_mobile = false
		if (response && response.data && response.data.non_field_errors){ // This is fix just because backend sends wrong params
			error = response.data.non_field_errors
		}
		if (response && response.data && response.data.Error) { // This is fix just because backend sends wrong params
			if (response.data.Error === 'Requires validated mobile') {validate_mobile = true}
			error = response.data.Error
		}
		yield put({ type: SENDING_REQUEST, flag: false })
		const profile = yield select(state => state.auth ? state.auth.profile : {})
		switch (status) {
		case 200:
			break
		case 201:
			break
		case 400:
			if (error && !validate_mobile) {
				yield put({ type: SET_COMMON_PROP, key: 'error', value: error })
				yield put({ type: SHOW_TOAST, message: translate(error, profile.locale) || 'Error' })
			} else if (validate_mobile) {
				yield put({ type: SET_MODAL, modal: 'mobileValidate', value: true })
			}
			break
		case 401:
			yield put({ type: SHOW_TOAST, message: translate('Please login!', profile.locale)})
			yield put({ type: LOGOUT })
			break
		case 404:
			// To do - display not found component (page)
			break
		case 405:
			yield put({ type: SHOW_TOAST, message: translate('Problem finishing operation', profile.locale)})
			break
		case 500:
			yield put({ type: SHOW_TOAST, message: translate('Server error occurred.', profile.locale), toastType: 'danger' })
			break
		default:
		}
		//reactivate stoped saga
		yield fork(wrap, fn, args)
	}
}

const forkWithErrHandler = (fn, ...args) => fork(wrap, fn, args)

const sagas = [
	initSaga,
	loginFlow,
	logoutFlow,
	registerFlow,
	dataFlow,
	updateProfileFlow,
	getProfileFlow,
	sendFeedback,
	sendRefer,
	sendVoucherCode,
	getVouchers,
	sendMobile,
	mobileCheckSms,
	restoreAuthFlow,
	resetPasswordFlow,
	getMyOrders,
	createOrderFlow,
	sendPostCode,
	addScannedCard,
	removePaymentCardsFlow,
	getPaymentCardsFlow,
	firebaseDatabaseFlow

].map(saga => {
	return forkWithErrHandler(saga)
})

const root = function* () {
	yield call(createAxiosInstance)
	yield all(sagas)
}

export default root
