import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Layout from '../../components/layout'
import { withTranslation } from '../../lib/translate'
import { IonSpinner } from '@ionic/react'
import { showToast } from '../../store/actions'
import { isDesktop, forwardTo, protectedOrder } from '../../lib/utils'
import { DesktopView } from '../../components/desktopView'
import { SplitForm } from '../../components/checkoutForm'

require('./index.css')
if (isDesktop()) {
	require('./desktop.css')
} else {
	require('./mobile.css')
}

// Example key pk_test_TYooMQauvdEDq54NiTphI7jx
class Checkout extends React.Component {

	handleProtectOrder = () => {
		const { __, dispatch, history, restaurantId, collectionTime } = this.props
		if (!protectedOrder(restaurantId, collectionTime)) {
			dispatch(showToast(__('Can\' proceed to checkout without selected restaurant and desired order') , 'warning'))
			forwardTo(history, '/locations')
		}
	}

	componentDidUpdate(prevProps) {
		const { restaurantId, collectionTime } = this.props
		if (prevProps.restaurantId === restaurantId && prevProps.collectionTime === collectionTime && !restaurantId && !collectionTime) {
			this.handleProtectOrder()
		}
	}

	render() {
		const { __, loading, history } = this.props
		return (
			<Layout headerTitle={ __('Checkout')} color={ isDesktop() ? 'transparent' : '' } contentClassName={ isDesktop() ? 'animated-gif-01' : '' } noPadding={true}>
				<DesktopView>
					<div className="desktop-checkout-title">{ __('Checkout')}</div>
				</DesktopView>
				<div className="desktop-checkout-wrapper">
					<>
						<SplitForm history={ history } />
						{/* <PaymentRequestForm history={ history } /> */}
					</>
				</div>
				{loading > 0 ? <div className='content-spinner'><IonSpinner color='primary' name='bubbles'/></div> : null }
			</Layout>
		)
	}
}

const stateToProps = (state) => {
	const { profile } = state.auth
	const { loading } = state.common
	const order = state.order

	return {
		profile,
		loading,
		restaurantId: order.restaurantId,
		collectionTime: order.collectionTime
	}
}

export default connect(stateToProps)(withRouter(withTranslation(Checkout)))
