import { Capacitor, Plugins } from '@capacitor/core'
import { eventChannel } from 'redux-saga'
import { take, put } from 'redux-saga/effects'
import { SET_COMMON_PROP} from '../store/common/constants'

const { PushNotifications } = Plugins

export const initFirebaseNotifications = () => {

	console.log('init firebase notification entered')
	// Register with Apple / Google to receive push via APNS/FCM
	PushNotifications.register()

	// On succcess, we should be able to receive notifications
	PushNotifications.addListener('registration',
		token => {
			// eslint-disable-next-line no-console
			console.log('Push registration success, token: ' + token.value)
		}
	)

	// Some issue with your setup and push will not work
	PushNotifications.addListener('registrationError',
		error => {
			// eslint-disable-next-line no-console
			console.log('Error on registration: ' + JSON.stringify(error))
		}
	)

	// Show us the notification payload if the app is open on our device
	PushNotifications.addListener('pushNotificationReceived',
		notification => {
			let notif
			notif.push({ id: notification.id, title: notification.title, body: notification.body })
		}
	)

	// Method called when tapping on a notification
	PushNotifications.addListener('pushNotificationActionPerformed',
		notification => {
			let notif
			notif.push({ id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body })
		}
	)
}

/* firebase Saga */
export const firebaseFlow = function* () {
	if (Capacitor.platform !== 'web') {
		// Register with Apple / Google to receive push via APNS/FCM
		PushNotifications.register()
		const firebaseChannel = eventChannel(emitter => {
			PushNotifications.addListener('registration', token => {
				emitter({
					type: 'registration',
					value: token.value
				})})
			// Some issue with your setup and push will not work
			PushNotifications.addListener('registrationError', error => { emitter({
				type: 'registrationError',
				value: error
			})})
			// Show us the notification payload if the app is open on our device
			PushNotifications.addListener('pushNotificationReceived', notification => { emitter({
				type: 'pushNotificationReceived',
				value: notification
			})})
			// Method called when tapping on a notification
			PushNotifications.addListener('pushNotificationActionPerformed', notification => { emitter({
				type: 'pushNotificationActionPerformed',
				value: notification
			})})
		})
		while (true) {
			const firebaseMessage = yield take(firebaseChannel)
			// eslint-disable-next-line no-console
			console.log('firebase message', firebaseMessage)
			switch (firebaseMessage.type) {
			case 'registration': {
				yield put({ type: SET_COMMON_PROP, key: 'deviceFcmToken', value: firebaseMessage.value })
				break
			}
			default:
			}
		}
	}
}
