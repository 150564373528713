import React from 'react'
import { IonButton } from '@ionic/react'
import Layout from '../../components/layout'
import { withTranslation } from '../../lib/translate'
import appConfig from '../../appConfig.js'
import './index.css'

const openExternalLink = url => window.open(url, '_system', 'location=yes')

const Radio = ({ __ }) => {
	return (
		<Layout headerTitle={ 'ISLAND ' + __('Radio') }>
			<div className="radio-holder ion-padding">
				<div className="radio-pic"></div>
				<div className="bottom-button-holder">
					<IonButton expand="block" className="top-medium bottom-button" color="secondary" onClick={ () => openExternalLink(appConfig.services.spotify)}>{ __('Tune in on spotify')}</IonButton>
				</div>
			</div>
		</Layout>
	)
}

export default withTranslation(Radio)
