import React from 'react'
import { Route } from 'react-router-dom'
import ProtectedRoute from '../components/protectedRoute'
import { default as BigNumberInstance } from './bignumber'
import { default as momentInstance } from './moment'
import navConfig from '../navConfig'
import { Capacitor } from '@capacitor/core'
import appConfig from '../appConfig'

export const BigNumber = BigNumberInstance

export const moment = momentInstance

export const emptyString = '--'

export const parseDecimal = (val, places) => {
	places = places || 2
	if (typeof val === 'string' || val instanceof String){
		if ((val === '.' || val === '0.' || val.slice(-1) === '.') && val.slice(0, -1) === parseInt(val, 10).toString()){
			return val
		}
	}
	return parseFloat(parseFloat(val).toFixed(places))
}

export const getComponentByDomNode = querySelector => {
	const dom = global.window.document.querySelector(querySelector)
	for (const key in dom) {
		if (key.startsWith('__reactInternalInstance$')) {
			const reactNode = dom[key]
			if (reactNode._currentElement) {
				// v15 < react < v16
				const compInternals = reactNode._currentElement
				if (compInternals) {
					const compWrapper = compInternals._owner
					if (compWrapper) {
						return compWrapper._instance
					}
				}
			} else {
				// react v16+
				return reactNode && reactNode.return && reactNode.return.stateNode
			}
		}
	}
	return null
}

// declarative "nav to"
export const forwardTo = (history, location, historyState = null) => {
	history.push(location, historyState)
}

export const goBack = (history) => history.goBack()

export const getDefaultRoute = protectedRoute => {
	protectedRoute = protectedRoute ? protectedRoute : false
	let defaultRoute = navConfig.routes.find(item => item.default === true && (protectedRoute ? item.protected === true : item.protected !== true))
	if (!defaultRoute){
		defaultRoute = navConfig.routes.find(item => protectedRoute ? item.protected === true : item.protected !== true)
	}
	return defaultRoute || navConfig.routes[0]
}

export const getHistoryState = (history, path = null) => !path ? null : getPropertyByPath(history, 'location.state.' + path)

export const getScrollbarWidth = element => {
	let scrollbarWidth = 0
	if (element) {
		scrollbarWidth = element.offsetWidth - element.clientWidth
	} else if (global && global.window) {
		scrollbarWidth = global.window.innerWidth - global.window.document.documentElement.clientWidth
	}
	return scrollbarWidth
}

/**
 * Helper utility function
 * checking if `item` is not `undefined` and not `null`
 * @public
 * @function
 * @param {object|string|array|number} item - source object with property "path" we want to check
 * @return {boolean} item is defined or not
 */
export const isDefined = item => item !== undefined && item !== null

/**
 * Check if object have defined some path.
 * (e.g. path='someObj.property1.property2')
 * @public
 * @param {Object} object object for check
 * @param {String} [path=''] object for check
 * @return {Boolean}
 * @function
 */
export const deepIsDefined = (object, path = '') => {
	let def = true
	let obj = { ...object }
	const arr = path.split('.')

	arr.forEach(level => {
		if (!obj[level]) {
			def = false
			return
		}
		obj = obj[level]
	})
	return def
}

export const setPropertyByPath = (object, path = '', value) => {
	if (path.indexOf('.') === -1) {
		if (isDefined(value)) {
			object[path] = value
		}
		return object
	} else {
		let paths = path.split('.')
		const singlePath = paths[0]
		paths = paths.slice(1, paths.length)
		if (!object[singlePath]) {
			object[singlePath] = {}
		}
		setPropertyByPath(object[singlePath], paths.join('.'), value)
		return object
	}
}

export const getPropertyByPath = (object, path = '') => {
	if (path.indexOf('.') === -1) {
		return object[path]
	} else {
		let paths = path.split('.')
		const singlePath = paths[0]
		paths = paths.slice(1, paths.length)
		if (!object[singlePath]) {
			return null
		}
		return getPropertyByPath(object[singlePath], paths.join('.'))
	}
}

export const padNumber = (num, size) => {
	var s = num + ''
	while (s.length < size) { s = '0' + s }
	return s
}

/**
 * Simulate wait of some operation
 * @public
 * @function
 * @param {number} ms milliseconds
 * @return {Promise}
 */
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

/**
 * Remove white spaces from string from the left side
 * @public
 * @function
 * @param {string} s string to parse
 * @param {string} char_mask
 * @return {string}
 */
export const lTrim = (s, char_mask) => {
	const whitespace = ' \t\n\r' + (char_mask || '')

	if (whitespace.indexOf(s.charAt(0)) !== -1) {
		let j = 0, i = s.length
		while (j < i && whitespace.indexOf(s.charAt(j)) !== -1) {
			j++
		}
		s = s.substring(j, i)
	}

	return s
}

/**
 * Remove white spaces from string from the right side
 * @public
 * @function
 * @param {string} s string to parse
 * @param {string} char_mask
 * @return {string}
 */
export const rTrim = (s, char_mask) => {
	const whitespace = ' \t\n\r' + (char_mask || '')

	if (whitespace.indexOf(s.charAt(s.length - 1)) !== -1) {
		let i = s.length - 1
		while (i >= 0 && whitespace.indexOf(s.charAt(i)) !== -1) {
			i--
		}
		s = s.substring(0, i + 1)
	}

	return s
}

/**
 * Remove white spaces from string
 * @public
 * @function
 * @param {string} s string to parse
 * @param {string} char_mask
 * @return {string}
 */
export const trim = (s, char_mask) => rTrim(lTrim(s, char_mask), char_mask)

export const fileSize = (sizeB, unit) => {
	const validUnits = ['B', 'kB', 'MB', 'GB', 'TB']
	const dv = 1024
	const intSize = parseInt(sizeB, 10)
	let unitIndex = 0
	let size = new BigNumber(intSize)
	if (unit) {
		if (validUnits.map(vu => vu.toLowerCase()).indexOf(unit.toLowerCase()) === -1) {
			if (unit.indexOf('i') !== -1 || unit.indexOf('I') !== -1) {
				// eslint-disable-next-line no-console
				console.error('BS "bi-bi-bytes" units (kiB, MiB, GiB, TiB) are not supported!')
			} else {
				// eslint-disable-next-line no-console
				console.error('Invalid file size unit "' + unit + '" provided!')
			}
			return intSize
		}
		if (unit.toLowerCase() === 'kb') {
			size = size.div(dv)
			unitIndex = 1
		} else if (unit.toLowerCase() === 'mb') {
			size = size.div(dv).div(dv)
			unitIndex = 2
		} else if (unit.toLowerCase() === 'gb') {
			size = size.div(dv).div(dv).div(dv)
			unitIndex = 3
		} else if (unit.toLowerCase() === 'tb') {
			size = size.div(dv).div(dv).div(dv).div(dv)
			unitIndex = 4
		} else {
			unitIndex = 0
		}
	} else {
		while (size.toNumber() > dv && unitIndex < validUnits.length - 1) {
			size = size.div(dv)
			unitIndex++
		}
	}
	return size.toFormat(2, BigNumber.ROUND_HALF_CEIL) + ' ' + validUnits[unitIndex]
}

export const compareByKey = (key, a, b) => {
	if (a[key] < b[key]) {
		return -1
	}
	if (a[key] > b[key]) {
		return 1
	}
	return 0
}

export const deepCopy = json => JSON.parse(JSON.stringify(json))

export const isString = val => typeof val === 'string' || val instanceof String

export const ucFirst = str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const ucWords = str => str.split(' ').map(word => ucFirst(word)).join(' ')

export const labelize = string => trim(ucWords(string.replace(/[/\-_]/gi, ' ')))

export const extractNumbers = str => {
	let tt = str.match(/\d+/g)
	tt = tt.toString().replace(/,/g, '')
	return parseInt(tt, 10)
}

export const validateForm = (formConfig, state, __) => {
	let formErrors = {}
	Object.keys(state).forEach(element => {
		if (formConfig[element]){
			const type = formConfig[element].type
			const fieldValue = state[element]
			switch (type) {
			case 'boolean':
			case 'integer':
			case 'text':
			case 'password': // password in case we want some extra rules
				if (formConfig[element].required === true && (fieldValue === undefined || fieldValue === '')) {
					formErrors[element] = __('Required field')
				} else if (formConfig[element].invalidValue === fieldValue) {
					formErrors[element] = __('Invalid value')
				}
				break
			case 'email':
				if (formConfig[element].required === true && (fieldValue === undefined || fieldValue === '')) {
					formErrors[element] = __('Required field')
				} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fieldValue)) {
					formErrors[element] = __('Invalid email')
				}
				break
			case 'tel': {
				if (isDefined(fieldValue) && fieldValue !== '' && !/^\+?\d+\.?\d*$/.test(fieldValue) ) {
					formErrors[element] = __('Invalid mobile number')
				}
				break
			}
			default:
			}
		}
	})
	return formErrors
}

export const isEmptyObject = obj => {
	for (var key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) { return false }
	}
	return true
}

export const tabifyPath = (path) => '/:tab(' + lTrim(path,'/') + ')'

export const mapRoutes = (routes, tabs = false, extraProps = {}) => {
	return routes.filter(route => !!route.path && !!route.component).map(item => {
		let { path } = item
		if (tabs){
			path = tabifyPath(path)
		}
		const ActualRoute = item.protected ? ProtectedRoute : Route
		return (
			<ActualRoute exact={ item.exact } path={ path } key={ 'nav-key-' + path } route={ item }
				cmp={ item.component }
				render={ props =>
					item.render ?
						item.render({ ...props, ...extraProps, route: item })
						:
						<item.component { ...props } { ...extraProps } route={ item } />
				}
			/>
		)
	})
}

export const nl2br = (str) => {
	if (!str){
		return null
	}
	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2')
}

export const cssVar = (key) => {
	if (!window && !document){
		return null
	}
	let root = document.documentElement || document
	const propValue = window.getComputedStyle(root).getPropertyValue(key)
	return propValue || null
}

// export const isDesktop = () => ((isPlatform('desktop') || isPlatform('mobileweb') || isPlatform('phablet') || isPlatform('tablet')) && !isPlatform('mobile'))
export const isDesktop = () => Capacitor.platform === 'web'

export const currencySign = (currency) => {
	const currency_symbols = {
		'': '', // When no value
		'USD': '$', // US Dollar
		'EUR': '€', // Euro
		'CRC': '₡', // Costa Rican Colón
		'GBP': '£', // British Pound Sterling
		'ILS': '₪', // Israeli New Sheqel
		'INR': '₹', // Indian Rupee
		'JPY': '¥', // Japanese Yen
		'KRW': '₩', // South Korean Won
		'NGN': '₦', // Nigerian Naira
		'PHP': '₱', // Philippine Peso
		'PLN': 'zł', // Polish Zloty
		'PYG': '₲', // Paraguayan Guarani
		'THB': '฿', // Thai Baht
		'UAH': '₴', // Ukrainian Hryvnia
		'VND': '₫' // Vietnamese Dong
	}
	const currency_name = currency.toUpperCase()
	if (currency_symbols[currency_name] !== undefined) {
		return currency_symbols[currency_name]
	}
}

export const price = (value) => {
	if (value === 0 || value === undefined || value === null || Number.isNaN(value)) {
		return null
	} else {
		const price = value / 100
		return price.toFixed(2)
	}
}

export const total = (items, appliedVouchers, basket, orderDetails) => {
	let sum = 0
	let discount = 0
	const basketVouchers = basket && basket.vouchers ? basket.vouchers : []
	if (appliedVouchers && appliedVouchers.length > 0 && basketVouchers.length > 0) {
		appliedVouchers.forEach((voucher) => {
			let findedVoucher = basketVouchers.find(basketVoucher => basketVoucher.id === voucher.id)
			if (findedVoucher) {
				discount += findedVoucher.cost
			}
		})
	}

	items.forEach((item) => {
		sum += item.price * item.qty
		item.sub_items.forEach(sub_item => {
			if (sub_item.item_qty) {
				sum += sub_item.item_price * item.qty * sub_item.item_qty
			} else {
				sum += sub_item.item_price * item.qty
			}

		})
	})
	if (Number.isNaN(sum)) {
		sum = 0
	}
	if (orderDetails) {
		discount += orderDetails.discount_applied || 0
	}
	sum = parseFloat(sum)
	sum = sum - discount
	sum = (sum / 100).toFixed(2)
	return sum
}

export const getDistance = (position, mylatitude, mylongitude) => {
	const lat1 = mylatitude
	const lon1 = mylongitude
	const LatLong = position.split(',')
	const lat2 = parseFloat(LatLong[0])
	const lon2 = parseFloat(LatLong[1])

	if (lat1 === lat2 && lon1 === lon2) {
		return 0
	} else {
		var radlat1 = Math.PI * lat1/180
		var radlat2 = Math.PI * lat2/180
		var theta = lon1-lon2
		var radtheta = Math.PI * theta/180
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
		if (dist > 1) {
			dist = 1
		}
		dist = Math.acos(dist)
		dist = dist * 180/Math.PI
		dist = dist * 60 * 1.1515
		dist = dist * 1.609344

		return dist
	}
}

export const protectedOrder = (restaurant, collection_time) => {
	let forward = false

	if (restaurant && collection_time) {
		forward = true
	}

	return forward
}

export const getLocale = profile => {
	let locale = appConfig.localization.defaultLocale
	if (profile && profile.locale) {
		locale = profile.locale
	}
	return locale
}

export const isObject = value => value && typeof value === 'object' && value.constructor === Object

export const isArray = value => value && typeof value === 'object' && value.constructor === Array

export const sortLocations = (locations = [], currentPosition = {}) => {
	let updatedLocations = []
	const { latitude, longitude } = currentPosition

	if (isDefined(currentPosition) && isDefined(latitude) && isDefined(longitude)) {
		updatedLocations = locations.map(i => ({ ...i, distance: getDistance(i.position, latitude, longitude )}))

		return updatedLocations.sort((a,b) => {
			if (isDefined(a.distance) && isDefined(b.distance)) {
				if (a.distance < b.distance) { return -1 }
				if (a.distance > b.distance) { return 1 }
				return 0
			} else {
				return 0
			}
		})
	} else {
		//If we dont have current position. In that case return array as it is.
		return locations
	}
}
