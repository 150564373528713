import {
	SET_AUTH,
	LOGIN_REQUEST,
	REGISTER_REQUEST,
	LOGOUT,
	REDIRECT_AFTER_LOGIN,
	SEND_FIREBASE_TOKEN,
	GET_PROFILE,
	UPDATE_PROFILE,
	SEND_FEEDBACK,
	SEND_REFER,
	SEND_VOUCHER_CODE,
	GET_VOUCHERS,
	SEND_MOBILE,
	MOBILE_CHECK_SMS,
	RESTORE_AUTH,
	SET_SYS_LOCALE
} from './constants'

export const setAuthState = (newAuthState, user, token) => ({ type: SET_AUTH, newAuthState, user, token })

export const getProfile = (skipLoading = false) => ({ type: GET_PROFILE, skipLoading })

export const updateProfile = data => ({ type: UPDATE_PROFILE, data })

export const loginRequest = data => ({ type: LOGIN_REQUEST, ...data })

export const registerRequest = data => ({ type: REGISTER_REQUEST, data })

export const logout = () => ({ type: LOGOUT })

export const redirectAfterLogin = path => ({ type: REDIRECT_AFTER_LOGIN, path })

export const sendFirebaseToken = data => ({ type: SEND_FIREBASE_TOKEN, data })

export const sendFeedback = data => ({ type: SEND_FEEDBACK, data })

export const sendRefer = data => ({ type: SEND_REFER, data })

export const sendVoucherCode = data => ({ type: SEND_VOUCHER_CODE, data })

export const getVouchers = () => ({ type: GET_VOUCHERS })

export const sendMobile = data => ({ type: SEND_MOBILE, data })

export const mobileCheckSms = data => ({ type: MOBILE_CHECK_SMS, data })

export const restoreAuth = () => ({ type: RESTORE_AUTH })

export const setSysLocale = sysLocale => ({ type: SET_SYS_LOCALE, sysLocale})
