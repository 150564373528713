import React from 'react'
import { connect } from 'react-redux'
import Layout from '../../components/layout'
import { withTranslation } from '../../lib/translate'
import { dataRequest, dataClearError, showToast } from '../../store/actions'
import SimpleLoader from '../../components/simpleLoader'

class Terms extends React.Component {
	componentDidUpdate () {
		const { data, apiMethod, dispatch } = this.props
		const { errors } = data
		const error = errors[apiMethod]

		if (error){
			dispatch(showToast(error.message, 'danger'))
			dispatch(dataClearError(apiMethod))
		}
	}
	componentDidMount () {
		const { apiMethod, dispatch } = this.props
		dispatch(dataRequest(apiMethod, {}))
	}
	render () {
		const { __, data, apiMethod } = this.props
		const { loading, content } = data
		const info = content[apiMethod]
		return (
			<Layout color="white" headerTitle={ __('Terms & Conditions')}>
				<SimpleLoader loading={ loading }>
					{ info ?
						<span dangerouslySetInnerHTML={{ __html: info }} />
						:
						<p>No data</p>
					}
				</SimpleLoader>
			</Layout>
		)
	}
}

const stateToProps = (state) => {
	const { data } = state
	return {
		data,
		apiMethod: 'terms'
	}
}

export default connect(stateToProps)(withTranslation(Terms))
