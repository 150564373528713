import {
	SENDING_REQUEST,
	REQUEST_ERROR,
	CLEAR_ERROR,
	LOADING,
	SHOW_TOAST,
	RESET_TOAST,
	SET_COMMON_PROP,
	SET_MODAL,
	ERROR,
	RIGHT_DRAWER
} from '../constants'

let initialState = {
	loading: 0,
	error: {
		message: '',
		errors: {},
		errorStatusCode: null
	},
	toast: {},
	currentlySending: 0,
	isRegisterModalOpen: false,
	isResetPasswordModalOpen: false,
	mobileValidate: false,
	mobileCheckSms: false,
	deviceFcmToken: null,
	myLocation: {
		latitude: null,
		longitude: null
	},
	voucherCode: ''
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SENDING_REQUEST: {
			const flag = action.sending ? state.currentlySending + 1 : state.currentlySending -1
			return { ...state, currentlySending: flag < 0 ? 0 : flag }
		}
		case REQUEST_ERROR:
			return { ...state, error: action.error }
		case CLEAR_ERROR:
			return { ...state, error: initialState.errors }
		case LOADING:
			return { ...state, loading: action.loading ? ++state.loading : state.loading === 0 ? state.loading : --state.loading }
		case ERROR:
			return { ...state, error: action.error }
		case SHOW_TOAST: {
			const toast = { message: action.message || '', toastType: action.toastType }
			return { ...state, toast }
		}
		case RESET_TOAST: {
			const toast = { message: '', toastType: null }
			return { ...state, toast }
		}
		case SET_COMMON_PROP:
			return { ...state, [action.key]: action.value }
		case SET_MODAL:
			return { ...state, [action.modal]: action.value }
		case RIGHT_DRAWER:
			return { ...state, isRightDrawerOpen: !state.isRightDrawerOpen }
		default:
			return state
	}
}

export default reducer
