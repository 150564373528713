import React from 'react'
// import { IonButton } from '@ionic/react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Layout from '../../components/layout'
import { withTranslation } from '../../lib/translate'
import { IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel } from '@ionic/react'
import moment from '../../lib/moment'
import { total, currencySign, price, isDesktop } from '../../lib/utils'
import { DesktopView } from '../../components/desktopView'

require('./index.css')

if (isDesktop()) {
	require('./desktop.css')
} else {
	require('./mobile.css')
}

class MyOrderDetails extends React.Component {
	render() {
		const { __ , orderDetails } = this.props
		const collection_time = moment(orderDetails.collection_time).format('DD/MM/YY LT')
		const collection_code = orderDetails.collection_code ? orderDetails.collection_code.substr(orderDetails.collection_code.length - 3) : null
		const items = orderDetails.items && orderDetails.items.length > 0 ? orderDetails.items : []
		const currency = orderDetails.currency && orderDetails.currency !== '' ? orderDetails.currency : ''
		const formatPrice = price => currency === 'gbp' ? currencySign(currency) + price : price + currencySign(currency)
        return (
				<Layout headerTitle={ __('Order') + '    ' + orderDetails.id } color={isDesktop() ? 'transparent' : 'white'} contentClassName={ isDesktop() ? "animated-gif-01" : "" }  noPadding={ true }>
					<DesktopView>
						<div className='order-details-header'>{ __('Order Details') }</div>
					</DesktopView>
					<div className={isDesktop() ? 'desktop-order-details-wrapper' : ''}>
						<DesktopView>
							<div className="details-title">{ __('Order Complete') }</div>
						</DesktopView>
						<IonGrid className={isDesktop() ? 'order-details-grid' : ''}>
							<IonRow className="row">
								<IonCol><h2>{ __('Restaurant:') }</h2></IonCol>
								<IonCol className="end"><h2>{orderDetails.restaurant_name}</h2></IonCol>
							</IonRow>
							<IonRow className="row">
								<IonCol size="8"><h2>{ __('Collection Code:') }</h2></IonCol>
								<IonCol size="4" className="end"><h2>{collection_code}</h2></IonCol>
							</IonRow>
							<IonRow className="row">
								<IonCol><h2>{ __('Date:') }</h2></IonCol>
								<IonCol className="end"><h2>{collection_time}</h2></IonCol>
							</IonRow>
							<IonRow className="row">
								<IonCol><h2>{ __('Order Status:') }</h2></IonCol>
								<IonCol className="end">
									<div className={isDesktop() ? 'order-details-status' : 'payment'}>
										<p>{ __(orderDetails.status) }</p>
									</div>
								</IonCol>
							</IonRow>
						</IonGrid>
						<div className="menu-box">
							<IonList lines='none'>
								{(items || []).map((item, index) => {
									const itemPrice = price(item.price * item.qty)
									return (
										<div key={index} className='menu-box-item'>
											<IonItem>
												<div className='menu-box-item-content'>
													<IonItem>
														<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
															<IonLabel>{item.qty}x {item.name}</IonLabel>
														</div>
														<h2>{itemPrice ? formatPrice(itemPrice) : null}</h2>
													</IonItem>
													<IonList className='menu-box-sub-items' lines='none'>
														{ (item.sub_items || []).map((sub_item, idx) => {
															const subItemPrice = price(sub_item.item_qty ? sub_item.item_price * item.qty * sub_item.item_qty : sub_item.item_price * item.qty)
															return (
																<IonItem key={idx}>
																	<div className='menu-box-subItem-content'>
																		<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
																			<IonLabel slot='start'>+{sub_item.item_qty ? sub_item.item_qty * item.qty + 'x' : item.qty > 1 ? item.qty + 'x': null} {sub_item.item_name}</IonLabel>
																		</div>
																		<h2>{ subItemPrice ? '+ ' + formatPrice(subItemPrice) : null }</h2>
																	</div>
																</IonItem>
															)
														})}
													</IonList>
												</div>
											</IonItem>
											{ item.notes && item.notes !== '' ? (
												<div className="munu-box-total notes">
													<IonLabel>{ __('Special Notes and Allergies') }:</IonLabel>
													<h2>{item.notes}</h2>
												</div>
											) : null }
										</div>
									)
								})}
							</IonList>
							<div className="total-box">
								<h2>Total</h2>
								<h2>{formatPrice(total(items))}</h2>
							</div>
						</div>
					</div>
			</Layout>
		)
	}
}

const stateToProps = (state) => {
	const { order } = state
	return {
		orderDetails: order.orderDetails
	}
}

export default connect(stateToProps)(withRouter(withTranslation(MyOrderDetails)))
