import { Plugins } from '@capacitor/core'
// const { Device } = Plugins

const appConfig = {
	theme: {
		nav: {
			label: 'Island Poke'
		}
	},
	services: {
		google_api_key: 'AIzaSyBVSUVG0Q1qndc1dSCxcGUZcsycARwebvI',
		stripe_key: 'pk_live_AwP170yNwqJ6wOzAC6NYyls0000x1e3y83',
		// stripe_key: 'pk_test_4lYq523b8IJGzFQ4DlljA8sY00H7OO3dQe',
		// stripe_key: 'pk_test_uzpYDbwuWA4RfifpRlyB52VY00FJ1qjdUC', //Olijev
		// stripe_key: 'pk_test_SfLlfP41LhHVIG2vrA95ZrFt', //Kole
		spotify: 'http://open.spotify.com/user/islandpoke',
		merchantIdentifier: 'merchant.com.islandpoke'
	},
	social: {
		facebook: 'https://www.facebook.com/islandpoke/',
		instagram: 'https://www.instagram.com/islandpokeuk',
		website: 'https://www.islandpoke.co.uk/',
		facebook_label: 'islandpoke',
		instagram_label: 'islandpokeuk',
		website_label: 'www.islandpoke.co.uk'
	},
	api: {
		baseURL: 'https://islandpoke-web.herokuapp.com',
		// baseURL: 'http://192.168.8.104:8000', //preda local,
		// baseURL: 'https://9faafdbf.ngrok.io', //preda,
		wordPressBaseURL: 'https://hybridapp.co.uk',
		paymentURL: 'https://pay-mo.co.uk'
	},
	general: {
		appExitRoutes: ['/','/home','/dashboard'],
		emptyString: '--',
		defaultCurrency: 'eur',
		default_country_code: 'fr'
	},
	localization: {
		defaultLocale: 'en',
		supportedLocales: {
			'en': 'English',
			'fr': 'français'
		}
	},
	configS3: {
		url: 'https://island-poke.s3-eu-west-1.amazonaws.com/'
	},
	firebaseConfig: {
		apiKey: 'AIzaSyB0ZiaxzyUiCL_CgKTNLGvA_He5lWm07ec',
		authDomain: 'island-poke-e7984.firebaseapp.com',
		databaseURL: 'https://island-poke-e7984.firebaseio.com',
		projectId: 'island-poke-e7984',
		storageBucket: 'island-poke-e7984.appspot.com',
		messagingSenderId: '903302470924',
		appId: '1:903302470924:web:e0ce57195f801d0ec47d29'
	}
}

let language = window.navigator.userLanguage || window.navigator.language
let sysLanguage = language && language.indexOf('-') !== -1 ? language.substr(0,2) : language

const sysLocale = sysLanguage
if (appConfig.localization.supportedLocales[sysLocale]){
	appConfig.localization.defaultLocale = sysLocale
}


export default appConfig
