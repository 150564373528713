import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { IonButton, IonModal } from '@ionic/react'
import Alert from '../../../components/alert'
import Layout from '../../../components/layout'
import { withTranslation } from '../../../lib/translate'
import moment from '../../../lib/moment'
import { dataRequest, dataClearError, showToast, setLocationAndTime, clearBasket, dataClearContent, setModal } from '../../../store/actions'
import { forwardTo, sortLocations, isDefined, isObject, isArray } from '../../../lib/utils'
import SimpleLoader from '../../../components/simpleLoader'
import Mobiscroll from '../../../components/mobiscroll'

const { SelectOption } = Mobiscroll

const period = 5
//[ 0, 5, 10, 15, 20, 25, 30, 35, 45, 50, 55 ]
const collection_minutes = []
let minutes = -period
while (minutes < 60) {
	minutes += period
	if (minutes < 60) {
		collection_minutes.push(minutes)
	}
}
// const customTime = '2020-06-23T01:00:10.546+01:00'

const createMomentFromTime = (time='') => {
	const parsedTime = time.split(':')
	if (parsedTime.length !== 2 || time === '') {
		return null
	}
	let hour = parseInt(time.split(':')[0])
	let minutes = parseInt(time.split(':')[1])
	return moment().hours(hour).minutes(minutes)
}

const toWhichSegmentTimeBelongs = (time, segments = []) => {
	let timeIsInSegment = -1
	segments.forEach((segment, index) => {
		const { start, end } = segment
		const targetTime = createMomentFromTime(time)
		const segmentStart = createMomentFromTime(start)
		const segmentEnd = createMomentFromTime(end)
		if (targetTime.isSameOrAfter(segmentStart) && targetTime.isSameOrBefore(segmentEnd)) {
			timeIsInSegment = index
		}
	})
	return timeIsInSegment
}

const isTimeInSegment = (time, segments = []) => {
	return toWhichSegmentTimeBelongs(time, segments) !== -1
}

const addSegment = (start, end, segments=[], date) => {
	let updatedSegments = [ ...segments ]
	const dayNumber = 'w' + date.day()
	const newSegment = { 'd': dayNumber, 'start': start, 'end': end }

	// check previously added segment. maybe some of them are handled with new added segment
	const oldSegments = [ ...segments ]
	oldSegments.forEach((oldSegment, index) => {
		if (isTimeInSegment(oldSegment.start, [ newSegment ]) && isTimeInSegment(oldSegment.end, [ newSegment ])) {
			updatedSegments = removeSegment(index, updatedSegments)
		}
	})
	return [ ...updatedSegments, newSegment ]
}

const updateSegment = (segmentIndex, propName, value, segments=[]) => {
	let updatedSegments = [ ...segments ]
	if (updatedSegments && updatedSegments[segmentIndex]) {
		updatedSegments[segmentIndex][propName] = value
	}
	return updatedSegments
}

const removeSegment = (segmentIndexForRemove, segments=[]) => [ ...segments ].map((segment, index) => index === segmentIndexForRemove ? null : segment).filter(segment => isDefined(segment))

const parseTimesJson = (json=[], date) => {
	let parsed_json = []
	const dayNumber = 'w' + date.day()

	// use old logic for 'json_time_selector' json (without: menuId and availabity)
	if (json.length > 0 && !json[0].menuId) {
		return json
	}

	json.forEach(menu => {
		(menu.availability || []).filter(i => i.d === dayNumber).forEach(time => {
			const { start, end } = time
			if (parsed_json.length === 0) {
				// add first available time
				parsed_json = addSegment(start, end, parsed_json, date)
			} else {
				if (!isTimeInSegment(start, parsed_json) && !isTimeInSegment(end, parsed_json)) {
					// case 1: start and end dont belong to any other segment
					parsed_json = addSegment(start, end, parsed_json, date)
				} else if (isTimeInSegment(start, parsed_json) && !isTimeInSegment(end, parsed_json)) {
					// case 2: start belong to some segment and end dont bolong to any segment
					const segmentIndex = toWhichSegmentTimeBelongs(start, parsed_json)
					parsed_json = updateSegment(segmentIndex, 'end', end, parsed_json)
				} else if (!isTimeInSegment(start, parsed_json) && isTimeInSegment(end, parsed_json)) {
					// case 3: end belong to some segment and start dont bolong to any segment
					const segmentIndex = toWhichSegmentTimeBelongs(end, parsed_json)
					parsed_json = updateSegment(segmentIndex, 'start', start, parsed_json)
				} else if (isTimeInSegment(start, parsed_json) && isTimeInSegment(end, parsed_json)) {
					// case 4: and start and end belongs to some segment
					const startSegmentIndex = toWhichSegmentTimeBelongs(start, parsed_json)
					const endSegmentIndex = toWhichSegmentTimeBelongs(end, parsed_json)
					if (parsed_json && parsed_json[startSegmentIndex] && parsed_json[endSegmentIndex]) {
						const newStartTime = parsed_json[startSegmentIndex].start
						const newEndTime = parsed_json[endSegmentIndex].end

						if (startSegmentIndex !== endSegmentIndex) {
							parsed_json = addSegment(newStartTime, newEndTime, parsed_json, date)
							parsed_json = removeSegment(startSegmentIndex, parsed_json)
							parsed_json = removeSegment(endSegmentIndex, parsed_json)
						}
					}
				}
			}
		})
	})

	// sort times by 'start' time
	return parsed_json.sort((a, b) => {
		const aStart = createMomentFromTime(a.start)
		const bStart = createMomentFromTime(b.start)
		return bStart.isSameOrBefore(aStart) ? 1 : -1
	})
}

/*
menusForLocation: [{menuName: "Winter Menu 2014", ikentooMenuId: 37082747671397}, ...]
pickTime: 12:45
json_time_selector: [{
	"menuId": 37082747671609,
	"availability": [
		{ "d": "w1", "start": "07:15", "end": "15:45" },
		{ "d": "w2", "start": "07:15", "end": "15:45" },
		{ "d": "w3", "start": "07:15", "end": "15:45" },
		{ "d": "w4", "start": "07:15", "end": "15:45" },
		{ "d": "w5", "start": "07:15", "end": "15:45" }
	]
}, ... ]
*/
export const getMenusForSelectedTime = (menusForLocation = [], pickTime, json_time_selector = []) => {
	if (isObject(menusForLocation) && !isArray(menusForLocation)) {
		menusForLocation = [ menusForLocation ]
	}
	return menusForLocation.filter(menu => {
		const ikentooMenuId = menu.ikentooMenuId

		if (json_time_selector.length > 0 && json_time_selector[0].menuId && pickTime) {
			const target_menu = json_time_selector.find(i => i.menuId === ikentooMenuId)
			if (target_menu && isTimeInSegment(pickTime, target_menu.availability.filter(i => i.d === 'w' + moment().day()))) {
				return true
			}
		} else {
			// handle old json_time_selector (without menuId and availability)
			return true
		}

		return false
	})
}

class Orders extends React.Component {
	state = {
		stores: [],
		form: {
			storeId: null,
			pickTime: null
		},
		mylatitude: null,
		mylongitude: null,
		showAlert: false,
		locationLoader: true
	}

	handleInput = (key, val) => {
		const { basket, dispatch } = this.props
		let items = basket ? basket.items : null

		if (items && items.length > 0) {
			this.setState({ showAlert: true })
		} else {
			// if (key === 'pickTime' && val) {
			// 	const m = moment().utcOffset(0)
			// 	const arr = val.split(':')
			// 	m.set({ minute: parseInt(arr[1], 10), hour: parseInt(arr[0], 10) })
			// 	val = m.toISOString()
			// }
			this.setState({ form: { ...this.state.form, [key]: val }}, () => {
				if (key === 'storeId') {
					//clear previously fatched data
					dispatch(dataClearContent('productAvailability'))
				}
			})
		}
	}

	clearBasket = () => {
		const { dispatch } = this.props
		dispatch(clearBasket())
		this.setState({ showAlert: false })
	}

	closeAlert = () => {
		this.setState({ showAlert: false })
	}

	handleData = () => {
		const { data, apiMethod, dispatch, myLocation } = this.props
		const { errors } = data
		const error = errors[apiMethod]

		if (error){
			dispatch(showToast(error.message, 'danger'))
			dispatch(dataClearError(apiMethod))
		} else {
			const { content } = data
			if (content && content[apiMethod]){
				let rawStores = content[apiMethod]
				rawStores.forEach(rs => {
					const [lat, lng] = rs.position.split(',')
					rs.latitude = parseFloat(lat)
					rs.longitude = parseFloat(lng)
				})
				this.setState({ stores: sortLocations(rawStores, myLocation) })
			}
		}
	}

	componentDidUpdate (prevProps) {
		const { data, myLocation } = this.props
		if (prevProps.data !== data){
			this.handleData()
		}
		if (myLocation.latitude !== prevProps.myLocation.latitude || myLocation.longitude !== prevProps.myLocation.longitude) {
			this.setState({ stores: sortLocations(this.state.stores, myLocation) })
		}
	}

	componentDidMount() {
		const { data, apiMethod, dispatch } = this.props
		const { content } = data

		if (content && content[apiMethod]){
			this.handleData()
		} else {
			dispatch(dataRequest(apiMethod, {}))
		}
	}

	handleNext = () => {
		const { history, dispatch, data } = this.props
		let selectedTime = this.state.form.pickTime
		const restaurants = data.content.restaurants
		let restaurant = restaurants.find(res => this.state.form.storeId === res.id)
		if (selectedTime) {
			let m = moment()
			let arr = selectedTime.split(':')
			m.set({ minute: parseInt(arr[1], 10), hour: parseInt(arr[0], 10) })
			selectedTime = m.toISOString()
			dispatch(setLocationAndTime(this.state.form.storeId, selectedTime, restaurant))
			forwardTo(history, '/menu')
		}
	}

	formatDataForSelect = (stores) => {
		const { __ } = this.props
		let arrForSelect = []
		stores.forEach((store) => {
			if (isDefined(store.can_order)) {
				if (store.can_order) {
					arrForSelect.push({ text: store.name, value: store.id })
				}
			} else {
				arrForSelect.push({ text: store.name, value: store.id })
			}
		})
		return [{ text: __('Select Restaurant'), value: null }, ...arrForSelect]
	}

	formatDataForTime = (store, minDT) => {
		let timesInBetween = []
		let storeTimes = []
		const date = moment()
		const dow = 'w' + date.day()
		const period = store && store.order_slot_interval_mins ? store.order_slot_interval_mins : 5
		const collection_minutes = []
		let minutes = -period

		while (minutes < 60) {
			minutes += period
			if (minutes < 60) {
				collection_minutes.push(minutes)
			}
		}
		if (store) {
			parseTimesJson(store.collection_valid_times_json, date).forEach((time) => {
				if (time.d === dow) {
					storeTimes.push(time)
				}
			})
		}

		function makePickerText(times, j, i) {
			let collectionMinutes = parseInt(times[j]) < 10 ? '0' + times[j] : times[j]
			return { text: i < 10 ? '0' + i + ':' + collectionMinutes : i + ':' + collectionMinutes, value: i < 10 ? '0' + i + ':' + collectionMinutes : i + ':' + collectionMinutes }
		}

		function returnTimesInBetween(start, end, cnt) {
			let startH = parseInt(start.split(':')[0])
			let startM = parseInt(start.split(':')[1])
			let endH = parseInt(end.split(':')[0])
			let endM = parseInt(end.split(':')[1])
			let minTimeStart = parseInt(minDT.split(':')[0])
			let minTimeEnd = parseInt(minDT.split(':')[1])
			let c = collection_minutes.filter(cm => cm >= startM)
			let cm = collection_minutes.filter(cm => cm <= endM)
			let startHH = startH
			if (startHH <= minTimeStart) {
				startHH = minTimeStart
			}

			for (let i = startHH; i <= endH; i++) {
				if (startH === i) {
					for (let j = 0; j < c.length; j++) {
						if (c[j] >= minTimeEnd && cnt === 0 && startH <= minTimeStart) {
							timesInBetween.push(makePickerText(c, j, i))
						} else if (cnt !== 0) {
							timesInBetween.push(makePickerText(c, j, i))
						} else if (startH > minTimeStart) {
							timesInBetween.push(makePickerText(c, j, i))
						}
					}
				} else if (endH === i) {
					if (minTimeStart === i) {
						for (let j = 0; j < cm.length; j++) {
							if (cm[j] >= minTimeEnd) {
								timesInBetween.push(makePickerText(cm, j, i))
							}
						}
					} else {
						for (let j = 0; j < cm.length; j++) {
							timesInBetween.push(makePickerText(cm, j, i))
						}
					}
				} else {
					if (i === minTimeStart) {
						let collection_m = collection_minutes.filter(cm => cm >= minTimeEnd)
						for (let j = 0; j < collection_m.length; j++) {
							timesInBetween.push(makePickerText(collection_m, j, i))
						}
					} else {
						for (let j = 0; j < collection_minutes.length; j++) {
							timesInBetween.push(makePickerText(collection_minutes, j, i))
						}
					}
				}
			}

			//if we have no oppning times, just add 'CLOSED' label to the picker
			if (timesInBetween && timesInBetween.length > 1 && timesInBetween[0] && timesInBetween[0].text === 'CLOSED') {
				timesInBetween.shift()
			}
			//if we have no oppning times, just add 'CLOSED' label to the picker
			if (isDefined(store) && timesInBetween.length === 0) {
				timesInBetween.push({ text: 'CLOSED', value: null })
			}
			return timesInBetween
		}
		storeTimes.forEach((storeT, i, arr) => {
			returnTimesInBetween(storeT.start, storeT.end, i)
			let minH = parseInt(minDT.split(':')[0])
			let minM = parseInt(minDT.split(':')[1])
			let endTimeH = parseInt(storeT.end.split(':')[0])
			let endTimeM = parseInt(storeT.end.split(':')[1])
			let minTime = moment().hours(minH).minutes(minM)
			let timeEnd = moment().hours(endTimeH).minutes(endTimeM)
			if (i < arr.length - 1 && arr.length > 0 && moment(minTime).isSameOrBefore(timeEnd)) {
				timesInBetween.push({ text: 'CLOSED', value: null })
			}
		})

		//remove 'CLOSED' label if that is first time
		if (timesInBetween && timesInBetween.length > 1 && timesInBetween[0] && timesInBetween[0].text === 'CLOSED') {
			timesInBetween.shift()
		}
		if (timesInBetween.length === 0) {
			timesInBetween.push({ text: 'CLOSED', value: null })
		}
		return timesInBetween
	}

	render() {
		const { __, isCurrentLocationModalOpen } = this.props
		const { stores, form, showAlert } = this.state
		const { storeId, pickTime } = form
		const store = storeId ? stores.find(s => s.id === storeId) : null

		const currentDT = moment()

		const timeFormat = 'HH:mm'

		let minDT = currentDT

		if (store && isDefined(store.order_slot_lead_time)) {
			minDT.add(store.order_slot_lead_time, 'minutes')
		}

		const timePickerOptions = this.formatDataForTime(store, minDT.format(timeFormat))

		return (
			<>
				<Layout headerTitle={ __('New Order') } color="white">
					<>
						<label className="select-picker-label" htmlFor="demo-non-form">{ __('Pickup location') }*</label>
						<SelectOption
							display="center"
							onSet={ (e, a) => {
								this.handleInput('storeId', a.getVal())
								// this.handleInput('pickTime', null)
							}}
							data={ this.formatDataForSelect(stores) }
							label='Location'
							value={ this.state.form.storeId }
							inputStyle="box"
							placeholder={ __('Select Restaurant') }
							setText={ __('OK') }
							cancelText = { __('Cancel') }
						/>
						<label className="time-picker-label" htmlFor="demo-non-form">{ __('Collection time') }*</label>
						<SelectOption
							display="center"
							onSet={ (e, inst) => this.handleInput('pickTime', inst.getVal()) }
							data={ timePickerOptions }
							label="Location"
							value={ pickTime }
							inputStyle="box"
							placeholder={ __('Select one') }
							setText={ __('OK') }
							cancelText = { __('Cancel') }
							disabled={ this.state.form.storeId === null ? true : false }
							onInit={ () => {
								if (timePickerOptions.length > 0) {
									const firstAvailableTime = timePickerOptions.find(i => i.value !== null)
									if (!pickTime && firstAvailableTime && pickTime !== firstAvailableTime.value) {
										this.handleInput('pickTime', firstAvailableTime.value)
									}
								}
							}}
						/>
						<br />
						<IonButton expand="block" disabled={ pickTime ? false : true } color="tertiary" onClick={() => {this.handleNext()}}>{ __('Next')}</IonButton>
						<Alert showAlert={showAlert} closeAlert={ this.closeAlert } type='select' clearBasket={this.clearBasket} __ = { __ } />
					</>
				</Layout>
				<IonModal cssClass='location-modal' showBackdrop={ true } isOpen={ isCurrentLocationModalOpen } onDidDismiss={ () => this.props.dispatch(setModal('isCurrentLocationModalOpen', false)) }>
					<div>
						<SimpleLoader className="loader-background" showBackdrop={ true } loading={ true } transparent loadingMessage={ __('Locating nearest stores')+'...' }></SimpleLoader>
					</div>
				</IonModal>
			</>
		)
	}
}

const stateToProps = (state) => {
	const { data, order, common } = state
	return {
		data,
		apiMethod: 'restaurants',
		basket: order.basket,
		myLocation: common.myLocation,
		isCurrentLocationModalOpen: common.isCurrentLocationModalOpen
	}
}

export default connect(stateToProps)(withRouter(withTranslation(Orders)))
