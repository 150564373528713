import React from 'react'
import { withTranslation } from '../../lib/translate'
import { loading, showToast, resetToast, createOrder, addScannedCard } from '../../store/actions'
import { IonAlert, IonButton, IonGrid, IonRow, IonCol, IonInput, IonItem, IonList, isPlatform } from '@ionic/react'
import { FieldError } from '../../components/common'
import { validateForm, getLocale } from '../../lib/utils'
import { removePaymentCard } from '../../store/actions'
import api from '../../lib/api'
import { connect } from 'react-redux'
import { CardIO } from '@ionic-native/card-io/ngx'
import Stripe from '../../lib/stripe'
import './index.css'
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, ElementsConsumer } from '@stripe/react-stripe-js'
import { Capacitor } from '@capacitor/core'
import CardsList from './cardsList'
import MaskedInput from 'react-text-mask'
import moment from '../../lib/moment'
import Mobiscroll from '../../components/mobiscroll'
import Loading from '../spinner'

const { DatePicker } = Mobiscroll
const nameInputMask = Array(50).fill(/[a-z0-9A-Z ]/)

class _SplitForm extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			showAlert: false,
			name: '',
			cards: [],
			showAlert1: false,
			showAlert2: false,
			pay_token: null,
			remove_token: null,
			formErrors: {
				name: ''
			},
			scanNumber: null,
			scanExp: null,
			expiryMonth: null,
			expiryYear: null,
			scanCVC: null,
			scanBrand: null,
			scanaLast4: null,
			scanName: null,


			cardNumber: '',
			expiryDate: '',
			cvc: '',
			focus: {},
			numberOfReadyElements: 0,
			focusedInput: ''
		}

		this.cardIO = new CardIO()
	}

	componentDidMount = () => {
		if (this.props.cards && this.props.cards.length > 0) {
			this.setCardsArray(this.props.cards)
		}
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.cards !== this.props.cards) {
			this.setCardsArray(this.props.cards)
		}
	}

	clearScan = () => this.setState({ scanNumber: null, scanExp: null, scanCVC: null, scanBrand: null, scanLat4: null, scanName: null, expiryMonth: null, expiryYear: null })

	resetState = () => this.setState({ cardNumber: '', expiryDate: '', cvc: '', name: '' }, () => {
		this.clearScan()
		if (this.props.skipRedirect) {
			return
		}
	})

	handleScanCard = () => {
		const that = this
		const { dispatch, __ } = this.props
		this.cardIO.canScan().then(res => {
			if (res) {
				let options = {
					requireExpiry: true,
					requireCVV: true,
					requirePostalCode: false
				}
				this.cardIO.scan(options).then(card => {
					that.setState({
						cardNumber: card.cardNumber,
						expiryDate: card.expiryMonth + '/' + card.expiryYear,
						cvc: card.cvv
					})
				})
			}
		}).catch(function() {
			dispatch(showToast(__('Scan card problem'), 'warning'))
		})
	}

	createOrder = paymentType => {
		const { dispatch, history } = this.props
		this.setShowAlert1(false)
		const token = this.state.pay_token
		if (!token && !paymentType) { return }
		dispatch(resetToast())

		let additionalOrderData = {
			payment_token: token
		}
		dispatch(createOrder(additionalOrderData, { history, paymentType }))
	}

	drawPayButton = () => {
		const { __ } = this.props
		let ret = null
		if (Stripe.getStripeInstance() && Capacitor.platform !== 'web') {
			if (!isPlatform('ios')) {
				//android
				ret = <IonButton expand="block" color="secondary" onClick={ () => this.createOrder('google') }>{ __('Google Pay') }</IonButton>
			} else {
				//ios
				ret = <IonButton expand="block" color="secondary" onClick={ () => this.createOrder('apple') }>{ __('Apple Pay') }</IonButton>
			}
		}
		return ret
	}

	createCard = card => {
		const { dispatch } = this.props
		const { name } = this.state
		dispatch(loading(true))

		try {
			Stripe.getStripeInstance().createCardToken(card).then(token => {
				const cardToken = token.id //'tok_....'
				dispatch(addScannedCard(name, cardToken, { cb: () => {
					dispatch(loading(false))
					this.resetState()
				} }))
			}).catch(error => {
				dispatch(loading(false))
				dispatch(showToast(error, 'warning'))
			})
		} catch (e) {
			dispatch(loading(false))
		}
	}

	validateCardForm = field => {
		const formErrors = validateForm({
			cardNumber: { type: 'cardNumber', required: true },
			expiryDate: { required: true },
			cvc: { required: true }
		}, this.state)

		const errorsLength = Object.keys(formErrors).length
		if (errorsLength !== 0 && !field) {
			this.setState({ formErrors })
		} else if (errorsLength !== 0 && field) {
			this.setState({ formErrors: {
				...this.state.formErrors,
				[field]: formErrors[field]
			}})
		} else if (errorsLength === 0) {
			this.setState({ formErrors })
		}
		return formErrors
	}

	addPaymentCard = event => {
		event.preventDefault()
		const { cardNumber, expiryDate, cvc, name } = this.state
		const { stripe, elements } = this
		const { dispatch, __ } = this.props

		if (name === '') {
			dispatch(showToast(__('Please add a card nickname'), 'warning'))
			return
		}

		if (Capacitor.platform === 'web') {
			if (!stripe || !elements) {
				return
			}
			const that = this
			const cardNumberElement = elements.getElement(CardNumberElement)
			const expiryDateElement = elements.getElement(CardExpiryElement)
			const cvcElement = elements.getElement(CardCvcElement)
			stripe.createToken(cardNumberElement).then(function(result) {
				if (result.error) {
					dispatch(showToast(__(result.error.message), 'warning'))
				} else {
					if (result.token && result.token.card) {
						const cardToken = result.token.id
						dispatch(addScannedCard(name, cardToken, { cb: () => {
							that.resetState()
							cardNumberElement.clear()
							expiryDateElement.clear()
							cvcElement.clear()
						} }))
					}
				}
			})
		} else {
			const formErrors = this.validateCardForm()

			if (Object.keys(formErrors).length !== 0) {
				this.setState({ formErrors })
			} else {
				const arr = expiryDate.split('/')
				const month = arr[0]
				const year = arr[1].length === 2 ? arr[1] : arr[1].length === 4 ? arr[1].substr(2, 4) : arr[1]
				const card = {
					number: (cardNumber + '').replace(/\s+/g, ''),
					exp_month: month,
					exp_year: year,
					cvc: cvc
				}
				this.createCard(card)
			}
		}
	}

	addScanedCard = () => {
		const { scanNumber, expiryMonth, expiryYear, scanCVC, scanBrand } = this.state
		const { dispatch, __ } = this.props
		let card = {
			number: scanNumber,
			expMonth: expiryMonth,
			expYear: expiryYear,
			cvc: scanCVC
		}
		dispatch(loading(true))
		Stripe.getStripeInstance().createCardToken(card).then(token => {
			const cardToken = token.id //'tok_....'
			const that = this
			let data = {
				payment_token: cardToken,
				name: this.state.scanName || __('scaned ') + scanBrand
			}

			api.createPaymentCard(data).then(function(result) {
				dispatch(loading(false))
				if (result.error) {
					dispatch(showToast(result.error.message, 'warning'))
				} else {
					dispatch(showToast(__('Card added successfully1111'), 'success'))
					that.setCardsArray(result.data.cards.data)
					that.clearScan()
				}
			}).catch(function() {
				// rejection
				dispatch(loading(false))
				dispatch(showToast(__('Problem adding card'), 'warning'))
			})
		}).catch(error => {
			dispatch(loading(false))
			console.error(error)
		})
	}

	setCardsArray = data => {
		this.setState({ cards: (data || []).map(item => {
			return {name: item.name, last4: item.last4, card_token: item.id, brand: item.brand, exp: item.exp_month + '/' + item.exp_year }
		}) })
	}

	removePaymentCardInit = token => this.setState({ showAlert2: true, remove_token: token })

	handleSubmitInit = token => this.setState({ showAlert1: true, pay_token: token })

	removePaymentCard = () => {
		const { __ } = this.props
		this.setState({ removeCardAlert: null }, () => {
			this.props.dispatch(removePaymentCard(this.state.remove_token, { __ }))
		})
	}

	setShowAlert1 = val => this.setState({ showAlert1: val })

	setShowAlert2 = val => this.setState({ showAlert2: val })

	handleInput = (key, val) => this.setState({[key]: val })

	setInputFocus = (inputName, flag) => this.setState({ focus: { ...this.state.focus, [inputName]: flag }})

	getInputClass = inputName => {
		const base = 'item-interactive item-input item-has-placeholder item md in-list ion-focusable hydrated masked-input '
		return base + (this.state.focus[inputName] ? 'item-has-focus' : '')
	}

	elementReady = () => this.setState({ numberOfReadyElements: this.state.numberOfReadyElements + 1 })

	setFocusedInput = (inputName = '') => this.setState({ focusedInput: inputName })

	scannedCardNameModal = () => {
		const { scanNumber, scanName, scanBrand, scanLat4, scanExp, formErrors } = this.state
		const { __ } = this.props
		return scanNumber !== null ?
			<div style={{ marginBottom: '40px' }}>
				<h2 style={{ textAlign: 'center', marginTop: 0 }}>{ __('Scanned card') }</h2>
				<IonGrid>
					<IonRow>
						<IonCol size="12" className="card_label">
							<div className="checkout-input" style={{ marginBottom: 0, width: '100%' }}>
								<label>
									{ __('Card Name') }
									<IonInput className="card_name" onIonChange={ e => this.handleInput('scanName', e.target.value) } required={ false } type="text" inputmode="text" value={ scanName } />
									<FieldError className="field-error" value={ __(formErrors.scanName) } />
								</label>
							</div>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12" className="card_label">
							{ scanBrand }, { scanLat4 }, exp. { scanExp }
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="6"><IonButton expand="block" color="secondary" onClick={ () => this.addScanedCard() }>{ __('Save') }</IonButton></IonCol>
						<IonCol size="6"><IonButton expand="block" color="tertiary" onClick={ () => this.clearScan() }>{ __('Cancel') }</IonButton></IonCol>
					</IonRow>
				</IonGrid>
			</div> : null
	}

	render() {
		const { __, profile, loading } = this.props
		const { name, formErrors, cards, showAlert1, showAlert2, cardNumber, expiryDate, cvc, numberOfReadyElements, focusedInput } = this.state
		const inputStyle = { style: { base: { fontSize: '15px' }}}
		const showStripeLoading = numberOfReadyElements < 3 && loading === 0 && Capacitor.platform === 'web'
		const CardNameInput = <IonItem className={ 'card-name-input ' + this.getInputClass('name') }>
			<MaskedInput
				mask={ nameInputMask }
				className="payment-card-input"
				placeholder={ __('Card Name') }
				guide={ false }
				onChange={ e => { this.handleInput('name', e.target.value) } }
				onFocus={ () => { this.setInputFocus('name', true) } }
				onBlur={ () => { this.setInputFocus('name', false) } }
				value={ name }
			/>
		</IonItem>
		const pageContent =
			<div className="pad20" id="checkout-form">
				<h2 className='saved-cards-header'>{ __('Your saved cards') }</h2>
				<CardsList cards={ cards } handlePay={ this.handleSubmitInit } handleRemove={ this.removePaymentCardInit }/>
				{ this.scannedCardNameModal() }
				{ this.drawPayButton() }
				{/* <IonButton expand="block" style={{ marginBottom: '20px' }} color="secondary" onClick={ this.handleScanCard }>{ __('Scan Card') }</IonButton> */}
				<form onSubmit={ this.addPaymentCard }>
					<div className="flex-row-wrapper absolute-content">
						<div className="scrollable-y add-card-scroll">
							{ showStripeLoading ? <Loading style={{ position: 'relative' }} loaderStyle={{ color: 'var(--ion-color-primary)' }} additionalLoadingCondition={ showStripeLoading } transparent transparentForce><span/></Loading> : null }
							<IonList className= "card-list">
								{ Capacitor.platform !== 'web' ?
									<>
										{ CardNameInput }
										<IonItem className={ this.getInputClass('cardNumber') }>
											<MaskedInput
												mask={ [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/] }
												className="payment-card-input"
												placeholder={ __('Card Number') }
												guide={ false }
												onChange={ e => { this.handleInput('cardNumber', e.target.value) } }
												onFocus={ () => { this.setInputFocus('cardNumber', true) } }
												onBlur={ () => { this.setInputFocus('cardNumber', false) } }
												value={ cardNumber }
												type= "tel"
											/>
										</IonItem>
										{ formErrors.cardNumber ? <FieldError className="field-error" value={ __(formErrors.cardNumber) } />: null }
										<div className="flex-col-wrapper">
											<IonItem>
												<DatePicker
													className="data-picker-input"
													display="bottom"
													setText="Done"
													value={ expiryDate }
													onSet={(e, a) => this.handleInput('expiryDate', a.element.value)}
													min = { moment() }
													placeholder={ __('Expiry date') }
													inputStyle="box"
													dateFormat="mm/yyyy"
												/>
											</IonItem>
											<div className="flex-spacer"/>
											<div>
												<IonItem className={ this.getInputClass('cvc') }>
													<MaskedInput
														mask={ [/\d/, /\d/, /\d/, /\d/] }
														className="payment-card-input"
														placeholder={ __('CVV') }
														guide={ false }
														onChange={ e => { this.handleInput('cvc', e.target.value) } }
														onFocus={ () => { this.setInputFocus('cvc', true) } }
														onBlur={ () => { this.setInputFocus('cvc', false) } }
														value={ cvc }
														type= "tel"
													/>
												</IonItem>
												{ formErrors.cvc ? <FieldError className="field-error" value={ __(formErrors.cvc) } />: null }
											</div>
										</div>
									</>
									:
									<div style={{ display: numberOfReadyElements >= 3 ? 'block' : 'none' }}>
										<label>{ __('Card number') }</label>
										<CardNumberElement
											className={ 'web-stripe-input ' + (focusedInput === 'cardNumber' ? 'web-stripe-input-active' : '') }
											options={{ ...inputStyle }}
											onReady={ this.elementReady }
											onFocus={ () => this.setFocusedInput('cardNumber') }
											onBlur={ () => this.setFocusedInput() }
										/>
										<label>{ __('Expiration date') }</label>
										<CardExpiryElement
											className={ 'web-stripe-input ' + (focusedInput === 'expiry' ? 'web-stripe-input-active' : '') }
											options={{ ...inputStyle }}
											onReady={ this.elementReady }
											onFocus={ () => this.setFocusedInput('expiry') }
											onBlur={ () => this.setFocusedInput() }
										/>
										<label>{ __('CVC') }</label>
										<CardCvcElement
											className={ 'web-stripe-input ' + (focusedInput === 'cvc' ? 'web-stripe-input-active' : '') }
											options={{ ...inputStyle }}
											onReady={ this.elementReady }
											onFocus={ () => this.setFocusedInput('cvc') }
											onBlur={ () => this.setFocusedInput() }
										/>
										<label>{ __('Card Name') }</label>
										{ CardNameInput }
									</div>
								}
							</IonList>
						</div>
						<div className="flex-min">
							<IonButton expand="block" color="tertiary" type="submit">{ __('Add New Card') }</IonButton>
						</div>
					</div>
				</form>
				<IonAlert
					isOpen={ showAlert1 }
					onDidDismiss={ () => this.setShowAlert1(false) }
					header={ __('Confirm') }
					message={ __('Do you want to pay using this card?') }
					buttons={[
						{ text: __('Cancel'), role: 'cancel', cssClass: 'secondary' },
						{ text: __('Pay'), handler: () => this.createOrder() }
					]}
				/>
				<IonAlert
					isOpen={ showAlert2 }
					onDidDismiss={ () => this.setShowAlert2(false) }
					header={ __('Confirm') }
					message={ __('Do you you want to remove this card?') }
					buttons={[
						{ text: __('Cancel'), role: 'cancel', cssClass: 'secondary' },
						{ text: __('Remove'), handler: () => this.removePaymentCard() }
					]}
				/>
			</div>

		return Capacitor.platform === 'web' ?
			<Elements stripe={ Stripe.getStripeInstance() } options={{ locale: getLocale(profile) }}>
				<ElementsConsumer>
					{({ elements, stripe }) => {
						this.elements = elements
						this.stripe = stripe
						return pageContent
					}}
				</ElementsConsumer>
			</Elements> : pageContent
	}
}

const stateToProps = store => {
	const { order } = store
	const { auth, profile } = store.auth
	const { loading } = store.common
	const { cards } = order

	return {
		loading,
		auth,
		profile,
		order,
		cards: cards || []
	}
}

export const SplitForm = connect(stateToProps)(withTranslation(_SplitForm))
