import {
	SENDING_REQUEST,
	REQUEST_SUCCESS,
	REQUEST_ERROR,
	CLEAR_ERROR,
	LOADING,
	SHOW_TOAST,
	RESET_TOAST,
	SET_MODAL,
	INIT,
	RIGHT_DRAWER,
	RESET_PASSWORD,
	SET_COMMON_PROP,
	SEND_POST_CODE
} from './constants'

export const sendingRequest = sending => ({ type: SENDING_REQUEST, sending })

export const requestSuccess = success => ({ type: REQUEST_SUCCESS, success })

export const requestError = error => ({ type: REQUEST_ERROR, error })

export const clearError = () => ({ type: CLEAR_ERROR })

export const loading = flag => ({ type: LOADING, loading: flag })

export const showToast = (message, toastType) => ({ type: SHOW_TOAST, message, toastType })

export const resetToast = () => ({ type: RESET_TOAST })

export const setModal = (modal, value) => ({ type: SET_MODAL, modal, value })

export const toggleRightDrawer = () => ({ type: RIGHT_DRAWER })

export const init = () => ({ type: INIT })

export const resetPassword = email => ({ type: RESET_PASSWORD, email })

export const setMyLocation = (data) => ({ type: SET_COMMON_PROP, key: 'myLocation', value: data })

export const sendPostCode = (data, history) => ({ type: SEND_POST_CODE, data, history })
