import React from 'react'
import { IonSpinner } from '@ionic/react'
import './index.css'
import { isDesktop } from '../../lib/utils'

export default ({ loading, children, transparent, loadingMessage }) => {
	return (
		<>
			{ transparent && loading ?
				<>
					<div className="simple-loader">
					{
						isDesktop() ?
						<div className="loader"></div>
						:
						<div className='transparent-loader'>
							<IonSpinner color="primary-shade" name="bubbles"></IonSpinner>
							<div className='message'><h2>{loadingMessage}</h2></div>
						</div>
					}
					</div>
					{ children }
				</>
				:
				<>
					{ children }
				</>
			}
		</>
	)
}
