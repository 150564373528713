import {
	DATA_REQUEST,
	DATA_SUCCESS,
	DATA_ERROR,
	DATA_CLEAR_ERROR,
	DATA_CLEAR_CONTENT
} from './constants'

import { deepCopy, isDefined } from '../../lib/utils'

let initialState = {
	loading: false,
	liveRequests: 0,
	content: {},
	errors: {}
}

let liveRequests
let errors
let content

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case DATA_REQUEST: {
		liveRequests = state.liveRequests < 0 ? 1 : state.liveRequests + 1
		return { ...state, liveRequests, loading: true }
	}
	case DATA_SUCCESS:
		liveRequests = state.liveRequests - 1 < 0 ? 0 : state.liveRequests - 1
		errors = state.errors
		if (errors[action.dataType]){
			delete errors[action.dataType]
		}
		return { ...state, errors, liveRequests, loading: liveRequests > 0, content: { ...state.content, [action.dataType]: action.data }}
	case DATA_ERROR:
		liveRequests = state.liveRequests - 1 < 0 ? 0 : state.liveRequests - 1
		return { ...state, errors: {[action.dataType]: action.error }, liveRequests, loading: liveRequests > 0 }
	case DATA_CLEAR_CONTENT: {
		const newContent = deepCopy(state.content)
		if (action.apiMethod && isDefined(newContent[action.apiMethod])) {
			delete newContent[action.apiMethod]
		}
		return { ...state, content: newContent }
	}
	case DATA_CLEAR_ERROR:
		errors = state.errors
		if (errors[action.dataType]){
			delete errors[action.dataType]
		}
		content = state.content
		if (state.content[action.dataType]) {
			delete state.content[action.dataType]
		}
		return { ...state, errors, content }
	default:
		return state
	}
}

export default reducer
