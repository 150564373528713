import React from 'react'
import { withRouter } from 'react-router'
import { IonButton, IonIcon } from '@ionic/react'
import * as icons from 'ionicons/icons'
import { isDefined } from '../../lib/utils'

const ArrowButton = props => {
	const { history, path, style, className, type, onBackHandler, onForwardHandler } = props
	return (
		<IonButton className={ className ? className : null } button clear
			onClick={() => {
				if (path) {
					history.push({ pathname: path })
				} else if (isDefined(onBackHandler)) {
					onBackHandler()
				} else if (isDefined(onForwardHandler)) {
					onForwardHandler()
				} else {
					history.goBack()
				}
			//path ? history.push({ pathname: path }) : history.goBack()
			}}
			style={ style ? { ...style } : {}}>
			<IonIcon slot="icon-only" icon={ icons[type] }/>
		</IonButton>
	)
}

export default withRouter(ArrowButton)
