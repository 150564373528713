import axios from 'axios'
import asyncStorage from './asyncStorage'
import { isDefined } from './utils'
import appConfig from '../appConfig'

const wordPressBaseURL = appConfig.api.wordPressBaseURL
const baseURL = appConfig.api.baseURL
const endpoints = {
	login: '/api-token-auth',//post
	register: '/me/register',//post
	getProfile: '/me',//get
	changePassword: '/me',//put
	changeProfile: '/me',//put
	resetPassword: '/me/reset_password',//put
	deleteCard: '/delete_payment_card',//put
	voucherCode: '/voucher/code',//put
	referFriend: '/refer_friend',//post
	restaurants: '/restaurants',//get
	sendFeedback: '/feedback/create',//post,
	sendVoucherCode: '/voucher/code',//put,
	getVouchers: '/me/vouchers',//get
	firebaseToken: '/firebase-token',//post
	allergenInfo: '/allergen_info',//get
	productAvailability: '/product/availability',//get
	terms: '/terms',//get,
	sendMobile: '/send_sms_password',//put
	mobileCheckSms: '/check_sms_password',//put
	confirmPayment: '/transactions/create_payment',//get,
	fcmToken: '/register_gcm_device', //post
	createOrder: '/order/create', //get,
	createPaymentCard: '/orders/create_payment_card', //post
	removePaymentCard: '/orders/delete_payment_card', //post
	listCards: '/orders/list_payment_cards', //post
	myOrders: '/me/orders',
	checkDiscount: '/order/check_discount',//post
	getGeoposition: '/get_geoposition', //post
	addPaymentCard: '/orders/create_payment_card', //post
	getPaymentCards: '/orders/list_payment_cards', //post
	get_envs: '/get_envs' //get
}

/*const paymentEndpoints = {
	registerCard: '/register_card.php'
}*/

let axiosInstance
export const createAxiosInstance = token => {
	axiosInstance = axios.create({
		headers: token ? {
			Authorization: 'JWT ' + token
		} : {},
		timeout: 30000,
		responseType: 'json'
	})
	return axiosInstance
}

(async () => {
	const existingToken = await asyncStorage.getItem('token')
	axiosInstance = createAxiosInstance(existingToken)
})()

export const isFieldValid = (fieldName, errors = {}) => !isDefined(errors[fieldName])

export const getErrorMessage = (fieldName, errors = {}) => !isFieldValid(fieldName, errors) ? errors[fieldName] : ''

const api = {
	login: async (username, password, sysLocale, cb) => {
		const response = await createAxiosInstance().post(baseURL + endpoints.login, { username, password, sysLocale })
		axiosInstance = createAxiosInstance(response.data.token)
		if (cb) {
			cb(response.data)
		}
		return response
	},
	register: async (userData, cb) => {
		const response = await createAxiosInstance().post(baseURL + endpoints.register, userData)
		axiosInstance = createAxiosInstance(response.data.token)
		if (cb) {
			cb(response.data)
		}
		return response
	},
	logout: () => {
		// eslint-disable-next-line no-console
		console.log('logout')
	},
	resetPassword: async email => await axiosInstance.put(baseURL + endpoints.resetPassword, {email}),
	getProfile: async () => await axiosInstance.get(baseURL + endpoints.getProfile),
	updateProfile: async (data={}) => await axiosInstance.put(baseURL + endpoints.changeProfile, data),
	sendFirebaseToken: async data => await axiosInstance.post(baseURL + endpoints.firebaseToken, data),
	allergenInfo: async () => await axiosInstance.get(baseURL + endpoints.allergenInfo, { responseType: 'text' }),
	terms: async () => await axiosInstance.get(baseURL + endpoints.terms, { responseType: 'text' }),
	sendFeedback: async data => await axiosInstance.post(baseURL + endpoints.sendFeedback, data),
	sendRefer: async data => await axiosInstance.post(baseURL + endpoints.referFriend, data),
	restaurants: async () => await axiosInstance.get(baseURL + endpoints.restaurants, { responseType: 'text' }),
	sendCode: async data => await axiosInstance.put(baseURL + endpoints.sendVoucherCode, data),
	productAvailability: async data => await axiosInstance.put(baseURL + endpoints.productAvailability, data),
	sendMobile: async data => await axiosInstance.put(baseURL + endpoints.sendMobile, data),
	getVouchers: async () => await axiosInstance.get(baseURL + endpoints.getVouchers),
	mobileCheckSms: async data => await axiosInstance.put(baseURL + endpoints.mobileCheckSms, data),
	confirmPayment: async data => await axiosInstance.post(baseURL + endpoints.confirmPayment, data),
	createOrder: async data => await axiosInstance.post(baseURL + endpoints.createOrder, data),
	createPaymentCard: async data => await axiosInstance.post(baseURL + endpoints.createPaymentCard, data),
	removePaymentCard: async data => await axiosInstance.post(baseURL + endpoints.removePaymentCard, data),
	listCards: async data => await axiosInstance.post(baseURL + endpoints.listCards, data),
	fcmToken: async token => await axiosInstance.post(baseURL + endpoints.fcmToken, { registration_id: token, cloud_message_type: 'FCM' }),
	getMyOrders: async () => await axiosInstance.get(baseURL + endpoints.myOrders),
	checkDiscount: async data => await axiosInstance.post(baseURL + endpoints.checkDiscount, data),
	sendPostCode: async data => await axiosInstance.post(baseURL + endpoints.getGeoposition, data),
	addPaymentCard: cardData => axiosInstance.post(baseURL + endpoints.addPaymentCard, cardData).then(res => res.data.cards.data),
	getPaymentCards: () => axiosInstance.post(baseURL + endpoints.getPaymentCards).then(res => res.data.cards.data),
	getTranslations: app_domain => axios.create().get(wordPressBaseURL + '/en/dictionary' + '/' + app_domain).then(res => res.data),
	getEnvs: async () => await axiosInstance.get(baseURL + endpoints.get_envs)
}

export default { ...api }
