import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withTranslation } from '../../../lib/translate'
import { addToBasket, showToast } from '../../../store/actions'
import { isEmptyObject, forwardTo, isDefined, currencySign, price, protectedOrder } from '../../../lib/utils'
import { IonButton, IonIcon, IonList, IonItem, IonLabel, IonInput, IonToolbar, IonButtons, IonTitle, IonListHeader, IonRadioGroup, IonRadio } from '@ionic/react'
import Layout from '../../../components/layout'
import SimpleLoader from '../../../components/simpleLoader'
import Modal from '../../../components/modal'
import Alert from '../../../components/alert'
import appConfig from '../../../appConfig'
import * as icons from 'ionicons/icons'
import { close } from 'ionicons/icons'
import './index.css'

const s3Url = appConfig.configS3.url

export class WebByob extends Component {
	state = {
		selectedCategory: {},
		selectedProduct: null,
		showProductModal: false,
		showSaucesModal: false,
		is_byob: true,
		byob_type: 'is_base_it',
		base_it_items: [],
		top_it_items: [],
		finish_it_items: [],
		showAlert: false,
		typeOfAlert: '',
		quantity: 1,
		basket: {},
		notes: '',
		expanded: [],
		selectedSize: null,
		comboProduct: null,
		comboSequences: {},
		sauces: [],
		selectedSauce: {}
	}

	handleProtectOrder = () => {
		const { __, dispatch, history, restaurantId, collectionTime } = this.props

		if (!protectedOrder(restaurantId, collectionTime)) {
			dispatch(showToast(__('Please select the restaurant in order to build your own bowl') , 'warning'))
			forwardTo(history, '/locations')
		}
	}

	componentDidMount(){
		const { selectedCategory, location } = this.props
		const selectedSize = location.state && location.state.size ? location.state.size : null
		this.setState({ selectedCategory, selectedSize }, () => {
			let arr = [...this.state.expanded]
			Object.keys(this.state.selectedCategory.subCategories ? this.state.selectedCategory.subCategories : {}).forEach(key => {
				if (this.state.selectedCategory.subCategories[key].subCategory.attributes.is_base_it) {
					arr.push(this.state.selectedCategory.subCategories[key])
				}
			})
			this.setState({expanded: arr})
		})
	}

	componentDidUpdate(prevProps, prevState) {
		const { restaurantId, collectionTime } = this.props
		const { byob_type, expanded, selectedCategory } = this.state

		if (prevProps.restaurantId === restaurantId && prevProps.collectionTime === collectionTime && !restaurantId && !collectionTime) {
			this.handleProtectOrder()
		}

		if (prevState.byob_type !== byob_type && byob_type === 'is_top_it') {
			let arr = [...expanded]
			Object.keys(selectedCategory.subCategories ? selectedCategory.subCategories : {}).forEach(key => {
				if (selectedCategory.subCategories[key].subCategory.attributes.is_top_it) {
					arr.push(selectedCategory.subCategories[key])
				}
			})
			this.setState({expanded: arr})
		}

		if (prevState.byob_type !== byob_type && byob_type === 'is_finish_it') {
			let arr = [...expanded]
			Object.keys(selectedCategory.subCategories ? selectedCategory.subCategories : {}).forEach(key => {
				if (selectedCategory.subCategories[key].subCategory.attributes.is_finish_it) {
					arr.push(selectedCategory.subCategories[key])
				}
			})
			this.setState({expanded: arr})
		}
	}

	handleCollapse = (subCategory) => {
		const { expanded } = this.state
		let arr = [...expanded]
		if (arr.indexOf(subCategory) !== -1) {
			arr = arr.filter(item => item.subCategory.name !== subCategory.subCategory.name)
		} else {
			arr.push(subCategory)
		}

		this.setState({ expanded: arr })
	}

	drawHorizontalSubCategories = (subCategory, index, byob, categoryName) => {
		const { base_it_items, top_it_items, finish_it_items, selectedSize } = this.state
		const { __, currency } = this.props
		const subCategoryName = subCategory.subCategory.name
		const default_subCategory = subCategory.subCategory.attributes.is_base_it ? subCategory : null
		return (
			<div className={'web-byob-content' + byob} key={index}>
				<div className={'web-category-title' + byob} onClick={() => this.handleCollapse(subCategory, default_subCategory ? default_subCategory : null)}>
					{ __(subCategoryName) }
					<IonIcon className={this.state.expanded.indexOf(subCategory) > -1 ? 'web-category-title-icon expanded' :'web-category-title-icon'} color="white" mode='ios' icon={icons.arrowUp} />
				</div>
				<div className={this.state.expanded.indexOf(subCategory) > -1 ? 'web-byob-content-inner expanded' :'web-byob-content-inner'}>
					{subCategory.products.
						sort((a, b) => a.product[0].sort_order > b.product[0].sort_order ? 1 : -1).
						map((product, i) => {
							const item = product.product[0]
							const itemPrice = price(item.price)
							let selected = false
							if (item.sub_category.attributes.is_finish_it) {
								selected = finish_it_items.find(product => product.product[0].id === item.id ? true : false)
							} else if (item.sub_category.attributes.is_top_it) {
								selected = top_it_items.find(product => product.product[0].id === item.id ? true : false)
							} else {
								selected = base_it_items.find(product => product.product[0].id === item.id ? true : false)
							}
							if (selectedSize && !item.sub_category.attributes.is_finish_it) {
								if (product.product[0].sub_category.attributes.size === selectedSize) {
									return (
										<div key={i} className={selected ? 'web-item-byob selected' : 'web-item-byob'} onClick={() => this.pickItems(product, byob)}>
											<div className={selected ? 'web-item-byob-image-selected' : 'web-item-byob-image'} style={{backgroundImage: `url('${s3Url + item.image}')`}}>
												{/* <img alt='' style={{ opacity: selected ? 0.5 : 1 }} src={s3Url + item.image} /> */}
												<div className='web-hover-item'>
													<IonIcon color='white' icon={icons.checkmark}/>
												</div>
												{selected ?
													<div className='web-selected-item-checkmark'>
														<IonIcon color='white' icon={icons.checkmark}/>
													</div>
												 : null }
											</div>
											<div className='web-byob-item-info'>
												<div className='web-item-byob-name'>{item.order_name}</div>
												<div className='web-item-byob-price'>{itemPrice ? this.formatPrice(itemPrice) : '' }</div>
											</div>
										</div>
									)
								}
							} else {
								return (
									<div key={i} className={selected ? 'web-item-byob selected' : 'web-item-byob'} onClick={() => this.pickItems(product, byob)}>
										<div className={selected ? 'web-item-byob-image-selected' : 'web-item-byob-image'} style={{backgroundImage: `url('${s3Url + item.image}')`}}>
											{/* <img alt='' style={{ opacity: selected ? 0.5 : 1 }} src={s3Url + item.image} /> */}
											<div className='web-hover-item'>
												<IonIcon color='white' icon={icons.checkmark}/>
											</div>
											{selected ?
												<div className='web-selected-item-checkmark'>
													<IonIcon color='white' icon={icons.checkmark}/>
												</div>
											 : null }
										</div>
										<div className='web-byob-item-info'>
											<div className='web-item-byob-name'>{item.order_name}</div>
											<div className='web-item-byob-price'>{itemPrice ? this.formatPrice(itemPrice) : '' }</div>
										</div>
									</div>
								)
							}
							return null
						})}
				</div>
			</div>
		)
	}

	pickItems = (product, byob) => {
		const { __ } = this.props
		const { byob_type, finish_it_items, drinksAndSnacks } = this.state
		if (byob !== '') {
			let b_type = ''
			if (byob_type === 'is_base_it') {
				b_type = 'is_top_it'
				const base_it_items = []
				base_it_items.push(product)
				this.setState({ byob_type: b_type, base_it_items })
			} else if (byob_type === 'is_top_it') {
				b_type = 'is_finish_it'
				const top_it_items = []
				top_it_items.push(product)
				this.doubleUpModal(product)
				this.setState({ byob_type: b_type, top_it_items})
			} else {
				let items = [...finish_it_items]
				let findItem = finish_it_items.length > 0 ? !!finish_it_items.find(item => item.product[0].id === product.product[0].id) : false
				if (findItem) {
					items = finish_it_items.filter(item => item.product[0].id !== product.product[0].id)
				} else {
					items.push(product)
				}
				this.setState({ finish_it_items: items })
			}
		} else {
			let items = [product]
			let selectedProduct =
				<div>
					{/* <div className='item-third-header'>
						<IonLabel size={20}>{ product.product[0].order_name.toUpperCase()}</IonLabel>
						<IonIcon icon={icons['close']} onClick={()=> { this.closeModal() }}/>
					</div> */}
					<IonToolbar color="white">
						<IonButtons slot="start">
							<IonButton button clear style={{ visibility: 'hidden' }}>
								<IonIcon slot="icon-only" icon={ close } />
							</IonButton>
						</IonButtons>

						<IonTitle className="ion-text-center modal-title item-name">{ product.product[0].order_name.toUpperCase() }</IonTitle>

						<IonButtons slot="end">
							<IonButton button clear onClick={() => this.closeModal()}>
								<IonIcon slot="icon-only" icon={ close } />
							</IonButton>
						</IonButtons>
					</IonToolbar>
					<IonList className='item-third-list'>
						{product.product[0].sequences.map((sequnce, i) => {
							return sequnce.options.map((option, idx) => {
								return (
									<IonItem key={idx} onClick={() => {this.quantityModal(product, items, idx)}}>
										<div tabIndex="-1"></div>
										<IonLabel>{option.name}</IonLabel>
									</IonItem>
								)
							})
						})}
					</IonList>
					<IonButton expand='full' disabled className='select-base-button'> { __('Add to Basket') }</IonButton>
				</div>


			if (product.product[0].sequences.length > 0) {
				this.setState({ showProductModal: true, selectedProduct })
			} else {
				this.quantityModal(product, items)
			}
		}
	}

	quantityModal = (product, items, sequenceOptionIndex) => {
		this.setState({showSaucesModal: false})
		const { __ } = this.props
		const { is_sauce, sauces } = this.state
		let quantity = product.quantity ? product.quantity : 1
		if ( sauces.length > 0) {
			let item = sauces.find(sauce => sauce.product[0].id === product.product[0].id)
			if (item) {
				quantity = item && item.quantity ? item.quantity : 0
			}
		}

		let selectedProduct =
			<div className='category-content'>
				{/* <div className='item-third-header'>
					<IonLabel size={20}>{ product.product[0].order_name.toUpperCase()}</IonLabel>
					<IonIcon icon={icons['close']} onClick={()=> { this.closeModal() }}/>
				</div> */}
				<IonToolbar color="white">
					<IonButtons slot="start">
						<IonButton button clear style={{ visibility: 'hidden' }}>
							<IonIcon slot="icon-only" icon={ close } />
						</IonButton>
					</IonButtons>

					<IonTitle className="ion-text-center modal-title item-name">{ product.product[0].order_name.toUpperCase() }</IonTitle>

					<IonButtons slot="end">
						<IonButton button clear onClick={() => this.closeModal()}>
							<IonIcon slot="icon-only" icon={ close } />
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<div className='item-actions'>
					<div className='item-actions-header'> { __('Update Quantity') }</div>
					<div className='item-action'>
						<div className='quantity-button' onClick={() => { this.changeQuantity(-1, product, 'quantity') }}>
							<IonButton disabled={is_sauce ? quantity > 0 ? false : true : quantity > 1 ? false : true } expand="full"> <IonIcon slot="icon-only" icon={icons.remove} /></IonButton>
						</div>
						<div className='item-quantity'>{quantity}</div>
						<div className='quantity-button' onClick={() => { this.changeQuantity(1, product, 'qunatity') }}>
							<IonButton expand="full"> <IonIcon slot="icon-only" icon={icons.add} /> </IonButton>
						</div>
					</div>
				</div>
				<IonButton expand='full' disabled={false} className='select-base-button quantity' onClick={() => is_sauce ? this.setSauce(product) : this.add('drinksAndSnacks') }> { __('Add to Basket') }</IonButton>
			</div>


		if (!is_sauce) {
			product.quantity = quantity
			if (!isDefined(product.sequences)) {
				product.sequences = product.product[0].sequences.length ? product.product[0].sequences[0].options[sequenceOptionIndex] : null
			}
			this.setState({ showProductModal: true, selectedProduct, drinksAndSnacks: [product] })
		} else {
			this.setState({ showProductModal: true, selectedProduct })
		}
	}
	setSauce = (product) => {
		const { sauces, selectedSauce } = this.state
		let s = null
		let aleradyAddedSauce = sauces.find(sauce => sauce.product[0].id === product.product[0].id)
		if (aleradyAddedSauce) {
			sauces.forEach((sauce) => {
				if (sauce.product[0].id === aleradyAddedSauce.product[0].id) {
					sauce.product.quantity = product.quantity
				}
			})
			if (product.quantity === 0) {
				s = sauces.filter(sauce => sauce.product[0].id !== product.product[0].id)
			}
			let newSauces = s ? s : sauces
			this.setState({ sauces: newSauces, showProductModal: true, showSaucesModal: true }, () => {
				// subCategory, index, byob
				this.showSaucesModal(selectedSauce, 0, '')
			})
		} else {
			if (product.quantity === undefined || product.quantity === 0) {
				product.quantity = 1
			}
			let newSauces = [...sauces, product]
			this.setState({ sauces: newSauces, showProductModal: true, showSaucesModal: true }, () => {
				// subCategory, index, byob
				this.showSaucesModal(selectedSauce, 0, '')
			})
		}
	}

	changeQuantity = (val, product, type) => {
		const { bowl, sauces } = this.state
		let quantity = product.quantity ? product.quantity : 1
		if (sauces.length > 0) {
			let item = sauces.find(sauce => sauce.product[0].id === product.product[0].id)
			let q = 0
			if (item) {
				quantity = item && item.quantity ? item.quantity : 0
				q = quantity + val
				if (q < 1) {
					q = 0
				}
				item.quantity = q
			}
		}
		let qty = quantity + val
		if (qty < 1) {
			qty = 0
		}
		let newProduct = {...product}
		newProduct.quantity = qty
		this.setState({ quantity: qty }, () => {
			if (type) {
				// have to handle this
				this.quantityModal(newProduct, bowl)
			} else {
				this.displayProduct(newProduct)
			}
		})
	}

	formatPrice = price => this.props.currency === 'gbp' ? currencySign(this.props.currency) + price : price + currencySign(this.props.currency)

	addTopItExtra = (type, obj) => {
		const { top_it_items } = this.state
		if (type !== 'no') {
			top_it_items[0].top_it_attributes = obj
			top_it_items[0].double = type === 'double' ? true : false
			this.setState({ showProductModal: false, top_it_items })
		} else {
			delete top_it_items[0].double
			delete top_it_items[0].top_it_attributes
			this.setState({ showProductModal: false, top_it_items })
		}
	}

	doubleUpModal = (product) => {
		const { __, currency } = this.props
		const productName = product.product[0].published_name ? product.product[0].published_name.toUpperCase() : ''
		const displayPrice = product.product[0].attributes.displayPrice
		// const canDoubleUp = product.product[0].attributes.canDoubleUp
		// const comboText = product.product[0].attributes.comboText ? product.product[0].attributes.comboText.toUpperCase() : ''
		// const combo = product.product[0].attributes
		const attributes = product.product[0].attributes

		const canDoubleUp = attributes.canDoubleUp
		const doubleUpText = attributes.doubleUpText ? attributes.doubleUpText.toUpperCase() : ''
		const doubleUpObj = { displayPrice: attributes.doubleUpPrice || displayPrice, comboSku: attributes.doubleUpSku || attributes.comboSku, comboText: doubleUpText || attributes.comboText, image: attributes.doubleUpImg }

		const canCombo = attributes.canCombo
		const comboText = attributes.comboText ? attributes.comboText.toUpperCase() : ''
		const comboObj = { displayPrice: attributes.comboPrice, comboSku: attributes.comboSku, comboText, image: attributes.comboImg }
		let selectedProduct =
			<div>
				<div className='item-third-header'>
					<IonLabel size={20}>{ __('Double up?') }</IonLabel>
				</div>
				<div className='item-actions'>
					<div className='item-actions-header'>{__('Would you like to double up your protein for an extra')} {this.formatPrice(doubleUpObj.displayPrice)}</div>
					{ canDoubleUp ? <IonButton text-wrap expand='full' style={{ whiteSpace: 'pre-wrap' }} color='tertiary' disabled={false} onClick={() => {this.addTopItExtra('double', doubleUpObj)}}>{__('Yes')} - {doubleUpText || productName} - {this.formatPrice(doubleUpObj.displayPrice)}</IonButton> : null }
					{ canCombo ? <IonButton style={{ whiteSpace: 'pre-wrap' }} expand='full' color='tertiary' disabled={false} onClick={() => {this.addTopItExtra('combo', comboObj)}}>{__('Yes')} - { comboText } - {this.formatPrice(comboObj.displayPrice)}</IonButton> : null }
					<IonButton className='no-thanks-button' expand='full' fill='clear' disabled={false} onClick={() => {this.addTopItExtra('no')}}>{ __ ('No thanks') }</IonButton>
				</div>
			</div>

		this.setState({ showProductModal: true, selectedProduct })
	}

	openSaucesCategory = (type) => {
		const { comboSequences, comboProduct } = this.state
		if (type && type === 'sequences' && comboProduct) {
			if (comboProduct.product[0].sequences.length === Object.keys(comboSequences).length) {
				this.setState({ is_bowl: false, is_sauce: true, setClassName: 'modal-auto-height', showProductModal: true, showSaucesModal: true, selectedSauce: this.setSelectedCategory(), quantity: 1 }, () => {
					this.showSaucesModal(this.setSelectedCategory(), 0, '')
				})
			} else {
				let type;
				const selectedSequence = comboProduct.product[0].sequences[0];
				if(comboProduct.product[0].sequences.length === 2 && Object.keys(comboSequences).length === 0){
					type = 'drinkAndDessert'
				}else if(comboProduct.product[0].sequences.length === 2) {
					const ret = selectedSequence.items.find(item=> item.item_sku === Object.values(comboSequences)[0]);
					type = ret ? "dessert" : "drink"
				}else{
					type = selectedSequence.name === "Choisir une boisson" ? "drink" :  "dessert";
				}
				this.setState({ typeOfAlert: type, showAlert: true, comboBaseFlag: true })
			}
		} else {
			this.setState({ is_bowl: false, is_sauce: true, setClassName: 'modal-auto-height', showProductModal: true, showSaucesModal: true, selectedSauce: this.setSelectedCategory(), quantity: 1 }, () => {
				this.showSaucesModal(this.setSelectedCategory(), 0, '')
			})
		}
	}

	showSaucesModal = (subCategory, index, byob) => {
		const { byob_type, base_it_items, top_it_items, finish_it_items, is_sauce, is_byob, sauces, is_bowl } = this.state
		const { __, currency, basket } = this.props
		const selectedProduct =
			<React.Fragment key={index}>
				<div className={'sauces-content'} key={index}>
					<IonToolbar color="white">
						<IonButtons slot="start">
							<IonButton button clear style={{ visibility: 'hidden' }}>
								<IonIcon slot="icon-only" icon={ close } />
							</IonButton>
						</IonButtons>

						<IonTitle className="ion-text-center modal-title item-name">{ __('Add a sauce?') }</IonTitle>

						<IonButtons slot="end">
							<IonButton button clear onClick={() => this.closeModal()}>
								<IonIcon slot="icon-only" icon={ close } />
							</IonButton>
						</IonButtons>
					</IonToolbar>
					<div className='desktop-sauce-wrapper'>
						{/* <div className={'sauce category-title' + byob}>
							{ __('Add a sauce?') }
							<IonIcon icon={icons['close']} onClick={()=> { this.closeModal() }}/>
						</div> */}

						<div className='sauces scrollable-y'>
							{subCategory.products.
								sort((a, b) => a.product[0].sort_order > b.product[0].sort_order ? 1 : -1).
								map((product, i) => {
									const item = product.product[0]
									const itemPrice = price(item.price)
									let selected = false

									if (byob_type === 'is_finish_it') {
										selected = finish_it_items.find(product => product.product[0].id === item.id ? true : false)
									} else if (byob_type === 'is_top_it') {
										selected = top_it_items.find(product => product.product[0].id === item.id ? true : false)
									} else {
										selected = base_it_items.find(product => product.product[0].id === item.id ? true : false)
									}
									if (sauces.length > 0) {
										selected = sauces.find(product => product.product[0].id === item.id ? true : false)
									}
									if (basket && basket.items.length > 0 && !is_sauce && !is_byob && !is_bowl ) {
										selected = basket.items.find(product => product.product === item.id ? true : false)
									}
									console.log("heree", product, byob)
									return (
										<div
											key={item.id}
											onClick={() => {
												this.pickItems(product, byob)
											}}
											className='sauces-item'>
											<div className={selected ? 'sauces-item-slide-image-selected' : 'sauces-item-slide-image'} style={{backgroundImage: `url('${s3Url + item.image}')`}}>
												{/* <img alt='' style={{ opacity: selected ? 0.5 : 1 }} src={s3Url + item.image} /> */}
												{selected ?
													<div className='sauces-selected-item-checkmark'>
														<IonIcon color='white' icon={icons.checkmark}/>
													</div>
												 : null }
											</div>
											<p className='sauces-item-slide-name'>{item.order_name}</p>
											<p className='sauces-item-slide-price'>{ itemPrice ? this.formatPrice(itemPrice) : '' }</p>
										</div>
									)
								})}
						</div>
					</div>
					<IonButton
						expand="full"
						disabled={byob_type !== 'is_finish_it' && is_byob ? true : false}
						color="tertiary"
						className='sauces-add-sauce'
						onClick={() => {
							if (is_sauce && sauces.length < 1) {
								this.notesModal()
							}else{
								this.add('bowl')
							}
						}}>
						{ __(is_sauce && sauces.length < 1 ? __('No thanks') : sauces.length > 0 ? __('Add') : null)}
					</IonButton>
				</div>
			</React.Fragment>

		this.setState({ selectedProduct })
	}

	add = (type, redirect) => {
		const { dispatch, location, basket, history } = this.props
		const { base_it_items, top_it_items, finish_it_items, comboProduct, comboSequences, sauces } = this.state
		let newBasket = {...basket}
		if (!isDefined(newBasket.items)) {
			newBasket = {items: []}
		}

		let categories = location.state.categories
		let cat = {}
		let byob_items = {}
		let byob_item = {}
		let findCombos = []
		if (comboProduct && comboSequences) {
			Object.keys(comboSequences).forEach((sequnce) => {
				let comboItem = comboProduct.product[0].sequences[sequnce].items.find(itm => itm.item_sku === comboSequences[sequnce])
				if (comboItem) {
					let combo_sub_product = {
						item_sku: comboItem.item_sku,
						item_price: comboItem.price,
						item_name: comboItem.description
					}
					findCombos.push(combo_sub_product)
				}
			})
		}
		Object.keys(categories).forEach((key) => {
			let attributes = categories[key].category.attributes
			if (!attributes.is_sauce && !attributes.is_byob && !attributes.is_bowl) {
				cat = categories[key]
			}
			if (attributes.is_bowl) {
				Object.keys(categories[key].subCategories).forEach((subKey) => {
					let attrSub = categories[key].subCategories[subKey].subCategory.attributes ? categories[key].subCategories[subKey].subCategory.attributes.is_byob : null
					if (attrSub) {
						byob_item = categories[key].subCategories[subKey].products[0]
					}
				})

			}
		})
		byob_items.sub_items = []
		byob_items.notes = this.state.notes
		base_it_items.forEach(item => {
			let sub_product = {
				item_sku: item.product[0].sku,
				item_price: item.product[0].price,
				item_name: item.product[0].order_name
			}
			byob_items.sub_items.push(sub_product)
		})

		top_it_items.forEach(item => {
			let sub_product = {
				item_sku: item.product[0].sku,
				item_price: item.product[0].price,
				item_name: item.product[0].order_name
			}
			byob_items.sub_items.push(sub_product)
			if (item.top_it_attributes) {
				let price = parseFloat(item.top_it_attributes.displayPrice) * 100
				let extra_item = {
					item_sku: item.top_it_attributes.comboSku,
					item_price: price,
					item_name: item.double ? 'Extra ' + item.product[0].published_name : 'Extra ' + item.top_it_attributes.comboText
				}
				byob_items.sub_items.push(extra_item)
			}
		})
		finish_it_items.forEach(item => {
			let sub_product = {
				item_sku: item.product[0].sku,
				item_price: item.product[0].price,
				item_name: item.product[0].order_name
			}
			byob_items.sub_items.push(sub_product)
		})

		byob_item = location.state.byob_item
		byob_items.name = byob_item.product[0].order_name
		byob_items.product = byob_item.product[0].id
		byob_items.sku = byob_item.product[0].sku
		byob_items.price = byob_item.product[0].price
		byob_items.image = byob_item.product[0].image

		let product = byob_items
		let item = {
			product: product.product,
			qty: 1,
			name: product.name,
			price: product.price,
			sub_items: product.sub_items.concat(findCombos) || [],
			image: product.image,
			notes: product.notes,
			sku: product.sku
		}
		if (comboProduct) {
			let comboProductPrice = comboProduct.product[0].price
			item.price = comboProductPrice
			item.sku = comboProduct.product[0].sku
		}
		sauces.forEach((sauce) => {
			let souceItem = {
				item_sku: sauce.product[0].sku,
				item_price: sauce.product[0].price,
				item_name: sauce.product[0].order_name,
				item_qty: sauce.quantity
			}
			item.sub_items.push(souceItem)
		})
		newBasket.items.push(item)
		this.setState({
			showProductModal: false,
			selectedCategory: cat,
			additional: false,
			is_byob: false,
			is_bowl: false,
			is_sauce: false,
			quantity: 1,
			base_it_items,
			sauces: []
		})

		dispatch(addToBasket(newBasket))
		forwardTo(history, 'cart')
	}

	notesModal = (flag, type) => {
		const { __ } = this.props
		const { comboSequences, comboProduct } = this.state
		if (flag) {
			
			if (type && type === 'sequences' && comboProduct) {
				if (comboProduct.product[0].sequences.length !== Object.keys(comboSequences).length) {
					let type;
					const selectedSequence = comboProduct.product[0].sequences[0];
					if(comboProduct.product[0].sequences.length === 2 && Object.keys(comboSequences).length === 0){
						type = 'drinkAndDessert'
					}else if(comboProduct.product[0].sequences.length === 2) {
						const ret = selectedSequence.items.find(item=> item.item_sku === Object.values(comboSequences)[0]);
						type = ret ? "dessert" : "drink"
					}else{
						type = selectedSequence.name === "Choisir une boisson" ? "drink" :  "dessert";
					}
					this.setState({ typeOfAlert: type, showAlert: true, comboBaseFlag: true })
					return;
				}
			}
		}
		let notesModal =
			<div>
				{/* <div className='item-third-header'>
					<IonLabel size={20}>{ __('Special Notes and Allergies') }</IonLabel>
					<IonIcon icon={icons['close']} onClick={()=> { this.closeModal() }}/>
				</div> */}
				<IonToolbar color="white">
					<IonButtons slot="start">
						<IonButton button clear style={{ visibility: 'hidden' }}>
							<IonIcon slot="icon-only" icon={icons.close} />
						</IonButton>
					</IonButtons>

					<IonTitle className="ion-text-center modal-title item-name">{ __('Special Notes and Allergies') }</IonTitle>

					<IonButtons slot="end">
						<IonButton button clear onClick={() => this.closeModal()}>
							<IonIcon slot="icon-only" icon={icons.close} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<div className='item-actions special-notes-actions'>
					<IonInput type='text' value={this.state.notes} placeholder={ __('Enter notes here') } onIonChange={(e)=> {this.setState({ notes: e.target.value})}}></IonInput>
				</div>
				<IonButton expand='full' disabled={false} className='select-base-button special-notes-button' onClick={() => { this.add() }}> { __('Add to Basket') }</IonButton>
			</div>

		this.setState({ showProductModal: true, selectedProduct: notesModal })
	}

	closeModal = () => {
		this.setState({ showProductModal: false, showSaucesModal: false })
	}

	closeAlert = () => {
		this.setState({ showAlert: false })
	}

	displayByobPrice = (b_items, t_items, f_items) => {
		const { location } = this.props
		let selected_byob_item = location.state && location.state.byob_item ? location.state.byob_item : null
		let selected_price = selected_byob_item && selected_byob_item.product ? selected_byob_item.product[0].price : 0
		let arr = [...b_items, ...t_items, ...f_items]
		if (arr.length > 0) {
			arr.forEach(item => {
				let itemPrice = item.top_it_attributes ? item.top_it_attributes.displayPrice : item.product[0].price
				if (itemPrice > 0) {
					if (typeof itemPrice === 'string') {
						itemPrice = parseInt(itemPrice.replace(/\./g,''))
					}
					selected_price += itemPrice
				}
			})
		}

		return price(selected_price)
	}

	findAvailableProductBySku = (sku) => {
		const { data } = this.props
		const { content } = data
		const { productAvailability } = content
		return (productAvailability.available_products || []).find(product => product.product[0].sku === sku)
	}

	displaySequencesModal = (item, type) => {
		if (item) {
			this.showModalBase(item, 'sequences', type)
			this.setState({comboProduct: item})
		} else {
			// this.setState({ is_bowl: false, is_sauce: true, showProductModal: false, selectedCategory: this.setSelectedCategory(), quantity: 1 })
		}
	}
	setSequences = (sequence, type, index, min) => {
		const { comboSequences } = this.state
		const newcomboSequences = {...comboSequences}
		newcomboSequences[index] = sequence
		this.setState({ comboSequences: newcomboSequences})
	}
	showModalBase = (product, type, itemType) => {
		const { __ } = this.props
		const { comboSequences } = this.state
		const item = product.product[0]

		let selectedProduct =
			<div className='item-content'>
				<IonToolbar color="white">
					<IonButtons slot="start">
						<IonButton button clear style={{ visibility: 'hidden' }}>
							<IonIcon slot="icon-only" icon={ close } />
						</IonButton>
					</IonButtons>

					<IonTitle className="ion-text-center modal-title item-name">{ __('Options') }</IonTitle>

					<IonButtons slot="end">
						<IonButton button clear onClick={() => this.setState({showProductModal: false})}>
							<IonIcon slot="icon-only" icon={ close } />
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<div className='item-actions-base'>
					{item[type].map((modifier, index) => {
						// eslint-disable-next-line no-console
						let radio = null
						if (modifier.max_select === 1){
							radio = true
						} else if (modifier.max_select > 1){
							radio = false
						} else {
							radio = false
						}
						return (
							<IonList key={index}>
								<IonListHeader className='list-header'>
									<IonLabel>{ __(modifier.name) }</IonLabel>
								</IonListHeader>
								<div>
									{ radio ?
										<IonRadioGroup onIonChange={(e) => { this.setSequences(e.target.value, 'radio', index, modifier.min_select)}}>
											<div className="select-base-content">
												{

													modifier.items.map((item, i) => {
														const itemPrice = price(item.price)
														return (
															<IonList key={i}>
																<IonItem lines='none' className='select-base-item'>
																	<IonLabel>{item.description}</IonLabel>
																	<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
																		<IonRadio slot="end" value={item.item_sku} />
																	</div>
																	<p className='item-action-modifier-price'>{ itemPrice ? this.formatPrice(itemPrice) : ''}</p>
																</IonItem>
															</IonList>
														)
													})

												}
											</div>
											<div className='arrow-down'>
												<IonIcon slot="icon-only" mode='ios' color='medium' size="medium" icon={icons.arrowDown} />
											</div>
										</IonRadioGroup>

										:
										null
									}
								</div>
							</IonList>
						)
					})}
				</div>
				<div className='item-actions-footer'>
					<IonButton expand='full' className='select-base' onClick={() => { this.openSaucesCategory(type)}}> { __('Add to Basket') }</IonButton>
				</div>
			</div>


		this.setState({ selectedProduct })
	}
	displayCombo = (comboSkus, product, type) => {
		const { __, currency } = this.props
		const foundProducts = []
		comboSkus.forEach(comboSku => {
			if (this.findAvailableProductBySku(comboSku)) {
				foundProducts.push(this.findAvailableProductBySku(comboSku))
			}
		})
		let selectedProduct =
		<div>
			<div className='item-third-header'>
				<IonLabel size={20}>{ __('Make it a combo?') }</IonLabel>
			</div>
			<div className='item-actions'>
				<div className='item-actions-header'>{__('Would you like to upgrade to a combo?')}</div>
				{foundProducts.map((prod,i) => {
					const item = prod.product[0]
					const itemName = item.order_name
					const itemPrice = item.price ? ((item.price - product[0].product[0].price) / 100).toFixed(2) : 0
					const buttonLabel = itemName + ' ' + (currency === 'gbp' ? ' + ' + currencySign(currency) + ' ' + itemPrice : ' + ' + itemPrice + ' ' + currencySign(currency))
					return <IonButton key={i} text-wrap expand='full' style={{ whiteSpace: 'pre-wrap' }} color='tertiary' disabled={false} onClick={() => {this.displaySequencesModal(prod, type)}}>{ buttonLabel }</IonButton>
				})}
				<IonButton className='no-thanks-button' expand='full' fill='clear' disabled={false} onClick={() => { this.openSaucesCategory() }}>{__('No thanks')}</IonButton>
			</div>
		</div>

		this.setState({ showProductModal: true, selectedProduct })
	}

	setSelectedCategory = () => {
		const { location } = this.props
		const { modifiers } = this.state
		let categories = location.state && location.state.categories || this.state.categories
		let cat = {}
		Object.keys(categories).forEach((key) => {
			if (categories[key].category.attributes.is_sauce) {
				cat = categories[key]
			}
		})
		return cat
	}
	getComboSeguencesItems = (comboSequnces) => {
		let items = []
		if (isEmptyObject(comboSequnces)) {return []}
		Object.keys(comboSequnces).forEach((key, i) => {
			let item = this.findAvailableProductBySku(comboSequnces[key])
			if (item) {
				items.push(item)
			}
		})
		return items
	}
	displayItems = () => {
		const { __, history, currency, location } = this.props
		const { selectedCategory, base_it_items, top_it_items, finish_it_items, is_byob, comboSequences } = this.state
		const categoryName = selectedCategory.category && selectedCategory.category.name ? selectedCategory.category.name : ''
		const subCategory = selectedCategory.subCategories || {}
		let top_it_attributes_sku = top_it_items.length && top_it_items[0].top_it_attributes && top_it_items[0].double === false ? top_it_items[0].top_it_attributes.comboSku : null
		let additionalItem = isEmptyObject(selectedCategory) && !top_it_attributes_sku ? null : selectedCategory.products.find(item => item.product[0].sku === top_it_attributes_sku)
		let additionalImage = additionalItem ? additionalItem.product[0].bowl_image : ''
		let baseItImage = base_it_items.length > 0 ? base_it_items[0].product[0].image : null
		let topItImage = top_it_items.length > 0 ? top_it_items[0].double ? top_it_items[0].product[0].bowl_image_double : top_it_items[0].product[0].bowl_image : null
		const byob_item = location.state && location.state.byob_item ? location.state.byob_item : null
		const canComboByob = byob_item && byob_item.product[0] && byob_item.product[0].additional_attributes && byob_item.product[0].additional_attributes.canCombo ? byob_item.product[0].additional_attributes.canCombo : false
		const comboByobSkus = byob_item && byob_item.product[0] && byob_item.product[0].additional_attributes && byob_item.product[0].additional_attributes.comboSkus ? byob_item.product[0].additional_attributes.comboSkus : []
		return (
			<>
				<div className='web-byob-header-title'>{ __('Build your own bowl') }</div>
				<div className='web-byob-wrapper'>
					<div className='web-byob-info-wrapper'>
						<div className='web-byob-image' style={{backgroundImage: `url('${s3Url + baseItImage}')`}}>
							<div className='web-top-image' style={{backgroundImage: `url('${s3Url + topItImage}')`}} />
							<div className='web-additional-top-image' style={{backgroundImage: `url('${s3Url + additionalImage }')`}} />
							{finish_it_items.map((item, i) => {
								let finishImage = item.product[0].bowl_image
								return (
									<div key={i} className='web-finish-image' style={{backgroundImage: `url('${s3Url + finishImage}')`}}/>
								)
							})}
						</div>
						<div className='web-byob-info'>
							<p className='web-byob-info-total'>{ this.displayByobPrice(base_it_items, top_it_items, finish_it_items) ? this.formatPrice(this.displayByobPrice(base_it_items, top_it_items, finish_it_items)) : null}</p> {/* TEMPORARY  */}
							<h2 className='web-byob-info-title'>{ __('Build your own bowl') }</h2> {/* TEMPORARY  */}
							<div className='web-byob-info-inner'>
								{
									base_it_items && base_it_items.length > 0 ?
										base_it_items.map((item, i) => {
											const name = item.product[0].order_name
											const itemPrice = price(item.product[0].price)
											return (
												<div key={i} className='web-byob-info-item'>
													<div className='web-byob-info-name'>+ {name}</div>
													<div className='web-byob-info-price'>{itemPrice ? '+' + this.formatPrice(itemPrice) : ''}</div>
												</div>
											)
										}) : null
								}
								{
									top_it_items && top_it_items.length > 0 ?
										top_it_items.map((item, i) => {
											const name = item.product[0].order_name
											let extra_item = {}
											if (item.top_it_attributes) {
												let price = parseFloat(item.top_it_attributes.displayPrice) * 100
												extra_item = {
													item_price: price,
													item_name: item.double ? 'Extra ' + item.product[0].published_name : 'Extra ' + item.top_it_attributes.comboText
												}
											}
											return (
												<div key={i}>
													<div className='web-byob-info-item'>
														<div className='web-byob-info-name'>+ {name}</div>
													</div>
													{
														!isEmptyObject(extra_item) ?
															<div className='web-byob-info-item'>
																<div className='web-byob-info-name'>+ {extra_item.item_name}</div>
																<div className='web-byob-info-price'>{ extra_item.item_price ? '+ ' + this.formatPrice(price(extra_item.item_price)) : null }</div>
															</div> : null
													}
												</div>
											)
										}) : null
								}
								{
									finish_it_items && finish_it_items.length > 0 ?
										finish_it_items.map((item, i) => {
											const name = item.product[0].order_name
											const itemPrice = price(item.product[0].price)
											return (
												<div key={i} className='web-byob-info-item'>
													<div className='web-byob-info-name'>+ {name}</div>
													<div className='web-byob-info-price'>{ itemPrice ? '+' + this.formatPrice(itemPrice) : ''}</div>
												</div>
											)
										}) : null
								}

								{
									this.getComboSeguencesItems(comboSequences) && this.getComboSeguencesItems(comboSequences).length > 0 ?
										this.getComboSeguencesItems(comboSequences).map((item, i) => {
											const name = item.product[0].order_name
											const itemPrice = null
											return (
												<div key={i} className='web-byob-info-item'>
													<div className='web-byob-info-name'>+ {name}</div>
													<div className='web-byob-info-price'>{ itemPrice ? '+' + this.formatPrice(itemPrice) : ''}</div>
												</div>
											)
										}) : null
								}
							</div>
							<IonButton
								expand="full"
								disabled={base_it_items.length === 0 || top_it_items.length === 0 ? true : false}
								color="primary"
								onClick={() => {
									is_byob ? canComboByob ? this.displayCombo(comboByobSkus, [byob_item], 'byob') : this.notesModal() : forwardTo(history, 'cart')
								}}>
								{ __('Next') }
							</IonButton>
						</div>
					</div>
					<div className='byob-right-wrapper'>
						{
							Object.keys(subCategory).
								sort((a, b) => {
									if (subCategory[a].subCategory.attributes.display_mode !== 'horizontal' || subCategory[b].subCategory.attributes.display_mode !== 'horizontal') { return null }
									return subCategory[a].subCategory.sort_order > subCategory[b].subCategory.sort_order ? 1 : -1
								}).
								map((key, index) => {
									if (subCategory[key].subCategory.attributes.display_mode === 'horizontal') {
										if (is_byob) {
											// let baseItImage =  base_it_items.length > 0 ? base_it_items[0].product[0].image : null
											// let topItImage =  top_it_items.length > 0 ? top_it_items[0].double ? top_it_items[0].product[0].bowl_image_double : top_it_items[0].product[0].bowl_image : null
											return (
												<div key={index} className='web-byob-section'>
													{this.drawHorizontalSubCategories(subCategory[key], index, '-byob', categoryName)}
												</div>)
										}
										return null
									}
									return null
								})
						}
					</div>
				</div>
			</>
		)
	}

	onForwardHandler = () => {
		const { byob_type } = this.state
		let b_type = ''
		if (byob_type === 'is_base_it') {
			b_type = 'is_top_it'
		} else if (byob_type === 'is_top_it') {
			b_type = 'is_finish_it'
		}
		this.setState({ byob_type: b_type })
	}

	onBackHandler = () => {
		const { byob_type } = this.state
		let b_type = ''
		if (byob_type === 'is_finish_it') {
			b_type = 'is_top_it'
		} else if (byob_type === 'is_top_it') {
			b_type = 'is_base_it'
		}
		this.setState({ byob_type: b_type })
	}

	render() {
		const { __ } = this.props
		const { showProductModal, typeOfAlert, showAlert, showSaucesModal } = this.state

		return (
			<Layout
				headerTitle={ __('Build your own bowl') }
				color='transparent'
				contentClassName="animated-gif-01"
				noPadding={true}
			>
				<SimpleLoader>
					{this.displayItems()}
					{/* <IonButton
						expand="full"
						disabled={byob_type !== 'is_finish_it' && is_byob ? true : false}
						color="tertiary"
						onClick={() => {
							is_byob ?  this.notesModal() : forwardTo(history, 'cart')
						}}>
						{ __(is_byob ? __('Add') : __('View Basket'))}
					</IonButton> */}
					<Modal
						showProductModal={showProductModal}
						component={this.state.selectedProduct}
						className={showSaucesModal ? "modal-auto-height full-width" : "modal-auto-height" }
						closeModal={ this.closeModal } />
					<Alert type={typeOfAlert} showAlert={showAlert} closeAlert={ this.closeAlert } __ = { __ }/>
				</SimpleLoader>
			</Layout>
		)
	}
}

const stateToProps = (state) => {
	const { data, order } = state
	return {
		data,
		order,
		selectedCategory: order.selectedCategory,
		basket: order.basket,
		currency: order.currency,
		restaurantId: order.restaurantId,
		collectionTime: order.time
	}
}

export default connect(stateToProps)(withRouter(withTranslation(WebByob)))
