import React from 'react'
// import { IonButton } from '@ionic/react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Layout from '../../components/layout'
import { withTranslation } from '../../lib/translate'
import { IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel } from '@ionic/react'
import moment from '../../lib/moment'
import { total, currencySign, price, isDesktop } from '../../lib/utils'
import { DesktopView } from '../../components/desktopView'
import { getMyOrders } from '../../store/actions'

require('./index.css')

if (isDesktop()) {
	require('./desktop.css')
}

class MyOrderDetails extends React.Component {

	componentDidMount(){
		this.props.dispatch(getMyOrders())
	}
	render() {
		const { __ , myOrders } = this.props
		const lastOrder = myOrders ? myOrders[0] : []
		const collection_time = lastOrder && lastOrder.collection_time ? moment(lastOrder.collection_time).format('LT') : null
		const collection_code = lastOrder && lastOrder.collection_code ? lastOrder.collection_code.substr(lastOrder.collection_code.length - 3) : null
		const items = lastOrder && lastOrder.items && lastOrder.items.length > 0 ? lastOrder.items : []
		const restaurantName = lastOrder && lastOrder.restaurant_name ? lastOrder.restaurant_name : null
		const currency = lastOrder && lastOrder.currency && lastOrder.currency !== '' ? lastOrder.currency : ''
		const status = lastOrder && lastOrder.status && lastOrder.status !== '' ? lastOrder.status : ''
		const discount = lastOrder && lastOrder.discount_applied !== null ? lastOrder.discount_applied : null
		console.log('discount', discount)
		const formatPrice = price => currency === 'gbp' ? currencySign(currency) + price : price + currencySign(currency)
		return (
			<Layout headerTitle={ __('Order Complete')} color={isDesktop() ? 'transparent' : 'white'} contentClassName={ isDesktop() ? 'animated-gif-01' : '' } noPadding={ true }>
				<div className={isDesktop() ? 'desktop-order-complete-wrapper' : ''}>
					<DesktopView>
						<div className="details-title">{ __('Order Complete') }</div>
					</DesktopView>
					<IonGrid className={isDesktop() ? 'order-complete-grid' : ''}>
						<IonRow className="row">
							<IonCol><h2>{ __('Restaurant:') }</h2></IonCol>
							<IonCol className="end"><h2>{restaurantName}</h2></IonCol>
						</IonRow>
						<IonRow className="row">
							<IonCol size="8"><h2>{ __('Collection Code:') }</h2></IonCol>
							<IonCol size="4" className="end"><h2>{collection_code}</h2></IonCol>
						</IonRow>
						<IonRow className="row">
							<IonCol><h2>{ __('Date:') }</h2></IonCol>
							<IonCol className="end"><h2>{collection_time}</h2></IonCol>
						</IonRow>
						<IonRow className="row">
							<IonCol><h2>{ __('Order Status:') }</h2></IonCol>
							<IonCol className="end">
								<div className={isDesktop() ? 'order-complete-status' : 'payment'}>
									<p>{ __(status) }</p>
								</div>
							</IonCol>
						</IonRow>
					</IonGrid>
					<div className="menu-box">
						<IonList lines='none'>
							{(items || []).map((item, index) => {
								const itemPrice = price(item.price * item.qty)
								return (
									<div key={index} className='menu-box-item'>
										<IonItem>
											<div className='menu-box-item-content'>
												<IonItem>
													<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
														<IonLabel>{item.qty}x {item.name}</IonLabel>
													</div>
													<h2>{itemPrice ? formatPrice(itemPrice) : null}</h2>
												</IonItem>
												<IonList className='menu-box-sub-items' lines='none'>
													{ (item.sub_items || []).map((sub_item, idx) => {
														const subItemPrice = price(sub_item.item_qty ? sub_item.item_price * item.qty * sub_item.item_qty : sub_item.item_price * item.qty)
														return (
															<IonItem key={idx}>
																<div className='menu-box-subItem-content'>
																	<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
																		<IonLabel slot='start'>+{sub_item.item_qty ? sub_item.item_qty * item.qty + 'x' : item.qty > 1 ? item.qty + 'x': null} {sub_item.item_name}</IonLabel>
																	</div>
																	<h2>{ subItemPrice ? '+ ' + formatPrice(subItemPrice) : null }</h2>
																</div>
															</IonItem>
														)
													})}
												</IonList>
											</div>
										</IonItem>
										{ item.notes && item.notes !== '' ?
											<div className="munu-box-total notes">
												<IonLabel>{ __('Special Notes and Allergies') }:</IonLabel>
												<h2>{item.notes}</h2>
											</div>
										 : null }
									</div>
								)
							})}
						</IonList>
						<div className="total-box">
							<h2>{__('Discount')}:</h2>
							<h2>-{formatPrice(parseFloat(discount) / 100).toFixed(2)}</h2>
						</div>
						<div className="total-box">
							<h2>{ __('Total')}</h2>
							<h2>{formatPrice(total(items, false, false, lastOrder))}</h2>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

const stateToProps = (state) => {
	const { order, auth, data } = state
	return {
		orderDetails: order.orderDetails,
		myOrders: order.myOrders || [],
		profile: auth.profile,
		restaurants: data.content.restaurants || []
	}
}

export default connect(stateToProps)(withRouter(withTranslation(MyOrderDetails)))
