import {
	SET_AUTH,
	REDIRECT_AFTER_LOGIN,
	SET_PROFILE_PROP,
	SET_VOUCHERS,
	SET_CARDS,
	SET_SYS_LOCALE
} from '../constants'
import { isDefined } from '../../lib/utils'

let initialState = {
	auth: {},
	profile: {},
	user: null,
	token: null,
	redirectAfterLogin: null,
	cards: [],
	vouchers: [],
	sysLocale: ''
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
	case SET_AUTH:
		return {
			...state,
			auth: {
				loggedIn: action.newAuthState,
				token: action.newAuthState && action.token ? action.token : null
			}
			//user: action.newAuthState && action.user ? action.user : null,
		}
	case SET_PROFILE_PROP:
		return { ...state, [action.key]: isDefined(action.merge) && action.merge ? {...state[action.key], ...action.value} : action.value }
	case SET_VOUCHERS:
		return { ...state, profile: { ...state.profile, vouchers: action.value } }
	case SET_CARDS:
		return { ...state, profile: { ...state.profile, cards: action.value } }
	case REDIRECT_AFTER_LOGIN:
		return { ...state, redirectAfterLogin: action.path }
	case SET_SYS_LOCALE:
		return { ...state, sysLocale: action.sysLocale }
	default:
		return state
	}
}

export default reducer
