import React from 'react'
import Layout from '../../components/layout'
import { FieldError } from '../../components/common'
import { validateForm } from '../../lib/utils'
import { withTranslation } from '../../lib/translate'
import { IonButton, IonInput, IonItem, IonLabel, IonList } from '@ionic/react'
import { sendRefer } from '../../store/actions'
import Loading from '../../components/spinner'

import './index.css'

class Refer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			email: '',
			formErrors: {}
		}
		this.formConfig = {
			email: { type: 'email', required: true }
		}
	}

	handleInput (key, val) {
		this.setState({[key]: val })
	}

	handleRefer = () => {
		let formErrors = validateForm(this.formConfig, this.state, this.props.__)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			const { email } = this.state
			const data = { email }
			this.setState({ email: '' })
			this.props.dispatch(sendRefer(data))
		}
	}

	setValue(field, value) {
		this.setState({ [field]: value })
	}

	render() {
		const { __ } = this.props
		const { email } = this.state
		return (
			<Loading transparent >
				<Layout headerTitle={ __('Refer a Friend') } color='white'>
					<h3>{ __('Enter your friend’s email to invite them to the Island Poké family.')}</h3>
					<div className="box-holder top-medium pad10 refer-holder">
						<IonList>
							<IonItem>
								<IonLabel position="floating">{ __('Email')}*</IonLabel>
								<IonInput onIonChange={ e => this.handleInput('email', e.target.value)} required={ true } type="email" pattern="email" inputmode="email" value={ email }></IonInput>
								<FieldError className="field-error" value={ __(this.state.formErrors.email)} />
							</IonItem>
						</IonList>
						<div className="top-medium">
							<IonButton expand="block" color="primary" onClick={ this.handleRefer }>{ __('Send Invitation') }</IonButton>
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

export default withTranslation(Refer)
