import React from 'react'
import { IonIcon, IonInput, IonButton } from '@ionic/react'
import { eye, eyeOff } from 'ionicons/icons'
import './index.css'

class PasswordInput extends React.Component {
	state = {
		value: this.props.value || '',
		show: false
	}

	togglePass = (show) => {
		this.setState({ show })
	}

	onChange = e => {
		const { onIonChange } = this.props
		this.setState({ value: e.target.value })
		if (onIonChange){
			onIonChange(e)
		}
	}

	handleKeyPress = e => {
		if (e.key === 'Enter' || e.which === 13) {
			this.props.handleLogin()
		}
	}

	render (){
		const { show, value } = this.state
		return (
			<IonInput className="okx-password" type={ show ? 'text' : 'password' } pattern="password" value={ value } onIonChange={ this.onChange } onKeyPress={(e) => this.handleKeyPress(e)}>
				<IonButton color="medium" disabled={ value === '' } fill="clear" size="small" slot="end" onTouchStart={() => this.togglePass(true)} onTouchEnd={() => this.togglePass(false)} onMouseDown={() => this.togglePass(true)} onMouseUp={() => this.togglePass(false)}>
					<IonIcon slot="icon-only" icon={ show ? eyeOff : eye }/>
				</IonButton>
			</IonInput>
		)
	}
}

export default PasswordInput
