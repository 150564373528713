import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withTranslation } from '../../lib/translate'
import { getDefaultRoute } from '../../lib/utils'
import { IonList, IonItem, IonLabel	 } from '@ionic/react'
import { toggleRightDrawer, logout } from '../../store/actions'
import navConfig from '../../navConfig'
import './index.css'

const RightDrawerItem = ({ history, item, handleLogout, toggleRightDrawer, __ }) => {
	return (
		<div onClick={() => toggleRightDrawer()}>
			<IonItem key={ item.path } lines='none' button onClick={() => item.fn === 'logout' ? handleLogout() : history.push(item.path)}>
				<div tabIndex="0" style={{ boxShadow: 'none', outline: '0', width: '100%' }}>
					<IonLabel>{__(item.label)}</IonLabel>
				</div>
			</IonItem>
		</div>
	)
}

class RightPopover extends Component {

	handleLogout = () => {
		this.props.dispatch(logout())
		localStorage.setItem("language", null);
		window.location.reload(false);
		const defaultRoute = getDefaultRoute()
		this.props.history.push(defaultRoute.path)
	}

	render() {
		const { isRightDrawerOpen, toggleRightDrawer, history, auth, __ } = this.props
		const { loggedIn } = auth
		return (
			<>
				<div onClick={() => toggleRightDrawer()} className={isRightDrawerOpen ? 'right-popover-backdrop visible' : 'right-backgrop'}></div>
				<IonList className={isRightDrawerOpen ? 'right-popover visible' : 'right-popover'}>
					{navConfig.webRightPopoverRoutes.map(item =>
						<RightDrawerItem key={ item.path } history={ history } item={ item} __={__} toggleRightDrawer={ toggleRightDrawer } />
					)}
					<RightDrawerItem history={ history } toggleRightDrawer={ toggleRightDrawer } handleLogout={ this.handleLogout } __={__} item={ navConfig.authRoutes.find(item => item.fn === (loggedIn ? 'logout' : 'login'))} />
				</IonList>
			</>
		)
	}
}

const stateToProps = state => {
	const { isRightDrawerOpen } = state.common
	const { auth } = state.auth
	return {
		isRightDrawerOpen,
		auth
	}
}

const dispatchToProps = dispatch => {
	return {
		toggleRightDrawer: () => dispatch(toggleRightDrawer())
	}
}

export default connect(stateToProps, dispatchToProps)(withTranslation(withRouter(RightPopover)))
