import moment from 'moment'
import asyncStorage from './asyncStorage'
import appConfig from '../appConfig'

import 'moment/locale/en-gb'
import 'moment/locale/fr'

export const applayLocale = async () => {
	const localeMap = {
		en: 'en-gb',
		fr: 'fr'
	}
	const profile = JSON.parse(await asyncStorage.getItem('profile'))
	const locale = profile && profile.locale ? profile.locale : appConfig.localization.defaultLocale
	moment.locale(localeMap[locale])

	if (locale === 'en'){
		moment.updateLocale('en-gb', {
			longDateFormat: {
				LT: 'h:mm A'
				// LTS: "h:mm:ss A",
				// L: "MM/DD/YYYY",
				// l: "M/D/YYYY",
				// LL: "MMMM Do YYYY",
				// ll: "MMM D YYYY",
				// LLL: "MMMM Do YYYY LT",
				// lll: "MMM D YYYY LT",
				// LLLL: "dddd, MMMM Do YYYY LT",
				// llll: "ddd, MMM D YYYY LT"
			}
		})
	}
}

applayLocale()

export default moment
