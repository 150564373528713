import React from 'react'
import { connect } from 'react-redux'
import { IonRippleEffect, IonInput, IonButton } from '@ionic/react'
import { isDesktop } from '../../lib/utils'
import { getProfile, clearBasket, sendPostCode, showToast } from '../../store/actions'
import Layout from '../../components/layout'
import { withTranslation } from '../../lib/translate'
import CurvedText from '../../components/curvedText'
import { MobileView } from '../../components/mobileView'
import { DesktopView } from '../../components/desktopView'
import Alert from '../../components/alert'

// import './index.css'

require('./index.css')
if (isDesktop()){
	require('./desktop.css')
}else{
	require('./mobile.css')
}

const webDashboard = require('../../assets/images/web-dashboard.jpg')

class Dashboard extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			showAlert: false,
			query: ''
		}
	}

	componentDidMount() {
		if (Object.keys(this.props.profile).length === 0 && Object.keys(this.props.auth).length === 0 && this.props.auth.loggedIn){
			this.props.dispatch(getProfile())
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.loggedIn !== this.props.auth.loggedIn && this.props.auth.loggedIn){
			this.props.dispatch(getProfile())
		}

	}

	renderWelcomeMsg = () => {
		const { __ } = this.props
		const currentHour = new Date().getHours()
		const splitAfternoon = 12 // 24hr time to split the afternoon
		const splitEvening = 17 // 24hr time to split the evening

		if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
			return __('Good afternoon')
		} else if (currentHour >= splitEvening) {
			return __('Good evening')
		}
		return __('Good morning')
	}

	handlePostCode = () => {
		const { history, dispatch, __ } = this.props
		const { query } = this.state
		const data = { query }
 		if (data.query === ''){
			dispatch(showToast(__('Enter a valid postcode or address'), 'warning'))
		} else {
			this.props.dispatch(sendPostCode(data, history))
		}
	}
	handleInput (key, val) {
		this.setState({[key]: val })
	}


	closeAlert = () => {
		this.setState({ showAlert: false })
	}

	clearBasket = () => {
		const { dispatch, history } = this.props
		dispatch(clearBasket())
		this.setState({ showAlert: false }, () => {
			setTimeout(() => {
				history.push({ pathname: 'orders' })
			}, 300)
		})
	}

	redirectToStartOrder = () => {
		const { history, basket } = this.props
		const items = basket ? basket.items : null
		if (items && items.length > 0) {
			this.setState({ showAlert: true })
		} else {
			history.push({ pathname: 'orders' })
		}
	}

	render() {
		const { __, history, profile } = this.props
		const { showAlert, query } = this.state

		return (
			<Layout hideSecondToolbar={ true } color="transparent" contentClassName="animated-gif-01">
				<DesktopView>
					<div className='start-order-wrapper'>
						<div className='start-order'>
							<div className='start-order-find'>
								<div className='start-order-top'>
									<h2> {__('Get poké now')}</h2>
									<h4>{ __('Search for locations near you to plan an order for collection') + '.'}</h4>
									<div className='start-order-input'>
										<IonInput className='search' placeholder={ __('Search postcode')} onIonChange={ e => this.handleInput('query', e.target.value)} value={ query } />
										<IonButton fill='clear' onClick={ this.handlePostCode }>{ __('Find') } </IonButton>
									</div>
								</div>
								<div className='start-order-buttons'>
									<IonButton className='start-order-button' onClick={() => history.push({ pathname: 'locations' })}>{ __('Browse all locations')}</IonButton>
									<IonButton className='start-order-button uber-eats' href="https://www.ubereats.com/fr/paris/food-delivery/island-poke-saint-honore/qFP5XQQQT0-h80TDMiuTug?pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMjMzJTIwUGxhY2UlMjBkdSUyME1hcmNoJUMzJUE5JTIwU2FpbnQtSG9ub3IlQzMlQTklMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKUVJZU05qQnU1a2NSeWR3b0oxSmhHUGclMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNDguODY3NDY1MyUyQyUyMmxvbmdpdHVkZSUyMiUzQTIuMzMxNzMwOSU3RA%3D%3D" target="_blank">
										{__('Uber eats')}
									</IonButton>
									<IonButton className='start-order-button' href="https://deliveroo.fr/fr/marques/island-poke" target="_blank">{__('Order on deliveroo')}</IonButton>
								</div>
							</div>
							<div className='start-order-pic' style={{backgroundImage: `url('${webDashboard}')`}}></div>
						</div>
					</div>
				</DesktopView>
				<MobileView>
					<div className="welcome">
						<CurvedText text={ this.renderWelcomeMsg()} radius={ 600 }/>
						<div>{ profile.first_name || 'Guest'}</div>
					</div>
					<div className="dash-grid-padding">
						<div className="dash-grid">
							<div className="ion-activatable" onClick={() => this.redirectToStartOrder()}>
								<div className="dash-grid-bg"/>
								<div className="dash-grid-label">{ __('Start new order')}</div>
								<IonRippleEffect></IonRippleEffect>
							</div>
							<div className="ion-activatable" onClick={() => history.push({ pathname: 'loyalty', state: { tab: 'scan' } })}>
								<div className="dash-grid-bg scan"/>
								<div className="dash-grid-label">{ __('Scan')}</div>
								<IonRippleEffect></IonRippleEffect>
							</div>
							<div className="ion-activatable" onClick={() => history.push({ pathname: 'loyalty', state: { tab: 'reward' } })}>
								<div className="dash-grid-bg rewards"/>
								<div className="dash-grid-label">{ __('Rewards')}</div>
								<IonRippleEffect></IonRippleEffect>
							</div>
							<div className="ion-activatable" onClick={() => history.push({ pathname: 'locations' })}>
								<div className="dash-grid-bg locations"/>
								<div className="dash-grid-label">{ __('Locations')}</div>
								<IonRippleEffect></IonRippleEffect>
							</div>
							<div className="ion-activatable" onClick={() => history.push({ pathname: 'refer' })}>
								<div className="dash-grid-bg refer"/>
								<div className="dash-grid-label">{ __('Refer friend')}</div>
								<IonRippleEffect></IonRippleEffect>
							</div>
						</div>
					</div>
				</MobileView>
				<Alert showAlert={showAlert} closeAlert={ this.closeAlert } type='select' clearBasket={this.clearBasket} />
			</Layout>
		)
	}
}

const stateToProps = (state) => {
	const { profile, auth } = state.auth
	const { basket } = state.order
	const { translationCatalog } = state.common
	return {
		auth,
		profile,
		basket,
		translationCatalog

	}
}

export default connect(stateToProps)(withTranslation(Dashboard))
