import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { IonHeader, IonToolbar, IonMenuButton, IonButtons, IonButton, IonImg, IonItem, IonList, IonIcon, IonLabel, IonBadge } from '@ionic/react'
import QRCode from 'qrcode.react'
import { MobileView } from '../mobileView'
import { DesktopView } from '../desktopView'
import headerImg from '../../assets/images/islandpoke_logo_hero.jpg'
import { cssVar, isDesktop, forwardTo } from '../../lib/utils'
import { withTranslation } from '../../lib/translate'
import appConfig from '../../appConfig'
import { menu } from 'ionicons/icons'
import Icon from '../icon'
import { toggleRightDrawer } from '../../store/actions'
import navConfig from '../../navConfig'


require('./index.css')
if (isDesktop()){
	require('./desktop.css')
}else{
	require('./mobile.css')
}

class StaticHeader extends Component  {
	render() {
		const { history, toggleRightDrawer, basket, __ } = this.props
		const currentPath = history.location.pathname
		const { website } = appConfig.social
		const basketItems = basket ? basket.items : []
		let badge = basket && basket.items ? basket.items.length : null
		if (badge === 0) {
			badge = null
		}
		const cartRoute = navConfig.routes.find((r) => r.path === '/cart')
		return (
			<>
			<DesktopView>
				{
					// className={(currentPath !== '/register' && currentPath !== '/reset-password') ? 'desktop-header' : 'desktop-header register'} // ION HEADER CLASSNAME
					// (currentPath !== '/register' && currentPath !== '/reset-password') ? ( // ION LIST AND ION BUTTONS
					// ION IMG
					// (currentPath !== '/register' && currentPath !== '/reset-password') ? ( // ION LIST AND ION BUTTONS
					<IonHeader className="desktop-header">
						<IonButtons slot='start'>
							<IonMenuButton className='left-menu-button' menuId='main' color='primary-shade'>
								<IonIcon icon={menu}  className='right-drawer-icon' />
							</IonMenuButton>
						</IonButtons>
						<IonList>
							{navConfig.webRoutes.filter(route => route.left).map(item =>
								<IonItem key={item.path} lines='none' href={item.path}>
									<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
										<IonLabel>{__(item.label)}</IonLabel>
									</div>
								</IonItem>
							)}
						</IonList>
					<IonImg src={headerImg} />
						<IonList>
							{navConfig.webRoutes.filter(route => !route.left).map(item => 
								<IonItem key={item.path} href={item.path} lines='none'>
									<div tabIndex="0" style={{ boxShadow: 'none', outline: '0' }}>
										<IonLabel>{__(item.label)}</IonLabel>
									</div>
								</IonItem>	
							)}
						</IonList>
						<IonButtons>
							<IonButton shape='round' className='right-drawer-button' onClick={() => toggleRightDrawer()}>
								<IonIcon icon={menu}  className='right-drawer-icon' />
							</IonButton>
						</IonButtons>
					</IonHeader>
				}
			</DesktopView>
			<MobileView>
				{ currentPath !== '/login' ? (
				<IonHeader className="static">
					<IonToolbar className="primary-toolbar">
					{ currentPath !== '/register' ? (
						<>
						<IonButtons slot="start">
							<IonMenuButton menuId='main' color="primary-shade"/>
						</IonButtons>
						<IonButtons color="primary">
							<IonButton className='image-button' color="primary-shade" onClick={() => { forwardTo(history, 'dashboard')}}/>
						</IonButtons>
						<IonButtons slot="end">
							{basketItems.length > 0
								?
								<IonButton className='basket-button' onClick={() => { history.push(cartRoute.path)}} size={ 24 }>
									<IonBadge slot="end" color='primary'>{badge}</IonBadge>
									<Icon iconName={ cartRoute.iconName } />
								</IonButton>
								:
								<IonButton onClick={() => history.push({ pathname: 'loyalty', state: { tab: 'scan' } })} className="button-has-icon-only" button clear><QRCode renderAs="svg" fgColor={ cssVar('--ion-color-primary-shade')} value={ website } size={ 24 } /></IonButton>
							}
						</IonButtons>
						</>
					) : null }
					</IonToolbar>
					<IonToolbar color="primary">{ currentPath === '/register' ? (<p><br/><br/></p>) : null }</IonToolbar>
				</IonHeader>
				) : null }
			</MobileView>
			</>
		)
	}
}

const stateToProps = (state) => {
	const { order } = state
	const { common } = state
	return {
		basket: order.basket,
		translationCatalog: common.translationCatalog
	}
}

const dispatchToProps = dispatch => {
	return {
		toggleRightDrawer: () => dispatch(toggleRightDrawer())
	}
}

export default connect(stateToProps, dispatchToProps)(withTranslation(withRouter(StaticHeader)))
