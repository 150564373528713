import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { IonLabel, IonBadge } from '@ionic/react'
import { ellipsisVertical } from 'ionicons/icons'
import { isDesktop } from '../../lib/utils'
import navConfig from '../../navConfig'
import { withTranslation } from '../../lib/translate'
import Icon from '../icon'

require('./index.css')
if (isDesktop()){
	require('./desktop.css')
}else{
	require('./mobile.css')
}

class Tabs extends React.Component {
	render () {
		const { __, history, location, auth, basket } = this.props
		const { pathname } = location
		const dashboardRoute = navConfig.routes.find((r) => r.path === '/dashboard')
		const loyaltyRoute = navConfig.routes.find((r) => r.path === '/loyalty')
		const cartRoute = navConfig.routes.find((r) => r.path === '/cart')
		const accountRoute = navConfig.routes.find((r) => r.path === '/account')
		let badge = basket && basket.items ? basket.items.length : null
		if (badge === 0) {
			badge = null
		}

		return (
			<div className="tab-bar">
				<div className={ 'tab' + (pathname === dashboardRoute.path ? ' selected' : '')} onClick={() => { history.push(dashboardRoute.path)}}>
					<div>
						<Icon icon={ dashboardRoute.icon } /><br />
						<IonLabel>{ __(dashboardRoute.miniLabel || dashboardRoute.label)}</IonLabel>
					</div>
				</div>
				{ auth && auth.loggedIn ?
					<div className={ 'tab' + (pathname === loyaltyRoute.path ? ' selected' : '')} onClick={() => { history.push(loyaltyRoute.path)}}>
						<div>
							<Icon iconName={ loyaltyRoute.iconName } />
							<IonLabel>{ __(loyaltyRoute.miniLabel || loyaltyRoute.label)}</IonLabel>
						</div>
					</div>
					: null }
				<div className={ 'tab' + (pathname === cartRoute.path ? ' selected' : '')} onClick={() => { history.push(cartRoute.path)}}>
					<div className='tab-basket'>
						<IonBadge color='primary'>{badge}</IonBadge>
						<Icon iconName={ cartRoute.iconName } />
						<IonLabel>{ __(cartRoute.miniLabel || cartRoute.label)}</IonLabel>
					</div>
				</div>
				{ auth && auth.loggedIn ?
					<div className={ 'tab' + (pathname === accountRoute.path ? ' selected' : '')} onClick={() => { history.push(accountRoute.path)}}>
						<div>
							<Icon iconName={ accountRoute.iconName } />
							<IonLabel>{ __(accountRoute.miniLabel || accountRoute.label)}</IonLabel>
						</div>
					</div>
					: null }
				<div className="tab" onClick={() => { document.querySelector('.drawer-menu').open()}}>
					<div>
						<Icon icon={ ellipsisVertical } /><br />
						<IonLabel>{ __('More')}</IonLabel>
					</div>
				</div>
			</div>
		)
	}
}

const stateToProps = (state) => {
	const { auth, profile } = state.auth
	const { basket } = state.order
	const items = basket ? basket.items : []
	return {
		auth,
		profile,
		basket,
		items
	}
}

export default withRouter(withTranslation(connect(stateToProps)(Tabs)))
