import { take, call, put } from 'redux-saga/effects'
import {
	DATA_REQUEST,
	DATA_SUCCESS,
	DATA_ERROR
} from './constants'
import api from '../../lib/api'
import { extractNumbers } from '../../lib/utils'
import httpStatuses from '../../lib/httpStatuses'

export const dataFlow = function* () {
	while (true) {
		const action = yield take(DATA_REQUEST)
		const { dataType, body } = action
		try {
			const response = yield call(api[dataType], body)
			if (!response.error){
				yield put({ type: DATA_SUCCESS, dataType, data: response.data })
			} else {
				yield put({ type: DATA_ERROR, dataType, error: response.error })
			}
		} catch (error){
			const bullError = error.toString().split(': ')
			const bullErrorMessage = bullError[1] ? bullError[1] : bullError[0]
			const statusCode = extractNumbers(bullErrorMessage)
			const stat = {
				message: bullErrorMessage,
				itemErrors: {},
				statusCode,
				status: statusCode ? httpStatuses[statusCode] : null
			}
			yield put({ type: DATA_ERROR, dataType, error: stat })
		}
	}
}
