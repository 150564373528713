import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import Layout from '../../../components/layout'
import SimpleLoader from '../../../components/simpleLoader'
import { withTranslation } from '../../../lib/translate'
import { dataRequest, dataClearError, showToast, selectCategory } from '../../../store/actions'
import { forwardTo, isEmptyObject, isDesktop } from '../../../lib/utils'
import appConfig from '../../../appConfig'
import { DesktopView } from '../../../components/desktopView'
import { IonButton } from '@ionic/react'


require('./index.css')
if (isDesktop()) {
	require('./desktop.css')
} else {
	require('./mobile.css')
}

const s3Url = appConfig.configS3.url

class Menu extends React.Component {
	state = {
		menu: [],
		selectedMenu: null,
		categories: {}
	}

	handleData = () => {
		const { data, apiMethod, dispatch, history, __ } = this.props
		const { errors } = data
		const error = errors[apiMethod]

		if (error){
			dispatch(showToast(error.message, 'danger'))
			dispatch(dataClearError(apiMethod))
		} else {
			const { content } = data
			if (content && content[apiMethod]){
				const menu = content[apiMethod]
				if (!menu || menu.available_products && menu.available_products.length === 0){
					dispatch(showToast(__('This restaurant has no products available') , 'warning'))
					forwardTo(history, '/orders')
				}
				const categories = this.createCategory(menu)
				this.setState({ menu, categories })
			}
		}
	}

	componentDidUpdate (prevProps) {
		const { data } = this.props
		if (prevProps.data !== data){
			this.handleData()
		}
	}

	componentDidMount() {
		const { apiMethod, dispatch, restaurantId } = this.props
		dispatch(dataRequest(apiMethod, {restaurant_id: restaurantId}))

	}

	createCategory = (menu) => {
		if (!isEmptyObject(menu)) {
			const categories = {}
			menu.available_products.forEach((product, index) => {
				const category = product.product[0].category
				const subCategory = product.product[0].sub_category
				if (!categories[category.name]) {
					categories[category.name] = {products: [], category, subCategories: {}}
				}
				if (!categories[category.name].subCategories[subCategory.name]) {
					categories[category.name].subCategories[subCategory.name] = {products: [], subCategory}
				}
				categories[category.name].subCategories[subCategory.name].products.push(product)
				categories[category.name].category = category
				categories[category.name].products.push(product)
			})
			return categories
		}
	}

    selectCategory = (category) => {
    	const { history, dispatch } = this.props
    	const { categories } = this.state

    	dispatch(selectCategory(category))
    	forwardTo(history, '/categories', { 'categories': categories })
    }

    render() {
    	const { __, data, basket, history } = this.props
    	const { loading } = data
    	const { categories } = this.state
    	const basketButton = basket && basket.items.length > 0 ? true : false
    	return (
    		<Layout headerTitle={__('Menu')} color={isDesktop() ? 'transparent' : 'white'} contentClassName={ isDesktop() ? 'animated-gif-01' : '' } noPadding={isDesktop() ? true : false}>
    			<SimpleLoader loading={ loading }>
    				<div className='desktop-categories-wrapper'>
    					{
    						(Object.keys(categories ? categories : {}) || []).
    							sort((a, b) => categories[a].category.sort_order > categories[b].category.sort_order ? 1 : -1).
    							map((key, i) => {
    								if (categories[key].category.attributes.is_hidden) {
    									return null
    								}
    								return (
    									<div className='category-item' key={i} onClick={() => {
    										this.selectCategory(categories[key])
    									}}>
    										<img alt='' className='category-image' src={s3Url + categories[key].category.image} />
    										<div className='category-label'>{categories[key].category.name.toUpperCase()}</div>
    									</div>
    								)
    							})
    					}
    				</div>
    				<DesktopView>
    					{
    						basketButton
    							?
    							<IonButton expand='full' color='tertiary' className='view-basket-button-menu' onClick={() => forwardTo(history, '/cart')}>{ __('View Basket')}</IonButton>
    							:
    							null
    					}
    				</DesktopView>
    			</SimpleLoader>
    		</Layout>
    	)
    }
}

const stateToProps = (state) => {
	const { data, order } = state
	return {
		data,
		apiMethod: 'productAvailability',
		restaurantId: order.restaurantId,
		basket: order.basket,
		currency: order.currency
	}
}

export default connect(stateToProps)(withRouter(withTranslation(Menu)))
